import React from "react";
import { Button } from "antd";
import { Card } from "antd";
import { useEffect, useState, useMemo, useContext } from "react";
import {
  BarChartOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

import collage from "../../Assests/8.png";

import { useNavigate } from "react-router-dom";

import FHB from "../../Assests/FHB.png";
import HPB from "../../Assests/HPB.png";
import NM from "../../Assests/NM.png";
import PS from "../../Assests/PS.png";
import GOV from "../../Assests/GOV.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { getPublicCounts } from "../../Api";
import { i18n } from "../../App";
import { PUBLICDASHBOARD_PAGE_LABELS } from "../../Utils/Labels/PublicDashboard.page.labels";
import getPageLabels from "../../Utils/Label.service";
import BannerCarousel from "../modules/BannerCarousel";

const imgStyle = {
  maxWidth: "150px",
  maxHeight: "150px",
  minHeight: "150px",

  marginTop: "50px",
  display: "block",
  margin: "0 auto",
};
const titleStyle = {
  fontSize: "20px",
  fontWeight: "bold",
  fontFamily: "HussarBoldWeb-bf92",
};
function DonorDashboard() {
  const navigate = useNavigate();
  const handleMakeDonationClick = () => {
    navigate("/MakeDonation");
  };

  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(PUBLICDASHBOARD_PAGE_LABELS, language.language),
    [language]
  );

  const [loadedImages, setLoadedImages] = useState([]);
  const [counts, setCounts] = useState(null);

  const name = window.localStorage.getItem("name");

  const fetchCounts = () => {
    getPublicCounts({}).then((out) => {
      setCounts(out.data.data);
    });
  };

  useEffect(() => {
    const loadImages = () => {
      const imageInfoArray = [
        {
          imagePath: PS,
          title1: PUBLICDASHBOARD_PAGE_LABELS.logo2.si,
          title2: PUBLICDASHBOARD_PAGE_LABELS.logo2.en,
          title3: PUBLICDASHBOARD_PAGE_LABELS.logo2.ta,
          url: 'https://www.presidentsoffice.gov.lk/'
        },
        {
          imagePath: GOV,
          title1: PUBLICDASHBOARD_PAGE_LABELS.logo1.si,
          title2: PUBLICDASHBOARD_PAGE_LABELS.logo1.en,
          title3: PUBLICDASHBOARD_PAGE_LABELS.logo1.ta,
          url: 'https://www.health.gov.lk/'
        },
        {
          imagePath: HPB,
          title1: PUBLICDASHBOARD_PAGE_LABELS.logo5.si,
          title2: PUBLICDASHBOARD_PAGE_LABELS.logo5.en,
          title3: PUBLICDASHBOARD_PAGE_LABELS.logo5.ta,
          url: 'https://www.hpb.health.gov.lk/en'
        },
        {
          imagePath: NM,
          title1: PUBLICDASHBOARD_PAGE_LABELS.logo3.si,
          title2: PUBLICDASHBOARD_PAGE_LABELS.logo3.en,
          title3: PUBLICDASHBOARD_PAGE_LABELS.logo3.ta,
          url: 'https://nutrition.health.gov.lk/english/'
        },
        {
          imagePath: FHB,
          title1: PUBLICDASHBOARD_PAGE_LABELS.logo4.si,
          title2: PUBLICDASHBOARD_PAGE_LABELS.logo4.en,
          title3: PUBLICDASHBOARD_PAGE_LABELS.logo4.ta,
          url: 'https://fhb.health.gov.lk/'
        },
      ];

      const imagesInRows = [];
      let currentRow1 = [];
      let currentRow2 = [];

      imageInfoArray.forEach((imageInfo, index) => {
        const imageElement = (
          <div key={index} className="col-md-4">
            <div
              className="image-card"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "40px",
              }}
            >
              <a href={imageInfo.url} target="_blank">
                <img
                  src={imageInfo.imagePath}
                  alt={`${index}`}
                  style={imgStyle}
                />
              </a>
              <div className="image-title">
                <span style={titleStyle}>{imageInfo.title1}</span>
                <span style={titleStyle}>{imageInfo.title3}</span>
                <span style={titleStyle}>{imageInfo.title2}</span>
              </div>
            </div>
          </div>
        );

        if (imageInfo.imagePath === GOV || imageInfo.imagePath === PS) {
          currentRow1.push(imageElement);
        } else {
          currentRow2.push(imageElement);
        }

        if (currentRow1.length === 2) {
          imagesInRows.push(
            <div
              key={`row-${index}-1`}
              className="row justify-content-center mt-4"
            >
              {currentRow1}
            </div>
          );
          currentRow1 = [];
        }
      });

      if (currentRow1.length > 0) {
        imagesInRows.push(
          <div
            key={`row-remaining-1`}
            className="row justify-content-center mt-4"
          >
            {currentRow1}
          </div>
        );
      }

      if (currentRow2.length > 0) {
        imagesInRows.push(
          <div
            key={`row-remaining-2`}
            className="row justify-content-center mt-4"
          >
            {currentRow2}
          </div>
        );
      }

      setLoadedImages(imagesInRows);
    };

    loadImages();
    fetchCounts();
  }, [language]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <div className="fos-heading">
            {lang.hi} {name}
          </div>
          <div className="fos-heading-small">{lang.welcome1}</div>
        </div>
        <div className=" d-flex align-items-end ">
          <Button
            className="fos-btn-primary mb-1 ml-4"
            onClick={handleMakeDonationClick}
          >
            {lang.donate_btn}
          </Button>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-3 mmt-10">
          <Card className="fos-card fos-gradient-card">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold fos-white">
                {counts?.count1}
              </div>
              <div className="fos-card-icon d-flex fos-maroon">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description fos-white">{lang.card1}</p>
          </Card>
        </div>
        <div className="col-md-3 mmt-10">
          <Card className="fos-card ">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold ">
                {counts?.count2}
              </div>
              <div className="fos-card-icon d-flex fos-green">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description ">{lang.card2}</p>
          </Card>
        </div>
        <div className="col-md-3 mmt-10">
          <Card className="fos-card ">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold ">
                {counts?.count3}
              </div>
              <div className="fos-card-icon d-flex fos-orange">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description ">{lang.card3}</p>
          </Card>
        </div>
        <div className="col-md-3 mmt-10">
          <Card className="fos-card ">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold ">
                {counts?.count4} LKR
              </div>
              <div className="fos-card-icon d-flex fos-yellow">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description ">{lang.card4}</p>
          </Card>
        </div>
      </div>
      <div>
        {/* Banner */}
        <BannerCarousel />
        {/* Banner */}
      </div>
      <div className="fos-bg-white-50">
        <div className="container mt-100 ">
          <div className="text-center mb-5">
            <div className="fos-heading" style={{ textAlign: "center" }}>
              {lang.welcome_label}
            </div>
          </div>

          <div className="row container ">
            <div className="col-md-4 text-center">
              <img
                style={{
                  width: "100%",
                  height: "auto",
                }}
                src={collage}
                alt="Description"
              />
            </div>
            <div className="col-md-8 mmt-10">
              <div className="">
                <p>{lang.welcome_desc1}</p>
                <p>{lang.welcome_desc2}</p>
                <p>{lang.welcome_desc3}</p>
                <p>{lang.welcome_desc4}</p>
                <p>{lang.welcome_desc5}</p>{" "}
              </div>
              <div className="d-flex mt-4">
                <Button
                  className="fos-btn-border-2 mb-1 ml-4"
                  onClick={() =>
                    window.open(
                      "https://nutrition.health.gov.lk/english/contact-us/",
                      "_blank"
                    )
                  }
                >
                  {lang.contact_btn}
                </Button>
                <Button
                  className="fos-btn-primary"
                  onClick={() => window.open("/makeDonation", "_self")}
                >
                  {lang.donate_btn}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-container">
        <div className="container mt-100">
          <div className="text-center mb-5">
            <div className="fos-heading">{lang.logo_heading}</div>
            <div className="fos-heading-medium">{lang.logo_subheading}</div>
          </div>

          <div className="row container">
            <div className="col-md-12 text-center">{loadedImages}</div>
          </div>
        </div>
      </div>
      <div className="footer p-3 mt-100 fos-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-4 footer-section">
              <h4>{lang.footer_header1}</h4>
              <p className="footer-info">
                <MailOutlined className="mr-2" />
                <b>{lang.email}</b> sneha.fcslk@gmail.com
              </p>
              <p className="footer-info">
                <PhoneOutlined className="mr-2" />
                <b>{lang.phone}</b> +94 112368320
              </p>
              <p className="footer-info">
                <EnvironmentOutlined className="mr-2" />
                <b>{lang.address}</b>
                <br /> {lang.address1}
                <br /> {lang.address2}
              </p>
            </div>

            <div className="col-md-4 footer-section">
              <h4>{lang.external_links}</h4>
              <p
                className="footer-link"
                style={{
                  marginTop: "15px",
                }}
              >
                <a
                  href="http://www.health.gov.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.link1}
                </a>
              </p>
              <p className="footer-link">
                <a
                  href="https://www.presidentsoffice.gov.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.link2}
                </a>
              </p>
              <p className="footer-link">
                <a
                  href="https://nutrition.health.gov.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.link3}
                </a>
              </p>
              <p className="footer-link">
                <a
                  href="https://fhb.health.gov.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.link4}
                </a>
              </p>
              <p className="footer-link">
                <a
                  href="https://www.hpb.health.gov.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.link5}
                </a>
              </p>
              <p className="footer-link">
                <a
                  href="https://www.who.int/srilanka"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.who}
                </a>
              </p>
            </div>
            <div className="col-md-4 footer-section">
              <h4>{lang.socialmedia}</h4>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/NDivSL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{
                      fontSize: "36px",
                      color: "#1877f2",
                      marginRight: "20px",
                      marginTop: "15px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/nutritiondivision_sl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{
                      fontSize: "36px",
                      color: "#bc2a8d",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a
                  href="https://twitter.com/your-twitter-url"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{
                      fontSize: "36px",
                      color: "#1da1f2",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/your-youtube-url"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{
                      fontSize: "36px",
                      color: "#c4302b",
                      marginRight: "20px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DonorDashboard;
