
export const LANGUAGE_CODE = {
  'EN': 'en',
  'TA': 'ta',
  'SI': 'si'
}

export const IMG = {
  'logo': {
    'en': require('../Assests/logo_en.webp'),
    'si': require('../Assests/logo_si.webp'),
    'ta': require('../Assests/logo_ta.webp'),
  }

};

export const LOCALES = [
  { key: LANGUAGE_CODE.SI, label: "සිංහල" },
  { key: LANGUAGE_CODE.TA, label: "தமிழ்" },
  { key: LANGUAGE_CODE.EN, label: "English" },
];

export const CHECK_PERMISSION = (permissions) => {
  const allowed = JSON.parse(window.localStorage.getItem('permissions'));
  if (allowed && allowed.length > 0 && allowed.includes(permissions)) {
    return true;
  }
  return false;
}

export const GET_DATE_TIME = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2,'0')}-${(date.getDate()).toString().padStart(2,'0')} ${date.toLocaleTimeString("en-US")}`;
}