import React, { useState, useEffect } from "react";
import logo from '../../Assests/logo-small.webp';

function Loader() {


  return (
    <>
      <div className="fos-loader ">
       <div className="fos-blink"> <img className="fos-loader-logo" src={logo}></img>
        <div className="text-center fos-blink ">    Loading</div></div>
      </div>



    </>
  )
}
export default Loader;

