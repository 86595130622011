export const ACTIVITYLOG_PAGE_LABELS = {
  heading: {
    en: "Activity Log",
    ta: "நடவடிக்கை பதிவு",
    si: "ක්‍රියාකාරකම් ලොගය",
  },
  heading_small: {
    en: "2023 January 20th 14.30 Kamal Perera All Donations Set Fund release date edit funds release date 25th",
    ta: "2023 ஜனவரி 20 ஆம் தேதி 14.30 கமல் பெரேரா அனைத்து நன்கொடைகள் செட் நிதி வெளியீட்டு தேதி திருத்த நிதி வெளியீட்டு தேதி 25 ஆம் தேதி",
    si: "2023 ජනවාරි 20 14.30 කමල් පෙරේරා සියලු පරිත්‍යාග සකසන්න අරමුදල් මුදා හැරීමේ දිනය සංස්කරණය අරමුදල් නිකුත් කිරීමේ දිනය 25",
  },
  heading_small2: {
    en: "2023 January 20th 14.30 Kamal Perera All Donations Set Fund release date edit funds release date 25th",
    ta: "2023 ஜனவரி 20 ஆம் தேதி 14.30 கமல் பெரேரா அனைத்து நன்கொடைகள் செட் நிதி வெளியீட்டு தேதி திருத்த நிதி வெளியீட்டு தேதி 25 ஆம் தேதி",
    si: "2023 ජනවාරි 20 14.30 කමල් පෙරේරා සියලු පරිත්‍යාග සකසන්න අරමුදල් මුදා හැරීමේ දිනය සංස්කරණය අරමුදල් නිකුත් කිරීමේ දිනය 25",
  },
  download_report: {
    en: "Download Report",
    ta: "அறிக்கையைப் பதிவிறக்கவும்",
    si: "වර් තාව බාගත කරන්න",
  }
};
