import axios from "axios";

export const BASE_URL = "http://13.213.206.120:3001";
export const ASSET_URL = `${BASE_URL}/static`;
// const BASE_URL = 'http://localhost:3023';
// const BASE_URL = "https://ec2-54-179-251-15.ap-southeast-1.compute.amazonaws.com/sneha";

const GET = async (path, body = {}, auth = true, blob = false) => {
  const TOKEN = await window.localStorage.getItem("token");
  if (auth) {
    body.headers = { Authorization: `Bearer ${TOKEN}` };
  }
  if (blob) {
    body.responseType = "blob";
  }
  let res = null;
  try {
    res = await axios.get(
      path,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    return res;
  } catch (e) {
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const POST = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.post(
      path,
      body,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e?.response?.data?.message ?? e.message,
      },
    };
  }
};

const PUT = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.put(
      path,
      body,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const DELETE = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.delete(
      path,
      { headers: { Authorization: `Bearer ${TOKEN}` }, data: body },
      auth ? {} : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const MULTIPART = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.post(
      path,
      body,
      auth
        ? {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          }
        : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

// Users

export const login = async (body) => {
  return await POST(BASE_URL + "/api/users/login", body, false);
};

export const getUsers = async (body) => {
  return await GET(BASE_URL + `/api/users/`, { body });
};

export const addUser = async (body) => {
  return await POST(BASE_URL + `/api/users/register`, body);
};

export const logout = async (body) => {
  return await POST(BASE_URL + "/api/users/logout", body, false);
};

export const activateDeactivateUser = async (body) => {
  return await POST(BASE_URL + "/api/users/activateDeactivate", body);
};

// Donors

export const registerDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/register", body, false);
};

export const loginDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/login", body, false);
};

export const forgotPasswordDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/forgotPassword", body, false);
};

export const verifyOtpDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/verifyOtp", body, false);
};

export const updatePasswordDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/updatePassword", body, false);
};

export const getDonors = async (body) => {
  return await GET(BASE_URL + "/api/donors/", body);
};

export const getMyDonorProfile = async (body) => {
  return await GET(BASE_URL + "/api/donors/myProfile", body);
};

export const updateMyDonorProfile = async (body) => {
  return await MULTIPART(BASE_URL + "/api/donors/myProfile", body);
};

export const activateDeactivateDonor = async (body) => {
  return await POST(BASE_URL + "/api/donors/activateDeactivate", body);
};

// Beneficiaries

export const getBeneficiary = async (body) => {
  return await GET(BASE_URL + `/api/beneficiaries/${body.uid}`, {});
};

export const getBeneficiaries = async (body) => {
  return await GET(BASE_URL + "/api/beneficiaries/", body);
};

export const suggestBeneficiary = async (body) => {
  return await POST(BASE_URL + "/api/beneficiaries/suggest", body);
};

export const verifyBeneficiary = async (body) => {
  return await PUT(BASE_URL + "/api/beneficiaries/verify", body);
};

export const setExcludedBeneficiary = async (body) => {
  return await PUT(BASE_URL + "/api/beneficiaries/setExcluded", body);
};

export const setDeceasedBeneficiary = async (body) => {
  return await PUT(BASE_URL + "/api/beneficiaries/setDeceased", body);
};

export const toFundingBeneficiary = async (body) => {
  return await POST(BASE_URL + "/api/beneficiaries/tofunding", body);
};

export const reEnroll = async (body) => {
  return await POST(BASE_URL + "/api/beneficiaries/reEnroll", body);
};

export const checkDivert = async (body) => {
  return await GET(BASE_URL + `/api/beneficiaries/divert/${body.uid}`, {});
};

// Donations
export const addDonation = async (body) => {
  return await POST(BASE_URL + "/api/donations/", body);
};

export const getDonation = async (body) => {
  return await GET(
    BASE_URL + `/api/donations/donation/${body.reference_no}`,
    {}
  );
};

export const addDonationTransaction = async (body) => {
  return await POST(BASE_URL + "/api/donations/transaction", body);
};

export const getDonations = async (body) => {
  return await GET(BASE_URL + `/api/donations/`, { body });
};

export const getMyDonations = async (body) => {
  return await GET(BASE_URL + `/api/donations/my`, { body });
};

export const getMyDonation = async (body) => {
  return await GET(BASE_URL + `/api/donations/my/${body.donation_id}`, {
    body,
  });
};

export const getDistrictGoals = async (body) => {
  return await GET(BASE_URL + `/api/donations/district_goals/${body.year}`, {});
};

export const getDistrictGoalsAll = async (body) => {
  return await GET(BASE_URL + `/api/donations/district_goals`, {});
};

export const getDistrictGoalMonth = async (body) => {
  return await GET(BASE_URL + `/api/donations/district_goal/${body.id}`, {});
};

export const updateDistrictGoalMonth = async (body) => {
  return await PUT(BASE_URL + `/api/donations/district_goal/`, body);
};

export const getCategoryGoals = async (body) => {
  return await GET(BASE_URL + `/api/donations/category_goals/${body.year}`, {});
};

export const getCategoryGoalsAll = async (body) => {
  return await GET(BASE_URL + `/api/donations/category_goals/all`, {});
};

export const getCategoryGoalMonth = async (body) => {
  return await GET(
    BASE_URL + `/api/donations/category_goals/by_goal/${body.id}`,
    {}
  );
};

export const updateCategoryGoalMonth = async (body) => {
  return await PUT(BASE_URL + `/api/donations/category_goals/`, body);
};

export const addAnnualGoal = async (body) => {
  return await POST(BASE_URL + `/api/donations/annual_goal/`, body);
};

export const getAnnualGoals = async (body) => {
  return await GET(BASE_URL + `/api/donations/annual_goal/`, body);
};

export const getPublicCounts = async (body) => {
  return await GET(BASE_URL + `/api/donations/counts`, {}, false);
};

export const getInternalCounts = async (body) => {
  return await GET(BASE_URL + `/api/donations/counts_internal`, {});
};

export const getByBeneficiary = async (body) => {
  return await POST(BASE_URL + `/api/donations/by_beneficiary`, body);
};

export const getByCategory = async (body) => {
  return await POST(BASE_URL + `/api/donations/by_category`, body);
};

export const getByDonor = async (body) => {
  return await POST(BASE_URL + `/api/donations/by_donor`, body);
};

export const getPayoutsByBeneficiary = async (body) => {
  return await POST(BASE_URL + `/api/donations/payouts_by_beneficiary`, body);
};

export const getByRDHS = async (body) => {
  return await POST(BASE_URL + `/api/donations/by_rdhs`, body);
};

export const getGoalsByRDHS = async (body) => {
  return await POST(BASE_URL + `/api/donations/by_rdhs_goals`, body);
};

export const getPayoutDate = async (body) => {
  return await GET(BASE_URL + `/api/donations/payout_date`, { body });
};

export const openIPG = async (body) => {
  return await POST(BASE_URL + "/api/donations/open_ipg", body);
};

export const confirmDivert = async (body) => {
  return await POST(BASE_URL + "/api/donations/divert", body);
};

export const issueCertificate = async (body) => {
  return await POST(BASE_URL + "/api/donations/issue_cerificate", body);
};

export const getReleases = async (body) => {
  return await GET(BASE_URL + `/api/donations/releases`, { body });
};

export const releaseFunds = async (body) => {
  return await POST(BASE_URL + "/api/donations/release", body);
};

// Meta

export const getRDHSAreas = async (body) => {
  return await GET(BASE_URL + `/api/meta/rdhs_areas`, { body });
};

export const getPermissions = async (body) => {
  return await GET(BASE_URL + `/api/meta/permissions`, {});
};

export const addUserRole = async (body) => {
  return await POST(BASE_URL + `/api/meta/role`, body);
};

export const getUserRoles = async (body) => {
  return await GET(BASE_URL + `/api/meta/roles`, {});
};

export const addUserRolePermission = async (body) => {
  return await POST(BASE_URL + `/api/meta/role_permission`, body);
};

export const removeUserRolePermission = async (body) => {
  return await DELETE(BASE_URL + `/api/meta/role_permission`, body);
};

export const getOrgUnits = async (body) => {
  return await GET(BASE_URL + `/api/meta/org_units`, { body });
};

export const uploadBanner = async (body) => {
  return await MULTIPART(BASE_URL + `/api/meta/banner`, body);
};

export const deleteBanner = async (body) => {
  return await DELETE(BASE_URL + `/api/meta/banner`, body);
};

export const getBanners = async (body) => {
  return await GET(BASE_URL + `/api/meta/banners`, { }, false);
};

export const getLogs = async (body) => {
  return await GET(BASE_URL + `/api/meta/logs`, { body });
};

export const getLogsAll = async (body) => {
  return await GET(BASE_URL + `/api/meta/logs_all`, { body });
};

export const getDonationsByMonth = async (body) => {
  return await GET(BASE_URL + `/api/meta/donations_by_month`, { body });
};

export const getChildRegistrationsByMonth = async (body) => {
  return await GET(BASE_URL + `/api/meta/child_registration_by_month`, { body });
};

export const getChildCountByEnrollment = async (body) => {
  return await GET(BASE_URL + `/api/meta/child_count_by_enrollment_status`, { body });
};

export const getChildCountByAge = async (body) => {
  return await GET(BASE_URL + `/api/meta/child_count_by_age`, { body });
};

export const getCertificate = async (body) => {
  return await GET(
    BASE_URL + `/api/donations/certificate/${body.id}`,
    {}
  );
};
