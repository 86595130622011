export const AccountSettings_FORM_RULE_MESSAGE = {
    firstname: {
      required: {
        en: "Please input your firstname!",
        ta: "உங்கள் முதல் பெயரை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ මුල් නම ඇතුළත් කරන්න!"
      },
     },
     surname: {
        required: {
        en: "Please input your surname!",
        ta: "தயவுசெய்து உங்கள் குடும்பப்பெயரை உள்ளிடவும்!",
        si: "කරුණාකර ඔබගේ වාසගම ඇතුලත් කරන්න!"
      },
     },
    card_no: {
      required: {
        en: "Please input your card number!",
        ta: "உங்கள் அட்டை எண்ணை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ කාඩ්පත් අංකය ඇතුළත් කරන්න!"
      },
      pattern: {
        en: "Card number does not match the required format (16 digits).",
        ta: "அட்டை எண் கோரிக்கைக்கு பொருத்தவில்லை (16 இலக்குகள்).",
        si: "කාඩ්පත් අංකය අවශ්‍ය ආකෘතිය (16 අංක) සමාන නොකරයි."
      }
    },
    valid_date: {
      required: {
        en: "Please input a valid through date!",
        ta: "தேதி வரை செல்லுபடியாகும் உள்ளிடவும்!",
        si: "කරුණාකර වලංගු දිනය ඇතුළත් කරන්න!"
      },
      pattern: {
        en: "Valid through date does not match the required format (MM/YY).",
        ta: "தேதி வரை செல்லுபடியாகும் வடிவம் பொருத்தவில்லை (MM/YY).",
        si: "වලංගු දිනය අවශ්‍ය ආකෘතිය (MM/YY) සමාන නොකරයි."
      }
    },
    cvv: {
      required: {
        en: "Please input the CVV!",
        ta: "தயவுசெய்து CVV ஐ உள்ளிடவும்!",
        si: "කරුණාකර CVV ඇතුළත් කරන්න!"
      },
      pattern: {
        en: "CVV does not match the required format (3 digits).",
        ta: "CVV கோரிக்கைக்கு பொருத்தவில்லை (3 இலக்குகள்).",
        si: "CVV අවශ්‍ය ආකෘතිය (3 අංක) සමාන නොකරයි."
      }
    }
  };
  