export const AREA_PAGE_LABELS = {
  heading: {
    en: "Area",
    ta: "பகுதி",
    si: "ප්‍රදේශය",
  },
  add_area_btn: {
    en: "Add New Area",
    ta: "புதிய பகுதியைச் சேர்க்கவும்",
    si: "නව ප්‍රදේශයක් එකතු කරන්න",
  },
  area_id: {
    en: "Area ID",
    ta: "பகுதி ஐடி",
    si: "ප්‍රදේශ හඳුනාගැනීමේ අංකය",
  },
  area_name: {
    en: "Area Name",
    ta: "பகுதி பெயர்",
    si: "ප්‍රදේශයේ නම",
  },
  area_type: {
    en: "Area Type",
    ta: "பகுதி வகை",
    si: "ප්‍රදේශයේ ප්‍රරූපය",
  },
  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  push_to_funding_list: {
    en: "Push to Funding List",
    ta: "நிதியளிப்பு பட்டியலுக்கு அழுத்தவும்",
    si: "අරමුදල් ලැයිස්තුවට තල්ලු කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "නිෂ්ක්‍රීය කරන්න",
  },
  re_enroll: {
    en: "Re Enroll",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  alert_push_to_funding_list_success: {
    en: "Push to Funding List successfully",
    ta: "வெற்றிகரமாக நிதியளிப்பு பட்டியலுக்கு புஷ்.",
    si: "සාර්ථකව අරමුදල් ලැයිස්තුවට තල්ලු කරන ලදී.",
  },
  alert_deactivate_success: {
    en: "Deactivate successfully.",
    ta: "வெற்றிகரமாக செயலிழக்கப்பட்டது.",
    si: "සාර්ථකව නිෂ්ක්‍රීය කරන ලදී.",
  },
  alert_re_enroll_success: {
    en: "Re Enroll successfully. ",
    ta: "மீண்டும் பதிவுசெய்யவும்.",
    si: "සාර්ථකව නැවත ලියාපදිංචි කරන ලදී.",
  },
};
