import { Button, Dropdown, Space, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../../Reducers/Layout";
import { allRoutes } from "../../Constants";
import { useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { LOCALES, LANGUAGE_CODE } from "../../Utils/Constants";
import { i18n } from "../../App";
import getPageLabels from "../../Utils/Label.service";
import { SIDEBAR_BASE_LABELS } from "../../Utils/Labels/Sidebar.base.labels";
import { logout } from "../../Api";

const AppHeader = () => {
  const location = useLocation();

  const activeItem = allRoutes.find((item) => item.url === location.pathname);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const page = useSelector((state) => state.layout.page);
  const reduxDispatch = useDispatch();

  const allowableLanguageCodes = Object.values(LANGUAGE_CODE);
  const { dispatch, language } = useContext(i18n);
  const lang = useMemo(
    () => getPageLabels(SIDEBAR_BASE_LABELS, language.language),
    [language]
  );
  const handleMenuClick = async (e) => {
    if (allowableLanguageCodes.includes(e?.key)) {
      sessionStorage.setItem("lang", e?.key);
      dispatch.dispatch({ type: e?.key });
    }
  };

  const items = LOCALES;

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => reduxDispatch(toggleSidebar())}
            style={{ fontSize: "16px", width: 64, height: 64 }}
          />
          <div className="fos-heading">{lang[activeItem?.label] ?? ""}</div>
        </div>

        <div className="d-flex">
          <div className="mt-1"></div>

          <div className="mt-1">
            <Dropdown menu={menuProps}>
              <Button className="border-0">
                <Space>
                  {
                    LOCALES.find((lang) => lang.key === language.language)
                      ?.label
                  }
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
          <div className="mt-1">
            <Button
              className="border-0"
              onClick={() => {
                logout().then(() => {
                  window.localStorage.removeItem("token");
                  window.location.href = "/";
                });
              }}
            >
              <Space>
                <span style={{ color: "red" }}>{lang.logout}</span>
                <LogoutOutlined style={{ color: "red" }} />
              </Space>
            </Button>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
