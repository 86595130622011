import React, { useContext, useMemo } from "react";
import { Card, Input, Button, Form } from "antd";
import getPageLabels from "../../Utils/Label.service";

import { ADDNEWAREA_PAGE_LABELS } from "../../Utils/Labels/AddNewArea.page.labels";

import { i18n } from "../../App";
import { Toast } from "../modules/Alert";
import AreaRules from "./validations/area_validation_rules";

function AddNewArea() {
  const { language } = useContext(i18n);
  const rules = AreaRules();
  const lang = useMemo(
    () => getPageLabels(ADDNEWAREA_PAGE_LABELS, language.language),
    [language]
  );
  const onFinish = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_create_success,
    });
  };
  return (
    <div>
      <div className="">
        <div className="">
          <div>
            <Card className="p-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <div className="fos-card-heading font-bold">
                      {lang.heading}
                    </div>
                  </div>
                  <Form onFinish={onFinish} autoComplete="off">
                    <div className="row w-50">
                      <div className="col-md-6">
                        <div>
                          <div className="fos-label mt-2">{lang.area_type}</div>
                          <div className="mt-1">
                            <Form.Item
                              name="area_type"
                              rules={Object.values(rules?.area_type??{})}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <div className="fos-label mt-2">{lang.area_name}</div>
                          <div className="mt-1">
                            <Form.Item
                              name="area_name"
                              rules={Object.values(rules?.area_name??{})}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6 d-flex align-items-end ">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="fos-btn-primary mb-1 ml-4"
                        >
                          {lang.save_btn}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewArea;
