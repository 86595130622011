import React, { useContext, useMemo, useState } from "react";
import { Table, Button, Modal, Form } from "antd";
import { DownOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Dropdown, Space, Card } from "antd";
import { DatePicker } from "antd";
import { PERMISSION } from "../../Constants";
import { PlusOutlined } from "@ant-design/icons";
import FormSelect from "../form/FormSelect";
import getPageLabels from "../../Utils/Label.service";
import { ADDNEWUSERROLE_PAGE_LABELS } from "../../Utils/Labels/AddNewUserRole.page.labels";
import { i18n } from "../../App";
import { Toast } from "../modules/Alert";
import UserRoleRules from "./validations/user_role_validation_rules";
import List from "./../form/List";
const items = [
  {
    label: "View users",
    key: "1",
  },
  {
    label: "Value 2",
    key: "2",
  },
];

const { RangePicker } = DatePicker;

function AddNewUserRole() {
  const [permission, setPermission] = useState(0);

  const { language } = useContext(i18n);
  const rules = UserRoleRules();
  const lang = useMemo(
    () => getPageLabels(ADDNEWUSERROLE_PAGE_LABELS, language.language),
    [language]
  );
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const onFinish = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_save_success,
    });
  };

  const initialItems = ["Item 1", "Item 2", "Item 3", "Item 4"];

  return (
    <div>
      <div className="">
        <div className="">
          <div>
            <Card className="p-2">
              <Form
                onFinish={onFinish}
                autoComplete="off"
                name="basic"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}

                layout="vertical"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <div className="fos-card-heading font-bold">
                        {lang.heading}
                      </div>
                    </div>
                    <Form.Item
                      label={lang.role_name_label}
                      name="user_roles"
                      rules={Object.values(rules?.user_roles_name ?? {})}
                    >
                      <Input placeholder={lang.role_name_placeholder} />
                    </Form.Item>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fos-heading"></div>
                      <Button className="w-auto fos-btn-primary d-flex align-items-center">
                        {lang.add_user_role_btn}
                        <PlusOutlined />
                      </Button>
                    </div>{" "}
                    <div className="row">
                      <div className="col-md-6">
                        <div></div>

                        <div className="row mt-3 fos-dotted-border m-0 p-2">
                          <div className="col-md-6">
                            <div>
                              <div className="fos-label mt-2">
                                {lang.select_user_role}
                              </div>
                              <div className="mt-1 d-flex">
                                <div>
                                  <Form.Item // label="Permission"
                                    name="permission"
                                    rules={Object.values(
                                      rules?.user_permission ?? {}
                                    )}
                                  >
                                    <FormSelect
                                      name="permission"
                                      setVal={(val) => {
                                        setPermission(val);
                                      }}
                                      options={PERMISSION}
                                    />
                                  </Form.Item>
                                </div>

                                <div className="ml-20">
                                  <Form.Item>
                                    <Button className="fos-btn-border mb-1 ml-4">
                                      {lang.add_btn}
                                    </Button>
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="d-flex mt-3">
                                <div>
                                  <Form.Item>
                                    <a href="" className="login-form-forgot">
                                      {lang.edit_user_btn}
                                    </a>
                                  </Form.Item>
                                </div>
                                <div className="ml-20">
                                  <Form.Item>
                                    <a
                                      className="login-form-forgot"
                                      href=""
                                      // className=""
                                    >
                                      {" "}
                                      {lang.view_child_btn}
                                    </a>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* list vew */}
                          <div className="col-md-6">
                            {/* <ul className="item-list">
                                  {item1s.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() => handleItemClick(index)}
                                      className="list-item"
                                    >
                                      {item}
                                    </li>
                                  ))}
                                </ul> */}
                            <List initialItems={initialItems} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6 d-flex align-items-end ">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="fos-btn-primary mb-1 ml-4"
                            // onClick={onFinish}
                          >
                            {lang.save_btn}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUserRole;
