export const DISTRICTFILTER_PAGE_LABELS = {

    heading: {
        en: 'Basic Filter',
        ta: 'அடிப்படை வடிகட்டி',
        si: 'මූලික පෙරහන'
    },
    select_year: {
        en: 'Select Year',  
        ta: 'ஆண்டைத் தேர்ந்தெடுக்கவும்',
        si: 'වසර තෝරන්න'
    },
    select_month: {
        en: 'Select Month',
        ta: 'மாதத்தைத் தேர்ந்தெடுக்கவும்',
        si: 'මාසය තෝරන්න'
    },
    search: {
        en: 'Search',
        ta: 'தேடுகிறது',
        si: 'සොයන්න'
    },
    
}
