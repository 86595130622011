import { useContext, useMemo } from "react";
import { getErrorMsgLabels } from "../../../Utils/Label.service";
import { AccountSettings_FORM_RULE_MESSAGE } from "../../../Utils/validations/accountsettings_validations";
import { i18n } from "../../../App";

export const AccountSettingsRules = () => {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getErrorMsgLabels(AccountSettings_FORM_RULE_MESSAGE, language.language),
    [language]
  );

  const RULES = {
    firstname: [
      {
        required: true,
        message: lang?.firstname?.required,
      },
    ],
    surname: [
      {
        required: true,
        message: lang?.surname?.required,
      },
    ],
    card_no: [
      {
        required: true,
        message: lang?.card_no?.required,
      },
      {
        pattern: /^\d{16}$/, // Assuming 16-digit card number requirement
        message: lang?.card_no?.pattern,
      },
    ],
    valid_date: [
      {
        required: true,
        message: lang?.valid_date?.required,
      },
      {
        pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, // Assuming MM/YY format for the date
        message: lang?.valid_date?.pattern,
      },
    ],
    cvv: [
      {
        required: true,
        message: lang?.cvv?.required,
      },
      {
        pattern: /^\d{3}$/, // Assuming 3-digit CVV requirement
        message: lang?.cvv?.pattern,
      },
    ],
  };

  return RULES;
}
export default AccountSettingsRules;