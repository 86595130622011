import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined, BookFilled } from "@ant-design/icons";
import { Card } from "antd";
import { login } from "../Api";
import { useDispatch } from "react-redux";
import collage from "../../src/Assests/login1.png";
import logo from "../../src/Assests/logo.webp";
import { useNavigate } from "react-router-dom";
import { Toast } from "../Components/modules/Alert";

function AdminLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log("Success:", values);
    login(values)
      .then((val) => {
        console.log("AdminLogin Response", val);
        if (val.status === 200) {
          window.localStorage.setItem("token", val.data.data.token);
          window.localStorage.setItem("donor", val.data.data.donor);
          window.localStorage.setItem("name", val.data.data.name);
          window.localStorage.setItem("permissions", JSON.stringify(val.data.data.permissions));
          navigate("/home");
          Toast.fire({
            icon: "success",
            title: "Login Successful!",
          });
        } else {
          throw new Error("Login Failed! Invalid username or password.");
        }
      })
      .catch((error) => {
        console.error("AdminLogin Error", error);
        Toast.fire({
          icon: "error",
          title: "Login Failed!",
          text: error.message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="row p-0 m-0">
      <div className="col-md-6 fos-login-background fos-background justify-content-center align-items-center d-flex">
        <div className="text-center">
          <div className="fos-heading mb-4 fos-white" style={{ fontSize: 30, marginLeft: 50 }}>
            Feed a child and make them smile
          </div>
          <img className="w-50 fos-collage-image" src={collage}></img>
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="row  justify-content-center align-items-center d-flex"
          style={{ height: "100vh" }}
        >
          <div className="p-5">
            <div className="">
              <img className="fos-login-logo" src={logo}></img>
            </div>
            <div
              className="fos-card-heading font-bold"
              style={{ marginBottom: 20 }}
            >
              Admin Login
            </div>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <Form.Item className="">
                <div className="d-flex justify-content-between ">
                  <Checkbox>Remember me</Checkbox>

                  <a className="login-form-forgot" href="">
                    Forgot password
                  </a>
                </div>
              </Form.Item> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="fos-btn-primary w-100 mt-5"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
