import React, { useContext, useMemo, useState } from "react";
import { Table, Card } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";

import getPageLabels from "../../Utils/Label.service";
import { DONATIONRECIEVEDBYBENEFICIARRY_PAGE_LABELS } from "../../Utils/Labels/DonationReceivedByBeneficiary.page.labels";
import { i18n } from "../../App";
import DonationRecievedByBenificiaryFilter from "../../Filters/DonationRecievedByBenificiaryFilter";
import { CSVLink } from "react-csv";

function DonationRecievedByBenificiary() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(
        DONATIONRECIEVEDBYBENEFICIARRY_PAGE_LABELS,
        language.language
      ),
    [language]
  );
  const columns = [
    {
      title: lang.donation_id,
      dataIndex: "reference_no",
    },
    {
      title: lang.month,
      dataIndex: "month",
    },
    {
      title: lang.year,
      dataIndex: "year",
    },
    {
      title: lang.date,
      dataIndex: "date",
    },
    {
      title: lang.category,
      dataIndex: "category",
    },
    {
      title: lang.beneficiary_id,
      dataIndex: "beneficiary_id",
    },
    {
      title: lang.donor_id,
      dataIndex: "donor_id",
    },
    {
      title: lang.amount,
      dataIndex: "amount",
    },
  ];
  const [data, setData] = useState([]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <DonationRecievedByBenificiaryFilter set={setData} />
        </div>
      </div>

      <div className="mt-4">
        <Card>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fos-heading ml-2"> {lang.heading}</div>
            <CSVLink
              data={data}
              headers={columns.map((col) => ({
                label: col.title,
                key: col.dataIndex,
              }))}
              filename={"donations_received_by_beneficiary.csv"}
              className="download-button"
            >
              {lang.download_report} <VerticalAlignBottomOutlined />
            </CSVLink>
          </div>
          <Table className="mt-3" columns={columns} dataSource={data} />
        </Card>
      </div>
    </div>
  );
}

export default DonationRecievedByBenificiary;
