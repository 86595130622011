import React, { useContext,useMemo,useState } from 'react';
import { Table, Button, Modal } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { Input  } from 'antd';
import { Dropdown, Space, Card } from 'antd';
import {DatePicker  } from 'antd';
import getPageLabels from "../../Utils/Label.service";
import {COMMONFILTERS_PAGE_LABELS} from "../../Utils/Labels/CommonFilter.page.labels";
import { i18n } from "../../App";


const handleMenuClick = (e) => {

    console.log('click', e);
};

const items = [
    {
        label: 'Value 1',
        key: '1',
       
    },
    {
        label: 'Value 2',
        key: '2',
       
    },
   
];
const menuProps = {
    items,
    onClick: handleMenuClick,
};

const { RangePicker } = DatePicker;




function CommonFilter() {

    const { language } = useContext(i18n);

  const lang  = useMemo(
    () => getPageLabels(COMMONFILTERS_PAGE_LABELS, language.language),
    [language]
  );

    return (
        <div>
            <div className=''>
                <div className=''>
                    <div >
                       
                        <Card className="p-2">
                           <div className='row'>
                          <div className='col-md-6'>
                          <div className="d-flex justify-content-between">
                                <div className="fos-card-heading font-bold">
                                    {lang.heading}
                                </div>
                            </div>
                           <div className='row'>
                            <div className='col-md-4'>
                            <div>
                                <div className="fos-label mt-2">{lang.select_year}</div>
                                <div className='mt-1'>
                                    <Dropdown menu={menuProps}>
                                        <Button>
                                            <Space>
                                                2023
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>

                            </div>
                            </div>
                            <div className='col-md-4'>
                            <div>
                                <div className="fos-label mt-2">{lang.select_month}</div>
                                <div className='mt-1'>
                                    <Dropdown menu={menuProps}>
                                        <Button>
                                            <Space>
                                                January
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>

                            </div>
                            </div>
                            <div className='col-md-4'>
                            <div>
                                <div className="fos-label mt-2">{lang.beneficiary_id}</div>
                                <div className='mt-1'>
                                <Input placeholder="Enter Benificiary ID" />
                                </div>

                            </div>
                            </div>
                           </div>
                          
                          </div>
                          <div className='col-md-6'>
                          <div className="d-flex justify-content-between">
                                <div className="fos-card-heading font-bold">
                                    {lang.advance_filter}
                                </div>
                            </div>
                           <div className='row'>
                            <div className='col-md-6'>
                            <div>
                                <div className="fos-label mt-2">{lang.select_year_and_month}</div>
                                <div className='mt-1'>
                                <RangePicker />
                                </div>

                            </div>
                            </div>
                            <div className='col-md-6'>
                            <div>
                                <div className="fos-label mt-2">{lang.select_category}</div>
                                <div className='mt-1'>
                                    <Dropdown menu={menuProps}>
                                        <Button>
                                            <Space>
                                                SAM8
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>

                            </div>
                            </div>
                          
                           </div>
                          
                          </div>
                           </div>
                           <div className='d-flex mt-3'>
                            <Button className='fos-btn-border'>{lang.clear}</Button>
                            <Button className='fos-btn-primary ml-4'>{lang.search}</Button>
                           </div>


                        </Card>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default CommonFilter;
