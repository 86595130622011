import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal, Form, Select, Segmented } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Dropdown, Space, Card } from "antd";
import { DatePicker } from "antd";
import getPageLabels from "../Utils/Label.service";
import { i18n } from "../App";
import { COMMONFILTERS_PAGE_LABELS } from "../Utils/Labels/CommonFilter.page.labels";
import {
  getBeneficiaries,
  getByBeneficiary,
  getByDonor,
  getDonors,
} from "../Api";
import { Toast } from "../Components/modules/Alert";

const handleMenuClick = (e) => {
  console.log("click", e);
};

const items = [
  {
    label: "Value 1",
    key: "1",
  },
  {
    label: "Value 2",
    key: "2",
  },
];
const menuProps = {
  items,
  onClick: handleMenuClick,
};

const { RangePicker } = DatePicker;

function DonationRecievedByDonorFilter({ set = () => { } }) {
  const [form] = Form.useForm();
  const [donors, setDonors] = useState([]);

  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(COMMONFILTERS_PAGE_LABELS, language.language),
    [language]
  );

  const [dateType, setDateType] = useState(lang.by_month);

  const MONTHS = [
    lang.january,
    lang.february,
    lang.march,
    lang.april,
    lang.may,
    lang.june,
    lang.july,
    lang.august,
    lang.september,
    lang.october,
    lang.november,
    lang.december,
  ];

  useEffect(() => {
    form.submit();
  }, []);

  useEffect(() => {
    getDonors().then((out) => {
      setDonors(out.data.data);
    });
    getByDonor({ year: null, month: null, range: null, donor: null }).then(
      (out) => {
        set(out.data.data);
      }
    );
  }, []);

  const onFinish = (values) => {
    getByDonor(values).then((out) => {
      set(out.data.data);
      Toast.fire({
        icon: "success",
        title: lang.alert_success,
      });
    });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Card className="p-2">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="fos-card-heading font-bold col-md-3">{lang.heading}</div>
              <div className="col-md-6"><Segmented options={[lang.by_month, lang.by_range]} onChange={(val) => setDateType(val)} /></div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {dateType == lang.by_month ?
                  <div className="row">
                    <div className="col-md-6">
                      <div className="fos-label mt-2">{lang.select_year}</div>
                      <div className="mt-1">
                        <Form.Item name="year">
                          <Select
                            style={{ width: 150 }}
                            options={Array.from(
                              { length: 101 },
                              (_, i) => i + 2000
                            ).map((yr) => {
                              return { label: yr, value: yr };
                            })}
                            showSearch
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fos-label mt-2">{lang.select_month}</div>
                      <div className="mt-1">
                        <Form.Item name="month">
                          <Select
                            style={{ width: 150 }}
                            options={MONTHS.map((month, index) => {
                              return { label: month, value: index + 1 };
                            })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fos-label mt-2">
                        {lang.select_year_and_month}
                      </div>
                      <div className="mt-1">
                        <Form.Item name="range">
                          <RangePicker />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-md-3">
                <div>
                  <div className="fos-label mt-2">{lang.select_category}</div>
                  <div className="mt-1">
                    <Form.Item name="category">
                      <Select
                        style={{ width: 150 }}
                        options={[{ label: "SAM8", value: 0 }]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <Button
            className="fos-btn-border"
            onClick={() => form.resetFields()}
            htmlType="submit"
          >
            {lang.clear}
          </Button>
          <Button className="fos-btn-primary ml-4" htmlType="submit">
            {lang.search}
          </Button>
        </div>
      </Card>
    </Form>
  );
}

export default DonationRecievedByDonorFilter;
