import React, { useContext, useMemo, useState } from "react";
import { Table } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Card } from "antd";
import CommonFilter from "../modules/CommonFilter";
import getPageLabels from "../../Utils/Label.service";
import { DONATIONTARGETBYDISTRICT_PAGE_LABELS } from "../../Utils/Labels/DonationTargetByDistrict.page.lables";
import { i18n } from "../../App";
import DonationGoalByRDHSFilter from "../../Filters/DonationGoalByRDHSFilter";
import { CSVLink } from "react-csv";
const items = [
  {
    label: <div>Donation ID</div>,
    key: "0",
  },
  {
    label: <div>Transaction ID</div>,
    key: "1",
  },

  {
    label: <div>Donor ID</div>,
    key: "3",
  },
  {
    label: <div>Donor Name</div>,
    key: "4",
  },
  {
    label: <div>Total Donated Amount</div>,
    key: "5",
  },
  {
    label: <div>Donation Date</div>,
    key: "6",
  },
];

function DonationTargetByDistrict() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(DONATIONTARGETBYDISTRICT_PAGE_LABELS, language.language),
    [language]
  );

  const columns = [
    {
      title: lang.district,
      dataIndex: "rdhs",
    },
    {
      title: lang.Goal_Selected_Month,
      dataIndex: "goal",
    },
    {
      title: lang.Amount_Donation_Recieved,
      dataIndex: "reached",
    },
  ];
  const [data,setData] = useState([]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <DonationGoalByRDHSFilter set={setData} />
        </div>
      </div>

      <div className="mt-4">
        <Card>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fos-heading ml-2"> {lang.heading}</div>
            <CSVLink
              data={data}
              headers={columns.map((col) => ({
                label: col.title,
                key: col.dataIndex,
              }))}
              filename={"completion_goal_by_RDHS.csv"}
              className="download-button"
            >
              {lang.download_report} <VerticalAlignBottomOutlined />
            </CSVLink>
          </div>
          <Table className="mt-3" columns={columns} dataSource={data} />
        </Card>
      </div>
    </div>
  );
}

export default DonationTargetByDistrict;
