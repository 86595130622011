import React, { useContext, useMemo, useState } from "react";
import { Table } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Card } from "antd";
import CommonFilter from "../modules/CommonFilter";
import getPageLabels from "../../Utils/Label.service";
import { DONATIONRECIEVEDBYCATEGORY_PAGE_LABELS } from "../../Utils/Labels/DonationsReceivedByCategory.page.labels";
import { i18n } from "../../App";
import DonationRecievedByCategoryFilter from "../../Filters/DonationRecievedByCategoryFilter";
import { CSVLink } from "react-csv";

const items = [
  {
    label: <div>Donation ID</div>,
    key: "0",
  },
  {
    label: <div>Transaction ID</div>,
    key: "1",
  },

  {
    label: <div>Donor ID</div>,
    key: "3",
  },
  {
    label: <div>Donor Name</div>,
    key: "4",
  },
  {
    label: <div>Total Donated Amount</div>,
    key: "5",
  },
  {
    label: <div>Donation Date</div>,
    key: "6",
  },
];

function DonationRecievedByCategory() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(DONATIONRECIEVEDBYCATEGORY_PAGE_LABELS, language.language),
    [language]
  );

  const columns = [
    {
      title: lang.child_id,
      dataIndex: "uid",
    },
    {
      title: lang.public_id,
      dataIndex: "public_id",
    },

    {
      title: lang.donation_id,
      dataIndex: "reference_no",
    },

    {
      title: lang.month,
      dataIndex: "month",
    },
    {
      title: lang.year,
      dataIndex: "year",
    },
    {
      title: lang.date,
      dataIndex: "date",
    },
    {
      title: lang.category,
      dataIndex: "category",
    },

    {
      title: lang.amount,
      dataIndex: "amount",
    },
  ];
  const [data, setData] = useState([]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <DonationRecievedByCategoryFilter set={setData} />
        </div>
      </div>

      <div className="mt-4">
        <Card>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fos-heading ml-2"> {lang.heading}</div>
            <CSVLink
              data={data}
              headers={columns.map((col) => ({
                label: col.title,
                key: col.dataIndex,
              }))}
              filename={"donations_received_by_category.csv"}
              className="download-button"
            >
              {lang.download_report} <VerticalAlignBottomOutlined />
            </CSVLink>
          </div>
          <Table className="mt-3" columns={columns} dataSource={data} />
        </Card>
      </div>
    </div>
  );
}

export default DonationRecievedByCategory;
