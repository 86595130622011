import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, Button, Modal, Form, Select } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Dropdown, Space, Card } from 'antd';
import { DatePicker } from 'antd';
import getPageLabels from "../../Utils/Label.service";
import { ANNUALTARGETFORNATIONAL_PAGE_LABELS } from "../../Utils/Labels/AnnualTargetForNational.page.labels";
import { i18n } from "../../App";
import { responseAlert,failureAlert } from "../modules/Alert";
import { addAnnualGoal, getAnnualGoals } from '../../Api';



const handleMenuClick = (e) => {

    console.log('click', e);
};

const items = [
    {
        label: 'Value 1',
        key: '1',

    },
    {
        label: 'Value 2',
        key: '2',

    },

];
const menuProps = {
    items,
    onClick: handleMenuClick,
};

const { RangePicker } = DatePicker;




function AnnulTargetForNational() {
    const [data, setData] = useState([]);

    const { language } = useContext(i18n);

    const lang = useMemo(
        () => getPageLabels(ANNUALTARGETFORNATIONAL_PAGE_LABELS, language.language),
        [language]
    );

    const fetchGoals = () => {
        getAnnualGoals().then(out => {
            setData(out.data.data);
        })
    }

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Success:', values);
        addAnnualGoal(values).then(response => {
            fetchGoals();
            responseAlert(response.status,language.language,lang.alert_save_success);
      })
      .catch((error) => {
        // Catch any network errors
        failureAlert(error,language.language);
      });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const CATEGORIES = [{ label: 'SAM8', value: 1 }];
    const columns1 = [
        {
            title: lang.year,
            dataIndex: 'year',
            key: 'year',
            fixed: 'left',
        },
        {
            title: lang.category,
            dataIndex: 'category',
            key: 'category',
            fixed: 'left',
            render: (_, record) => {
                const filtered = CATEGORIES.filter((itm) => itm.value == record.category);
                return filtered[0] ? filtered[0].label : ""
            }
        },
        {
            title: lang.goal,
            dataIndex: 'goal',
            key: 'goal',
            fixed: 'left',
        },

    ];

    useEffect(() => { fetchGoals(); }, []);

    return (
        <div>
            <div className=''>
                <div className=''>
                    <div >
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                            form={form}
                        >
                            <Card className="p-2">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="d-flex justify-content-between">
                                            <div className="fos-card-heading font-bold">
                                                {lang.heading}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <div>
                                                    <div className="fos-label mt-2">{lang.select_year}</div>
                                                    <div className='mt-1'>
                                                        <Form.Item name="year">
                                                            <Select style={{ width: 150 }} options={Array.from({ length: 101 }, (_, i) => i + 2000).map((yr) => { return { label: yr, value: yr } })} showSearch />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>
                                                    <div className="fos-label mt-2">{lang.select_category}</div>
                                                    <div className='mt-1'>
                                                        <Form.Item name="category">
                                                            <Select style={{ width: 150 }} options={CATEGORIES} showSearch />
                                                        </Form.Item>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <div className="fos-label mt-2">{lang.select_category_2}</div>
                                                <Form.Item name="goal">
                                                    <Input placeholder={lang.goal} />
                                                </Form.Item>
                                            </div>
                                            <div className='col-md-3 d-flex align-items-end '>
                                                <Button className='fos-btn-primary mb-1 ml-4' htmlType='submit'>{lang.generate}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Form>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <Card>
                    <Table
                        columns={columns1}
                        dataSource={data}
                        scroll={{ x: 150, }} sticky
                        className='mt-12'
                        rowClassName='shrink-cell'
                        pagination={false}
                    />
                </Card>
            </div>
        </div>
    );
}

export default AnnulTargetForNational;
