import { useContext, useMemo } from "react";
import { getErrorMsgLabels } from "../../../Utils/Label.service";
import { Login_FORM_RULE_MESSAGE } from "../../../Utils/validations/login_validations";
import { i18n } from "../../../App";

export const LoginFormRules = () => {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getErrorMsgLabels(Login_FORM_RULE_MESSAGE, language.language),
    [language]
  );

  const RULES = {
    username: [
      {
        required: true,
        message: lang?.username?.required,
      },
    ],
    password: [
        {
          required: true,
          message: lang?.password?.required,
        },
        {
          min: 8, // Minimum password length
          message: lang?.password?.min,
        },

        /*
        add below password validation if required
        {
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/, // At least one uppercase, one lowercase, and one digit
          message: "Password must contain at least one uppercase letter, one lowercase letter, and one digit."
        },
        */

      ],
    rememberMe: [
      // define rules for rememberMe if needed
    ],
    forgotPassword: [
      // define rules for forgotPassword if needed
    ],
    agreeToTerms: [
      // define rules for agreeToTerms if needed
    ],
  };

  return RULES;
}
export default LoginFormRules;
