import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal } from "antd";
import {
  IssuesCloseOutlined,
  DollarOutlined,
  BranchesOutlined,
  RedoOutlined,
  CloseOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import {
  CheckOutlined,
  ArrowRightOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import getPageLabels from "../../Utils/Label.service";
import { BENIFICISRIES_PAGE_LABELS } from "../../Utils/Labels/Benificiaries.page.labels";
import { i18n } from "../../App";
import { getBeneficiaries } from "../../Api";
import BeneciaryActions from "../modules/BeneciaryActions";
import TableFilter from "../form/TableFilter";

function Beneficiaries() {
  const { language } = useContext(i18n);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const lang = useMemo(
    () => getPageLabels(BENIFICISRIES_PAGE_LABELS, language.language),
    [language]
  );

  const fetchBeneficiaries = () => {
    getBeneficiaries()
      .then((out) => {
        if (out.status == 200) {
          const mapped = out.data.data.map((itm, index) => {
            return { ...itm, key: index };
          });
          setData(mapped);
          setFilteredData(mapped); // Initialize filteredData with all data
        }
      })
      .catch((e) => {
        console.log("CATCH", e);
      });
  };

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  // Function to handle filtering based on user input
  const handleFilter = ({ column, searchText }) => {
    if (!column || !searchText) {
      // If either column or searchText is not provided, reset the filteredData to all data
      setFilteredData(data);
      return;
    }

    // Implement your filtering logic here
    const filtered = data.filter((item) => {
      if (column === "funding_state") {
        return getFundingState(item[column])
          ?.props?.children?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()); // Get the cell value for the selected column
      } else if (column === "sync_state") {
        return getSyncState(item[column])
          ?.props?.children?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()); // Get the cell value for the selected column
      } else if (column === "dob") {
        return getAge(item[column])
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()); // Get the cell value for the selected column
      } else {
        return (item[column] || "")
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()); // Get the cell value for the selected column
      }
    });
    setFilteredData(filtered);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };

  const getAge = (dob) => {
    if (!dob) return null;
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    const months = monthDifference < 0 ? monthDifference + 12 : monthDifference;
    return `${age}Y ${months}M`;
  };

  const renderActions = (record) => {
    return (
      <Space>
        <BeneciaryActions
          title="Verify"
          background="#52c41a"
          color="#FFFFFF"
          icon={<CheckOutlined />}
          record={record}
          action={0}
          refresh={fetchBeneficiaries}
        />
        <BeneciaryActions
          title="Push to Funding"
          background="#1890ff"
          color="#FFFFFF"
          icon={<ArrowRightOutlined style={{ color: "#FFFFFF" }} />}
          record={record}
          action={1}
          refresh={fetchBeneficiaries}
        />
        <BeneciaryActions
          title="Divert Funds"
          background="#ffd700"
          color="#696969"
          icon={<BranchesOutlined style={{ color: "#696969" }} />}
          record={record}
          action={2}
          refresh={fetchBeneficiaries}
        />
        <BeneciaryActions
          title="Re-enroll"
          background="#ffa940"
          color="#FFFFFF"
          icon={<ReloadOutlined style={{ color: "#FFFFFF" }} />}
          record={record}
          action={3}
          refresh={fetchBeneficiaries}
        />
        <BeneciaryActions
          title="Exclude"
          background="#ff4f4f"
          color="#f3f3f3"
          icon={<CloseOutlined style={{ color: "#f3f3f3" }} />}
          n
          record={record}
          action={4}
          refresh={fetchBeneficiaries}
        />
        <BeneciaryActions
          title="Deceased"
          background="#808080" // Dark grey color
          color="#FFFFFF"
          icon={<FrownOutlined style={{ color: "#FFFFFF" }} />}
          record={record}
          action={5}
          refresh={fetchBeneficiaries}
        />
      </Space>
    );
  };

  const getFundingState = (state) => {
    let out = null;
    switch (state) {
      case 0: // From new - Until 90K
        out = <div className="fos-lable-green">Enrolled</div>;
        break;
      case 1: // 90k reached
        out = <div className="fos-lable-blue-5 ">Pending</div>;
        break;
      case 2: // Payouts started
        out = <div className="fos-lable-green ">Funding</div>;
        break;
      case 3: // Payout completed
        out = <div className="fos-lable-blue-9 ">Complete</div>;
        break;
      case 4: // Manually or age based exclution
        out = <div className="fos-lable-red ">Excluded</div>;
        break;
      case 5: // Manually marked as deceased
        out = <div className="fos-lable-grey ">Deceased</div>;
        break;
      default:
        break;
    }
    return out;
  };

  const getSyncState = (state) => {
    let out = null;
    switch (state) {
      case 0:
        out = <div className="fos-lable-orange ">Unverified</div>;
        break;
      case 1:
        out = <div className="fos-lable-green ">Verified</div>;
        break;
      case 2:
        out = <div className="fos-lable-red ">Funds!</div>;
        break;
      default:
        break;
    }
    return out;
  };

  // const getAge = (dob) => {
  //   if (!dob) return null;
  //   return new Date().getFullYear() - new Date(dob).getFullYear();
  // };

  const columns = [
    {
      title: lang.child_id,
      width: 150,
      dataIndex: "uid",
      key: "uid",
      fixed: "left",
      search: true,
    },
    {
      title: lang.public_id,
      width: 150,
      dataIndex: "public_id",
      key: "public_id",
      fixed: "left",
      search: true,
    },
    {
      title: lang.name,
      width: 150,
      dataIndex: "name",
      key: "name",
      search: true,
    },
    {
      title: lang.status,
      dataIndex: "sync_state",
      key: "1",
      width: 150,
      search: true,
      render: (_, record) => getSyncState(record.sync_state),
    },
    {
      title: lang.funding,
      dataIndex: "funding_state",
      key: "2",
      width: 150,
      search: true,
      render: (_, record) => getFundingState(record.funding_state),
    },
    {
      title: lang.age,
      dataIndex: "dob",
      key: "3",
      width: 150,
      search: true,
      render: (_, record) => {
        return <div className="fos-lable-ash ">{getAge(record.dob)}</div>;
      },
    },
    {
      title: lang.bank_account,
      dataIndex: "bank_account",
      key: "4",
      width: 150,
      search: true,
    },

    {
      title: lang.phone_primary,
      dataIndex: "phone_primary",
      key: "4",
      width: 150,
      search: true,
    },
    {
      title: lang.phone_secondary,
      dataIndex: "phone_secondary",
      key: "5",
      width: 150,
      search: true,
    },
    {
      title: lang.district,
      dataIndex: "rdhs",
      key: "6",
      width: 150,
      search: true,
    },
    {
      title: lang.moh_area,
      dataIndex: "moh",
      key: "7",
      width: 150,
      search: true,
    },
    {
      title: lang.no_of_enrolment,
      dataIndex: "no_of_enrolment",
      key: "8",
      width: 150,
      search: true,
    },
    {
      title: lang.exclusion_reason,
      dataIndex: "remarks",
      key: "9",
      width: 150,
      search: true,
    },

    {
      title: lang.action,
      key: "operation",
      fixed: "right",
      width: 200,
      render: (_, record) => renderActions(record),
    },
  ];
  return (
    <div>
      {/* {alertVisible && (
        <Alert className="" message="Add the custom alert message here!" onClose={handleAlertClose} />
      )} */}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fos-heading">{lang.heading}</div>
          <div className="fos-heading-small">{lang.heading_small}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div></div>
          <div>
            <div className="mt-1">
              {/* Render the TableFilter component */}
              <TableFilter columns={columns} onFilter={handleFilter} />
            </div>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        scroll={{ x: 1500 }}
        sticky
        className="mt-3"
      />
      {selectedRow && (
        <Modal
          title={<div className="fos-heading text-center">Action</div>}
          visible={modalVisible}
          onCancel={handleModalClose}
          footer={
            [
              // <Button key="close" onClick={handleModalClose}>
              //   Close
              // </Button>,
            ]
          }
        >
          <div className="d-flex justify-content-between mt-4">
            <Button className="fos-btn-primary">
              {" "}
              {lang.push_to_funding_list}{" "}
            </Button>
            <Button className="fos-btn-primary"> {lang.deactivate} </Button>
            <Button className="fos-btn-primary"> {lang.re_enroll} </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Beneficiaries;
