export const DONATIONSRECIEVEDBYDISTRICT_PAGE_LABELS = {
  heading: {
    en: "Donation Recieved By RDHS Areas",
    ta: "RDHS பகுதிகளால் நன்கொடை பெறப்பட்டது",
    si: "RDHS ප්‍රදේශ මගින් ලැබුණු පරිත්‍යාග",
  },
  download_report: {
    en: "Download Report",
    ta: "அறிக்கையைப் பதிவிறக்கவும்",
    si: "වර් තාව බාගත කරන්න",
  },
  district: {
    en: "RDHS Area",
    ta: "RDHS பகுதி",
    si: "RDHS ප්‍රදෙශ්ය",
  },
  no_bnf_reached_90K: {
    en: "No. Bnf reached 90K",
    ta: "பயனாளிகளின் எண்ணிக்கை 90 ஆயிரத்தை எட்டியது",
    si: "ප්‍රතිලාභීන් සංඛ්‍යාව 90K කරා ළඟා විය",
  },
  total_beneficiaries: {
    en: "No. Ttl Bnf",
    ta: "மொத்த பயனாளிகள்",
    si: "මුළු ප්‍රතිලාභීන්",
  },
  no_donation_by_district: {
    en: "No. Donation by RDHS Area",
    ta: "RDHS பகுதியின் நன்கொடைகளின் எண்ணிக்கை",
    si: "RDHS ප්‍රදේශයෙන් පරිත්‍යාග ගණන",
  },
};
