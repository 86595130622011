import React, { useContext, useMemo, useRef } from "react";

import { Card, Avatar, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Modal } from "antd";
import { i18n } from "../../App";
import { MAKEDONATIONS_PAGE_LABELS } from "../../Utils/Labels/MakeDonations.page.labels";
import getPageLabels from "../../Utils/Label.service";
import snehaImage from "../../Assests/logo.webp";

import { useReactToPrint } from "react-to-print";

import FHB from "../../Assests/FHB.png";
import HPB from "../../Assests/HPB.png";
import NM from "../../Assests/NM.png";
// import who from "../Assests/who.png";
import PS from "../../Assests/PS.png";
import GOV from "../../Assests/GOV.png";
import { BASE_URL } from "../../Api";

const CertificatePopup = ({ certificate, FosUser, onClose }) => {
  const { language } = useContext(i18n);
  const lang = useMemo(
    () => getPageLabels(MAKEDONATIONS_PAGE_LABELS, language.language),
    [language]
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Modal
      visible={true} // Use a state variable to control visibility
      onCancel={onClose} // Close the modal when clicking the close button
      footer={null} // Hide the footer (i.e., OK and Cancel buttons)
      className="my-certificate-modal"
    >
      <div className="certificate-popup">
        <div className="certificate-content">
          <div className="d-flex justify-content-end align-items-center">
            <div className="mt-4">
              <Button
                style={{ width: "max-content", marginBottom: "20px" }}
                className="fos-btn-primary"
                type="success"
                // color="primary"
                onClick={handlePrint}
                title="Print certificate"
              >
                Print certificate
              </Button>
            </div>
          </div>
          <div
            className="d-flex justify-content-center "
            ref={componentRef}
            style={{ padding: "0", margin: "0" }}
          >
            {" "}
            <div className="w-60 p-5 fos-certificate">
              <img src={snehaImage} alt="Sneha Image" className="sneha-image" />
              <Card className="p-2">
                <div className="d-flex justify-content-center">
                  <div>
                    <CheckCircleTwoTone className="certificate-check" />
                    <div className="fos-card-heading font-bold text-center">
                      {" "}
                      {lang.donation_successful}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div>
                    <div className="text-center">
                      <Avatar
                        src={
                          <img
                            className="fos-user-img"
                            src={FosUser}
                            alt="avatar"
                          />
                        }
                        size={"large"}
                      />

                      <div className="fos-card-heading font-bold">
                        {certificate?.first_name}&nbsp;
                        {certificate?.last_name}
                      </div>
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {/* Kusal Perera{" "} */}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg2}
                    </div>
                  </div>
                </div>

                <div className="row mt-3 justify-content-center">
                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.donor_id}</div>
                    <div className="fos-label-detail">
                      {certificate?.donor_id.toString().padStart(5, "0")}
                    </div>
                  </div>

                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.date}</div>
                    <div className="fos-label-detail">
                      {certificate?.updated
                        ? new Intl.DateTimeFormat().format(
                          new Date(certificate?.updated)
                        )
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center">
                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.public_id}</div>
                    {certificate?.beneficiaries?.map((ben, index) => (
                      <div className="fos-label-detail" key={index}>
                        {ben?.public_id}
                      </div>
                    ))}
                  </div>

                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.time}</div>
                    <div className="fos-label-detail">
                      {certificate?.updated
                        ? new Intl.DateTimeFormat("en-AU", {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          timeZoneName: "short",
                        }).format(new Date(certificate?.updated))
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center">
                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.transaction_id}</div>
                    <div className="fos-label-detail">
                      {certificate?.transaction_id}
                    </div>
                  </div>

                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.amount}</div>
                    <div className="fos-label-detail">
                      {new Intl.NumberFormat("lk-LK", {
                        style: "currency",
                        currency: "LKR",
                      }).format(certificate?.amount)}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center">
                  <div className="col-md-6 text-center">
                    <div className="fos-label">{lang.reference_no}</div>
                    <div className="fos-label-detail">
                      {certificate?.reference_no}
                    </div>
                  </div>

                  <div className="col-md-6 text-center">
                    <div className="fos-label"></div>
                    <div className="fos-label-detail">
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="stakeholder-logos">
                    <img src={FHB} alt="FHB" />
                    <img src={HPB} alt="HPB" />
                    <img src={NM} alt="NM" />
                    <img src={PS} alt="PS" />
                    <img src={GOV} alt="GOV" />
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.hostname}/certificate/${certificate?.reference_no}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  fontSize: "36px",
                  color: "#1877f2",
                  marginRight: "20px",
                  marginTop: "15px",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/nutritiondivision_sl/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  fontSize: "36px",
                  color: "#bc2a8d",
                  marginRight: "20px",
                  marginTop: "15px",
                }}
              />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${window.location.hostname}/certificate/${certificate?.reference_no}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                style={{
                  fontSize: "36px",
                  color: "#1da1f2",
                  marginRight: "20px",
                  marginTop: "15px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CertificatePopup;
