export const Register_FORM_RULE_MESSAGE = {
    firstname: {
      required: {
        en: "Please input your first name!",
        ta: "உங்கள் முதல் பெயரை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ මුල් නම ඇතුළත් කරන්න!"
      },
    },
    lastname: {
      required: {
        en: "Please input your last name!",
        ta: "உங்கள் கடைசி பெயரை உள்ளிடவும்!",
        si: "කරුණාකර ඔබගේ අවසන් නම ඇතුලත් කරන්න!"
      },
    },
    email: {
      required: {
        en: "Please input your email!",
        ta: "உங்கள் மின்னஞ்சலை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ විද්යුත් තැපැල් ලිපිනය ඇතුළත් කරන්න!"
      },
      pattern: {
        en: "Please input a valid email address!",
        ta: "செல்லுபடியாக ஒரு செயல்முறை மின்னஞ்சல் முகவரியை உள்ளிடவும்!",
        si: "කරුණාකර වලංගු විද්යුත් තැපැල් ලිපිනයක් ඇතුළත් කරන්න!"
      },
    },
    phone: {
      required: {
        en: "Please input your phone number!",
        ta: "உங்கள் தொலைபேசி எண்ணை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ දුරකථන අංකය ඇතුළත් කරන්න!"
      },
      pattern: {
        en: "Please input a valid phone number!",
        ta: "செல்லுபடியாக ஒரு செயல்முறை தொலைபேசி எண்ணை உள்ளிடவும்!",
        si: "කරුණාකර වලංගු දුරකථන අංකයක් ඇතුළත් කරන්න!"
      },
    },
    password: {
      required: {
        en: "Please input your password!",
        ta: "உங்கள் கடவுச்சொல்லை உள்ளிடவும்!",
        si: "කරුණාකර ඔබේ මුරපදය ඇතුළත් කරන්න!"
      },
      min: {
        en: "Password must be at least 8 characters long.",
        ta: "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.",
        si: "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් දිග විය යුතුය."
      },
    },
    confirm_password: {
      required: {
        en: "Please confirm your password!",
        ta: "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்!",
        si: "කරුණාකර ඔබේ මුරපදය තහවුරු කරන්න!"
      },
      min: {
        en: "Password must be at least 8 characters long.",
        ta: "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.",
        si: "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් දිග විය යුතුය."
      },
      password_not_matched: {
        en: "Passwords don't match.",
        ta: "கடவுச்சொற்கள் பொருந்தவில்லை.",
        si: "මුරපද නොගැලපේ."
      },
    },
    rememberMe: {
      en: "Remember me",
      ta: "என் விவரங்களை நினைவில் கொள்க",
      si: "මගේ විස්තර මතක තියාගන්න"
    },
    forgotPassword: {
      en: "Forgot password",
      ta: "கடவுச்சொல்லை மறந்துவிட்டேன்",
      si: "මුරපදය අමතකද?"
    },
    agreeToTerms: {
      en: "I agree to all the Terms and Privacy policy",
      ta: "அனைத்து விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கைகளையும் நான் ஏற்கிறேன்",
      si: "මම සියලු නියම සහ රහස්‍යතා ප්‍රතිපත්තියට එකඟ වෙමි."
    },
    register: {
      en: "Register",
      ta: "பதிவுபெறுக",
      si: "ලියාපදිංචි"
    },
    alreadyHaveAccount: {
      en: "Already have an account?",
      ta: "ஏற்கனவே ஒரு கணக்கு உள்ளதா?",
      si: "දැනටමත් ගිණුමක් තිබේද?"
    }
  };
  