import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import { getBanners, ASSET_URL } from "../../Api/index";

function BannerCarousel() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const data = await getBanners();
        setImages(data?.data?.data);
      } catch (err) {
        console.error("Error fetching banners:", err);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="mt-4">
      <div>
        <Carousel className="carousel-container" autoplay autoplaySpeed={4000}>
          {images?.map((image, index) => (
            <div key={index}>
              <img src={`${ASSET_URL}/banners/${image?.img}`} alt="banner" />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default BannerCarousel;
