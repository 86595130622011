import React, { useContext, useMemo } from "react";
import { Menu, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import { useSelector } from "react-redux";
import { items } from "../../Constants";
import logo from "../../Assests/logo.webp";
import logos from "../../Assests/logo-small.webp";
import { useNavigate } from "react-router-dom";
import { getImgs, getPageLabels } from "../../Utils/Label.service";
import { SIDEBAR_BASE_LABELS } from "../../Utils/Labels/Sidebar.base.labels";
import { i18n } from "../../App";
import smallLogo from "../../Assests/ksoft_logo.png";

const SideBar = () => {
  const { language } = useContext(i18n);
  const donor = window.localStorage.getItem("donor").toString();
  const token = window.localStorage.getItem("token");
  const lang = useMemo(
    () => getPageLabels(SIDEBAR_BASE_LABELS, language.language),
    [language]
  );
  const imgs = useMemo(() => getImgs(language.language), [language]);
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();

  const handleMenuItemClick = (url) => {
    navigate(url);
  };
  const handleHomeClixk = () => {
    if (token && donor == "true") {
      navigate("/donorDashboard");
    } else if (token) {
      navigate("/home");
    } else {
      navigate("/");
    }
  };
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div
        className="fos-logo text-center mt-3"
        style={{ cursor: "pointer" }}
        onClick={handleHomeClixk}
      >
        {collapsed ? (
          <span>
            <img className="w-50" src={logos} alt="Logo" />
          </span>
        ) : (
          <span>
            <img className="w-50" src={imgs.logo} alt="Logo" />
          </span>
        )}
      </div>
      <Menu
        className="mt-3"
        theme="light"
        defaultSelectedKeys={["1"]}
        mode="inline"
      >
        {items
          .filter((itm) =>
            donor == "true" ? itm.donor == true : itm.donor == false
          )
          .map((item) => {
            if (item.nav) {
              if (item.children) {
                return (
                  <Menu.SubMenu
                    icon={item.icon}
                    title={lang[item.label] ?? ""}
                    key={item.key}
                  >
                    {item.children.map((child) => (
                      <Menu.Item
                        key={child.key}
                        icon={child.icon}
                        onClick={() => {
                          if (child.url) handleMenuItemClick(child.url);
                        }}
                      >
                        <Tooltip
                          title={lang[child.label] ?? ""}
                          placement="right"
                        >
                          <div>{lang[child.label] ?? ""}</div>
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={() => {
                      if (item.url) handleMenuItemClick(item.url);
                    }}
                  >
                    {lang[item.label] ?? ""}
                  </Menu.Item>
                );
              }
            } else {
              return null;
            }
          })}
      </Menu>
      <div className="copyright">
        <div className="company-logo">
          <span className="powered-by fos-font-10">Powered by </span>
          <img src={smallLogo} alt="K-SOFT Logo" style={{ width: "100px" }} />
          {/* <img src={smallLogo} alt="Company Logo" width="50" height="22" /> */}
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;
