export const MANAGEDASHBOARD_PAGE_LABELS = {

    heading: {
        en: 'Change Banners',
        ta: 'பேனர்களை மாற்றவும்',
        si: 'බැනර් වෙනස් කරන්න'
    },
    upload_btn: {
        en: 'Uplaod',
        ta: 'பதிவேற்றவும்',
        si: 'උඩුගත කරන්න'
    },
    imagesize: {
        en: '* Image resolution size to upload: 1200 x 800',
        ta: '* பதிவேற்ற வேண்டிய படத் தெளிவுத்திறன் அளவு: 1200 x 800',
        si: '* උඩුගත කළ යුතු රූප විභේදන ප්‍රමාණය: 1200 x 800'
    },


}
