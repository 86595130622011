export const USERROLES_PAGE_LABELS = {

    heading: {
        en: 'User Roles',
        ta: 'பயனர் பாத்திரங்கள்',
        si: 'පරිශීලක භූමිකාවන්'
    },
    add_user_role_btn: {
        en: 'Add New User Role',
        ta: 'புதிய பயனர் பங்கை சேர்க்க',
        si: 'නව පරිශීලක භූමිකාවක් එක් කරන්න'
    },
    role_name: {
        en: 'Role Name',
        ta: 'பாத்திரத்தின் பெயர்',
        si: 'භූමිකාවේ නම'
    },
    permission: {
        en: 'Permission',
        ta: 'அனுமதி',
        si: 'අවසර'
    },
    action: {
        en: 'Action',
        ta: 'நடவடிக்கை',
        si: 'ක්‍රියාව'
    },
    view_user_btn: {
        en: 'View User',
        ta: 'பயனரைக் காண்க',
        si: 'පරිශීලක බලන්න'
    },
    edit_user_role: {
        en: 'Edit User',
        ta: 'பயனரைத் திருத்து',
        si: 'පරිශීලක සංස්කරණය කරන්න'
    },
    role_name_label: {
        en: "Role Name",
        ta: "பாத்திரத்தின் பெயர்",
        si: "භූමිකාවේ නම",
    },
    permission_add_name_label: {
        en: "Add Permission",
        ta: "கூட்டு அனுமதி",
        si: "අවසර එක් කරන්න",
    },
    select: {
        en: "Select",
        ta: "தேர்ந்தெடுக்கவும்",
        si: "තෝරන්න",
    },
    exit: {
        en: "Complete",
        ta: "முழுமை",
        si: "සම්පූර්ණයි",
    },
    alert_save_success: {
        en: "User Role added Successfully!",
        ta: "பயனர் பங்கு வெற்றிகரமாக சேர்க்கப்பட்டது!",
        si: "පරිශීලක භූමිකාව සාර්ථකව එකතු කරන ලදී!",
    },
    alert_save_error: {
        en: "Failed to add User Role!",
        ta: "பயனர் பங்கைச் சேர்க்க முடியவில்லை!",
        si: "පරිශීලක භූමිකාව එක් කිරීමට අසමත් විය!",
    },



}
