import React, { useContext, useEffect, useMemo, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Input, Card, Button, Form } from "antd";

import getPageLabels from "../../Utils/Label.service";
import { ACCOUNTSETTINGS_PAGE_LABELS } from "../../Utils/Labels/AccountSettings.page.labels";
import { i18n } from "../../App";
import { responseAlert,Toast,failureAlert } from "../modules/Alert";
import AccountSettingsRules from "./validations/accountsettings_validation_rules";
import { getMyDonorProfile, updateMyDonorProfile } from "../../Api";
import { ASSET_URL } from "../../Api/index";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const AccountSetting = () => {
  const { language } = useContext(i18n);

  const [form] = Form.useForm()

  const rules = AccountSettingsRules();

  const lang = useMemo(
    () => getPageLabels(ACCOUNTSETTINGS_PAGE_LABELS, language.language),
    [language]
  );

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [profile, setProfile] = useState(null);

  const onFinish = (values) => {
    const data = new FormData();
    data.append('first_name', values.firstname);
    data.append('last_name', values.lastname);
    data.append('avatar', values.avatar ? values.avatar.file.originFileObj : null);
    updateMyDonorProfile(data).then((response) => {
      responseAlert(response.status,language.language,lang.alert_update_success);
    })
    .catch((error) => {
      // Catch any network errors
      failureAlert(error,language.language);
    });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file.originFileObj);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {lang.upload}
      </div>
    </div>
  );

  const getProfile = () => {
    getMyDonorProfile().then((out) => {
      const prof = out.data.data;
      setProfile(prof);
      console.log("SEETTING PROFILE", prof);
      if (prof && prof.img) {
        setImageUrl(`${ASSET_URL}/avatar/${prof.img}`);
      }
    });
  };

  const showThumbnail = (arg1, arg2) => {
    const url = URL.createObjectURL(arg1.file);
    setImageUrl(url);
  }

  useEffect(() => { getProfile() }, []);

 

  return (
    <>
      <Card className="p-2">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold"> {lang.heading}</div>
            </div>
            <div className="row mt-4 ww-50">
              <div className="col-md-12">
                {profile ? <Form onFinish={onFinish} autoComplete="off" form={form}
                  initialValues={{ "firstname": profile?.first_name, "lastname": profile?.last_name }}>
                  <div>
                    <div className="fos-label mt-2">
                      {" "}
                      {lang.edit_profile_image}
                    </div>
                    <div className="mt-1">
                      <Form.Item name="avatar">
                        <Upload
                          listType="picture-circle"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={showThumbnail}
                          multiple={false}
                        >
                          {imageUrl ? (
                            <div
                              style={{
                                width: "100px", // Adjust the width and height to your preference
                                height: "100px", // These should be equal for a perfect circle
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            >
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover", // This ensures the image fits within the circle
                                }}
                              />
                            </div>
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <div className="fos-label mt-2">
                        {lang.edit_first_name}
                      </div>
                      <div className="mt-1">
                        <Form.Item
                          name="firstname"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div className="fos-label mt-2"> {lang.edit_surname}</div>
                      <div className="mt-1">
                        <Form.Item
                          name="lastname"
                        >
                          <Input defaultValue={profile?.last_name} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row mt-3">
                      <div className="col-md-6 d-flex align-items-end ">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="fos-btn-primary mb-1 ml-4"
                        >
                          {lang.save}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AccountSetting;
