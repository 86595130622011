export const COMMONFILTERS_PAGE_LABELS = {
  heading: {
    en: "Basic Filter",
    ta: "அடிப்படை வடிகட்டி",
    si: "මූලික පෙරහන",
  },
  select_year: {
    en: "Select Year",
    ta: "ஆண்டைத் தேர்ந்தெடுக்கவும்",
    si: "වසර තෝරන්න",
  },
  select_month: {
    en: "Select Month",
    ta: "மாதத்தைத் தேர்ந்தெடுக்கவும்",
    si: "මාසය තෝරන්න",
  },
  beneficiary_id: {
    en: "Benificiary ID",
    ta: "பயனாளி ஐடி",
    si: "ප්‍රතිලාභි හඳුනාගැනීමේ අංකය",
  },
  donor: {
    en: "Donor",
    ta: "நன்கொடையாளர்",
    si: "දායකයා",
  },
  advance_filter: {
    en: "Advanced Filter",
    ta: "மேம்பட்ட வடிப்பான்",
    si: "උසස් පෙරහන",
  },
  select_year_and_month: {
    en: "Select Year and Month",
    ta: "ஆண்டு மற்றும் மாதத்தைத் தேர்ந்தெடுக்கவும்",
    si: "වසර සහ මාසය තෝරන්න",
  },
  select_category: {
    en: "Select Category",
    ta: "பிரிவை தேர்வு செய்க",
    si: "ප්‍රභේදය තෝරන්න",
  },
  clear: {
    en: "Clear",
    ta: "தெளிவுபடுத்துங்கள்",
    si: "පැහැදිලි කරන්න",
  },
  search: {
    en: "Search",
    ta: "தேடுகிறது",
    si: "සොයන්න",
  },
  january: {
    en: "January",
    ta: "ஜனவரி",
    si: "ජනවාරි",
  },
  february: {
    en: "February",
    ta: "பிப்ரவரி",
    si: "පෙබරවාරි",
  },
  march: {
    en: "March",
    ta: "மார்ச்",
    si: "මාර්තු",
  },
  april: {
    en: "April",
    ta: "ஏப்ரல்",
    si: "අප්රේල්",
  },
  may: {
    en: "May",
    ta: "மே",
    si: "මැයි",
  },
  june: {
    en: "June",
    ta: "ஜூன்",
    si: "ජූනි",
  },
  july: {
    en: "July",
    ta: "ஜூலை",
    si: "ජුලි",
  },
  august: {
    en: "August",
    ta: "ஆகஸ்ட்",
    si: "අගෝස්තු",
  },
  september: {
    en: "September",
    ta: "செப்டம்பர்",
    si: "සැප්තැම්බර්",
  },
  october: {
    en: "October",
    ta: "அக்டோபர்",
    si: "ඔක්තෝම්බර්",
  },
  november: {
    en: "November",
    ta: "நவம்பர்",
    si: "නොවැම්බර්",
  },
  december: {
    en: "December",
    ta: "டிசம்பர்",
    si: "දෙසැම්බර්",
  },
  alert_success: {
    en: "Report Generated Successfully!",
    ta: "அறிக்கை வெற்றிகரமாக உருவாக்கப்பட்டது!",
    si: "වාර්තාව උත්පාදනය සාර්ථකයි!",
  },
  by_month: {
    en: "By Month",
    ta: "மாதத்தின்படி",
    si: "මාසය වන විට",
  },
  by_range: {
    en: "Date Range",
    ta: "தேதி வரம்பு",
    si: "දින පරාසය",
  },
};
