export const PUBLICDASHBOARD_PAGE_LABELS = {
  hi: {
    en: "Hi",
    ta: "வணக்கம்",
    si: "ආයුබෝවන්",
  },
  welcome1: {
    en: "Welcome back to SNEHA dashboard",
    ta: "SNEHA டாஷ்போர்டுக்கு மீண்டும் வரவேற்கிறோம்",
    si: "ස්නේහ උපකරණ පුවරුව වෙත නැවත සාදරයෙන් පිළිගනිමු",
  },
  welcome2: {
    en: "Welcome to ",
    ta: "வரவேற்கிறோம்ம் ",
    si: " ස්නේහ වෙත සාදරයෙන් පිළිගනිමු ",
  },
  donate_btn: {
    en: "Donate",
    ta: "தானம் செய்",
    si: "පරිත්‍යාග කරන්න",
  },
  login_btn: {
    en: "Login/Register",
    ta: "உள்நுழை/பதிவு",
    si: "ලොගින් / ලියාපදිංචි",
  },
  card1: {
    en: "No of total children registered",
    ta: "பதிவு செய்யப்பட்ட மொத்த குழந்தைகளின் எண்ணிக்கை",
    si: "ලියාපදිංචි කර ඇති මුළු ළමුන් ගණන",
  },
  card2: {
    en: "No of children who reached 90,000 LKR goal",
    ta: "90,000 LKR ஆயிரம் இலக்கை எட்டிய குழந்தைகளின் எண்ணிக்கை",
    si: "රු. 90,000 ඉලක්කයට ළඟා වූ ළමුන් සංඛ්‍යාව",
  },
  card3: {
    en: "No of active children in the system",
    ta: "கணினியில் செயலில் உள்ள குழந்தைகளின் எண்ணிக்கை",
    si: "පද්ධතියේ ක්‍රියාකාරී ළමුන් සංඛ්‍යාව",
  },
  card4: {
    en: "Total donations received",
    ta: "பெறப்பட்ட மொத்த நன்கொடைகள்",
    si: "ලැබුණු මුළු පරිත්‍යාග",
  },
  welcome_label: {
    en: "Welcome to SNEHA Foster Care Scheme Website",
    ta: "எங்களது சிநேகா (SNEHA) இணையத்தளத்திற்கு வரவேற்கின்றோம்‌.",
    si: "‘ස්නෙහ’ වෙබ් අඩවියට අපි ඔබව සාදරයෙන් පිළිගනිමු",
  },
  welcome_desc1: {
    en: "We invite you to be a part of a transformative journey. Your contribution, no matter how big or small, can help secure the future of children, who need access to a nutrition rich diet to thrive, learn, and grow.",
    ta: "மாற்றும் பயணத்தின் ஒரு பகுதியாக இருக்க உங்களை அழைக்கிறோம். உங்கள் பங்களிப்பு, எவ்வளவு பெரியதாக இருந்தாலும் சரி, சிறியதாக இருந்தாலும் சரி, குழந்தைகளின் எதிர்காலத்தைப் பாதுகாக்க உதவும், அவர்கள் செழிக்க, கற்க மற்றும் வளர ஊட்டச்சத்து நிறைந்த உணவை அணுக வேண்டும்.",
    si: "අපගේ දරුවන්ගේ ජීවිතවල අරුත්බර වෙනසක් ඇති කිරීමට අප හා එක්වන ලෙස අපි ඔබට ආරාධනා කරමු.",
  },
  welcome_desc2: {
    en: "The importance of early childhood nutrition to improve development outcomes is well recognized. Nutrition failure, especially during the first five years of life, leads to poor physical health, poor cognitive development, and low educational attainment. Child malnutrition has become a grave public health concern in Sri Lanka.",
    ta: "வளர்ச்சி விளைவுகளை மேம்படுத்த குழந்தை பருவ ஊட்டச்சத்தின் முக்கியத்துவம் நன்கு அங்கீகரிக்கப்பட்டுள்ளது. ஊட்டச்சத்து குறைபாடு, குறிப்பாக வாழ்க்கையின் முதல் ஐந்து ஆண்டுகளில், மோசமான உடல் ஆரோக்கியம், மோசமான அறிவாற்றல் வளர்ச்சி மற்றும் குறைந்த கல்வித் தகுதிக்கு வழிவகுக்கிறது. இலங்கையில் குழந்தைகளின் போசாக்கின்மை ஒரு பாரதூரமான பொது சுகாதார கவலையாக மாறியுள்ளது.",
    si: "ඔබගේ පරිත්‍යාගය ලොකු හෝ කුඩා වුවත් එය අපගේ දරුවන්ගේ අනාගතය සුරක්ෂිත කිරීමට මහඟු උපකාරයකි. ඔබගේ දායකත්වය ඔවුන්ට ප්‍රශස්ත කායික සහ මානසික සංවර්ධනය සඳහා ප්‍රවේශයක් වනු නොඅනුමානය.",
  },
  welcome_desc3: {
    en: "Today we identify many children affected with Severe Acute Malnutrition. Most of them do need extra support for their nutrition. SNEHA is about a Foster Care Scheme which targets under-five children with Severe Acute Malnutrition in economically vulnerable families. The Ministry of Health has taken the initiative to launch the 'SNEHA’ Foster Care Scheme with domestic and international donors and institutions to help improve their nutritional condition whereby the families of affected children can purchase nourishing food.",
    ta: "இன்று நாம் கடுமையான ஊட்டச்சத்து குறைபாட்டால் பாதிக்கப்பட்ட பல குழந்தைகளை அடையாளம் காண்கிறோம். அவர்களில் பெரும்பாலோர் தங்கள் ஊட்டச்சத்துக்கு கூடுதல் ஆதரவு தேவை. SNEHA என்பது பொருளாதார ரீதியாக பாதிக்கப்படக்கூடிய குடும்பங்களில் கடுமையான ஊட்டச்சத்து குறைபாடு உள்ள ஐந்து வயதுக்குட்பட்ட குழந்தைகளை இலக்காகக் கொண்ட ஒரு வளர்ப்பு பராமரிப்பு திட்டம் ஆகும். பாதிக்கப்பட்ட குழந்தைகளின் குடும்பங்கள் ஊட்டமளிக்கும் உணவை வாங்குவதற்கு அவர்களின் ஊட்டச்சத்து நிலையை மேம்படுத்த உதவுவதற்காக உள்நாட்டு மற்றும் சர்வதேச நன்கொடையாளர்கள் மற்றும் நிறுவனங்களுடன் இணைந்து 'SNEHA' வளர்ப்பு பராமரிப்பு திட்டத்தை தொடங்குவதற்கு சுகாதார அமைச்சகம் முன்முயற்சி எடுத்துள்ளது.",
    si: "දරුවන්ගේ ප්‍රශස්ත කායික සහ මානසික සංවර්ධනය සඳහා මුල් ළමාවියේ  පෝෂණය ඉතා වැදගත් බව හඳුනා ගෙන ඇත. විශේෂයෙන්ම මුල් අවුරුදු පහ තුළ නිසි පෝෂණය නොලැබීමේ ප්‍රථිඵලයක් ලෙස කායික වර්ධනය අඩාල වීම, බුද්ධි වර්ධනය අඩාල වීම, ඉගෙනුම් හැකියා දුර්වල වීම සිදු විය හැක. ළමා මන්දපෝෂණය වර්තමනයේ ශ්‍රී ලංකාව තුල බරපතල මහජන සෞඛ්‍ය ගැටලුවක් බවට පත්ව ඇත.",
  },
  welcome_desc4: {
    en: "By supporting the 'SNEHA’ Foster Care Scheme, you become a lifeline for these vulnerable children. Your generosity can help rewrite their stories, turning despair into dreams and hunger into hope.",
    ta: "சிநேகா (SNEHA) ஃபாஸ்டர் கேர் திட்டத்தை ஆதரிப்பதன் மூலம், இந்த பாதிக்கப்படக்கூடிய குழந்தைகளுக்கு நீங்கள் ஒரு உயிர்நாடியாக மாறுகிறீர்கள். உங்கள் தாராள மனப்பான்மை அவர்களின் கதைகளை மீண்டும் எழுத உதவும், விரக்தியை கனவுகளாகவும், பசியை நம்பிக்கையாகவும் மாற்றும்.",
    si: "දරුවන්ට ප්‍රශස්ත පෝෂණයක් ලබා දීම සඳහා, දරිද්‍රතාවයෙන් සහ ආහාර අනාරක්ෂිතභාවයෙන් පෙළෙන පවුල්වල වයස අවුරුදු පහට අඩු උග්‍ර තීව්‍ර මන්දපෝෂණයෙන් පෙළෙන දරුවන් ඉලක්ක කර ගනිමින් “කැපකරු සත්කාර යෝජනා”  ක්‍රමයක් ලෙස “ස්නේහ” සෞඛ්‍ය අමත්‍යංශය මඟින් හඳුන්වා දී ඇත.  මෙමගින් දේශීය හා විදේශීය ධන පරිත්‍යාගශීලීන් සහ පරිත්‍යාගශීලී ආයතනවල අනුග්‍රහය යටතේ පෝෂ්‍යදායි ආහාර මිලදී ගැනීමට ඉඩ කඩ සලසයි. ",
  },
  welcome_desc5: {
    en: "Thank you for considering our cause. Your donation is not just an act of kindness; it's an investment into the future of our nation. We look forward to your valuable support as we embark on this journey to transform lives together.",
    ta: "எங்கள் காரணத்தை கருத்தில் கொண்டதற்கு நன்றி. உங்கள் நன்கொடை வெறும் கருணைச் செயல் அல்ல; இது நமது நாட்டின் எதிர்காலத்திற்கான முதலீடு. ஒன்றாக வாழ்வை மாற்றும் இந்த பயணத்தை தொடங்கும் போது உங்களின் மதிப்புமிக்க ஆதரவை எதிர்பார்க்கிறோம்.",
    si: "‘ස්නේහ’ කැපකරු දායක ක්‍රමයට සහාය දීමෙන් ඔබ මෙම අවදානමට ලක්වී ඇති දරුවන්ගේ ජීවිත වලට නව බලපොරොත්තුවක් උදා කරයි. ඔබගේ ත්‍යගශීලීබව ශ්‍රී ලංකාවේ අනාගතය වෙනුවෙන් සිදු කරනු ලබන මහඟු අයෝජනයකි.ස්නේහ’ කැපකරු දායක ක්‍රමයට අනුග්‍රහය දැක්වූ ඔබට ස්තූතියි. ",
  },
  contact_btn: {
    en: "Contact Us",
    ta: "எங்களை தொடர்பு கொள்ள",
    si: "අපව අමතන්න",
  },
  logo_heading: {
    en: "In Partnership With",
    ta: "கூட்டாண்மைகள்",
    si: "හවුල්කාරිත්වයන්",
  },
  logo_subheading: {
    en: "the SNEHA is a project launched in partnership with,",
    ta: "கூட்டாண்மைகள் SNEHA திட்டம் தொடங்கப்பட்டது,",
    si: "ස්නේහ ව්‍යාපෘතිය දියත් කරන ලද හවුල්කාරිත්වයන්,",
  },
  logo1: {
    en: "Ministry of Health",
    ta: "சுகாதார அமைச்சகம்",
    si: "සෞඛ්‍ය අමාත්‍යංශය",
  },
  logo2: {
    en: "Presidential Secretariat",
    ta: "ஜனாதிபதி செயலகம்",
    si: "ජනාධිපති ලේකම් කාර්යාලය",
  },
  logo3: {
    en: "Nutrition Division - MOH",
    ta: "ஊட்டச்சத்து பிரிவு - MOH",
    si: "පෝෂණ අංශය - MOH",
  },
  logo4: {
    en: "Family Health Bureau",
    ta: "குடும்ப சுகாதார பணியகம்",
    si: "පවුල් සෞඛ්‍ය කාර්යාංශය",
  },
  logo5: {
    en: "Health Promotion Bureau",
    ta: "சுகாதார மேம்பாட்டு பணியகம்",
    si: "සෞඛ්‍ය ප්‍රවර්ධන කාර්යාංශය",
  },
  footer_header1: {
    en: "Sneha Program",
    ta: "சிநேஹா திட்டம்",
    si: "ස්නේහ වැඩසටහන",
  },
  email: {
    en: "Email:",
    ta: "தொலைபேசி:",
    si: "විද්යුත් තැපෑල:",
  },
  phone: {
    en: "Phone:",
    ta: "மின்னஞ்சல்:",
    si: "දුරකථන:",
  },
  address: {
    en: "Address:",
    ta: "முகவரி:",
    si: "ලිපිනය:",
  },
  address1: {
    en: "Nutrition Division, 7th floor,",
    ta: "ஊட்டச்சத்து பிரிவு, 7 வது மாடி,",
    si: "පෝෂණ අංශය, 7 වන මහල, ",
  },
  address2: {
    en: "  Public Health Building, Colombo 05",
    ta: "பொது சுகாதார கட்டிடம், கொழும்பு 05",
    si: " මහජන සෞඛ්‍ය ගොඩනැගිල්ල කොළඹ 05",
  },
  external_links: {
    en: "External Links",
    ta: "வெளி இணைப்புகள்",
    si: "බාහිර සබැඳි",
  },
  link1: {
    en: "Ministry of Health",
    ta: "சுகாதார அமைச்சகம்",
    si: "සෞඛ්‍ය අමාත්‍යංශය",
  },
  link2: {
    en: "Presidential Secretariat",
    ta: "ஜனாதிபதி செயலகம்",
    si: "ජනාධිපති ලේකම් කාර්යාලය",
  },
  link3: {
    en: "Nutrition Division - MOH",
    ta: "ஊட்டச்சத்து பிரிவு - MOH",
    si: "පෝෂණ අංශය - MOH",
  },
  link4: {
    en: "Family Health Bureau",
    ta: "குடும்ப சுகாதார பணியகம்",
    si: "පවුල් සෞඛ්‍ය කාර්යාංශය",
  },
  link5: {
    en: "Health Promotion Bureau",
    ta: "சுகாதார மேம்பாட்டு பணியகம்",
    si: "සෞඛ්‍ය ප්‍රවර්ධන කාර්යාංශය",
  },
  who: {
    en: "WHO",
    ta: "வேர்ல்ட் ஹெல்த் ஆர்கனைசேஷன்",
    si: "ලෝක සෞඛ්‍ය සංවිධානය",
  },
  socialmedia: {
    en: "Our Social Media",
    ta: "எங்கள் சமூக ஊடகங்கள்",
    si: "අපේ සමාජ මාධ්‍ය",
  },
};
