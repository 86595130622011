import React, { useContext, useMemo, useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown, Space, Card, Input } from "antd";
import DistrictFilter from "../modules/DistrictFilter";
import getPageLabels from "../../Utils/Label.service";
import { MONTHLYTARGETFORCATEGORY_PAGE_LABELS } from "../../Utils/Labels/MonthlyTargetForCategory.page.labels";
import { i18n } from "../../App";
import {
  getCategoryGoalMonth,
  getCategoryGoals,
  getCategoryGoalsAll,
  updateCategoryGoalMonth,
} from "../../Api";
import { Toast } from "../modules/Alert";

const items = [
  {
    label: <div>Year</div>,
    key: "0",
  },
  {
    label: <div>Month</div>,
    key: "1",
  },

  {
    label: <div>Weak</div>,
    key: "3",
  },
];

function MonthlyTargetForCategory() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const handleActionClick = (row) => {
    setSelectedRow(row);
  };

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };

  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(MONTHLYTARGETFORCATEGORY_PAGE_LABELS, language.language),
    [language]
  );

  const fetchAllGoals = () => {
    getCategoryGoalsAll().then((out) => {
      const goals = out.data.data;
      setData(
        goals.map((goal) => {
          let status = 0;
          if (goal.year < new Date().getFullYear()) {
            status = 0;
          } else if (goal.year == new Date().getFullYear()) {
            status = 1;
          } else {
            status = 2;
          }
          return { ...goal, status };
        })
      );
    });
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values, selectedRow);
    updateCategoryGoalMonth({
      goal_id: selectedRow.id,
      months: Object.keys(values).map((key, index) => {
        return { month: key, goal: values[key] };
      }),
    }).then((out) => {
      console.log(out);
      setSelectedMonth(null);
      setSelectedRow(null);
      setData([]);
      fetchAllGoals();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const MONTHS = [
    lang.january,
    lang.february,
    lang.march,
    lang.april,
    lang.may,
    lang.june,
    lang.july,
    lang.august,
    lang.september,
    lang.october,
    lang.november,
    lang.december,
  ];

  const CATEGORIES = ["SAM 8"];

  const data1 = MONTHS.map((m, index) => {
    return {
      month: m,
      goal: (
        <Form.Item style={{ margin: 0 }} name={`${index}`}>
          <Input
            disabled={selectedRow?.status < 2}
            placeholder={m}
            bordered={false}
          />
        </Form.Item>
      ),
    };
  });

  const columns = [
    {
      title: lang.category_goal_id,
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: lang.year,
      dataIndex: "year",
      key: "year",
      fixed: "left",
    },
    {
      title: lang.category,
      dataIndex: "category",
      key: "category",
      render: (_, record) => CATEGORIES[record.category],
    },
    {
      title: lang.amount,
      dataIndex: "goal",
      key: "goal",
    },
    {
      title: lang.status,
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status == 0) {
          return <div className="fos-lable-red ">{lang.locked}</div>;
        } else if (record.status == 1) {
          return <div className="fos-lable-green ">{lang.active}</div>;
        } else {
          return <div className="fos-lable-blue ">{lang.upcoming}</div>;
        }
      },
    },
    {
      title: lang.action,
      key: "operation",
      fixed: "right",
      render: (_, record) => (
        <div className="fos-view-btn" onClick={() => handleActionClick(record)}>
          <EyeOutlined />
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      title: lang.month,
      dataIndex: "month",
      key: "month",
      fixed: "left",
    },
    {
      title: lang.goal,

      dataIndex: "goal",
      key: "goal",
      fixed: "left",
    },
  ];

  const sumOfChildren = data1.reduce((acc, current) => {
    const goalValue = parseInt(current.goal.props.children.props.value);
    return isNaN(goalValue) ? acc : acc + goalValue;
  }, 0);

  const totalRow = {
    month: <b>{lang.total}</b>,
    goal: <b style={{ margin: 10 }}>{sumOfChildren}</b>,
  };

  const modifiedData1 = [...data1, totalRow];

  useEffect(() => {
    getCategoryGoals({ year: new Date().getFullYear() + 1 }).then((out) =>
      fetchAllGoals()
    );
  }, []);

  useEffect(() => {
    if (selectedRow) {
      getCategoryGoalMonth(selectedRow).then((out) => {
        setSelectedMonth(out.data.data);
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedMonth) {
      Object.keys(selectedMonth).forEach((key, index) => {
        form.setFieldValue(key, selectedMonth[key]?.goal);
      });
    } else {
      form.resetFields();
    }
  }, [selectedMonth]);

  return (
    <div>
      {/* <div className="">
        <DistrictFilter></DistrictFilter>
      </div> */}

      <div className="row mt-4 ">
        <div className="col-md-8">
          <Card>
            <div class="fos-card-heading font-bold">{lang.heading}</div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
            {selectedRow && (
              <Modal
                title={
                  <div className="fos-heading text-center">{lang.action}</div>
                }
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={
                  [
                    // <Button key="close" onClick={handleModalClose}>
                    //   Close
                    // </Button>,
                  ]
                }
              >
                <div className="d-flex justify-content-between mt-4">
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.push_to_funding_list}{" "}
                  </Button>
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.deactivate}{" "}
                  </Button>
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.re_enroll}{" "}
                  </Button>
                </div>
              </Modal>
            )}
          </Card>
        </div>
        {selectedRow ? (
          <div className="col-md-4">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Card>
                <div class="fos-card-heading font-bold">
                  {selectedRow?.year} {CATEGORIES[selectedRow?.category]}
                </div>
                <div class="fos-label mt-2">{lang.info_label}</div>
                <Table
                  columns={columns1}
                  dataSource={modifiedData1}
                  scroll={{ x: 150 }}
                  sticky
                  className="mt-3"
                  rowClassName="shrink-cell"
                  pagination={false}
                />
                {selectedRow?.status > 1 ? (
                  <div className="d-flex mt-3">
                    {/* <Button className='fos-btn-border'>{lang.edit}</Button> */}
                    <Button className="fos-btn-primary ml-4" htmlType="submit">
                      {lang.save}
                    </Button>
                  </div>
                ) : null}
              </Card>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MonthlyTargetForCategory;
