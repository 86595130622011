import { useParams } from "react-router-dom";

const Pay = () => {
  let { session } = useParams();

  const url = `https://test-bankofceylon.mtf.gateway.mastercard.com/checkout/api/pageState/${session}?checkoutVersion=1.0.0`;

  return (<form id="myForm" action={url} method="POST">
    <button type="submit">Submit</button>
  </form>);
}

export default Pay;

