import {IMG} from "./Constants";

const getPageLabels = (labels, lang) => {
    let obj = {};
    for (const key in labels) {
        obj[key] = labels[key][lang];
    }
    return obj;
};

const getErrorMsgLabels = (errorMsg, lang) => {
    let obj = {}
    for (const item in errorMsg) {
        let o = {}
        for (const i in errorMsg[item]) {
            o[i] = errorMsg[item][i][lang]
        }
        obj[item] = o
    }

    return obj
}

const getImgs = (lang) => {
    const imgs = IMG;
    let obj = {};
    for (const img in imgs) {
        obj[img] = imgs[img][lang];
    }
    return obj
};

export { getPageLabels, getErrorMsgLabels,getImgs }

export default getPageLabels;

