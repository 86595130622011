export const MONTHLYTARGETFORCATEGORY_PAGE_LABELS = {
  heading: {
    en: "Monthly Goal for Category",
    ta: "மாவட்டத்திற்கான மாதாந்திர இலக்கு",
    si: "කාණ්ඩය සඳහා මාසික ඉලක්කය",
  },
  category_goal_id: {
    en: "Cat. Goal ID",
    ta: "வகை இலக்கு ஐடி",
    si: "වර්ගය ඉලක්ක හඳුනාගැනීමේ අංකය",
  },
  category: {
    en: "Category",
    ta: "வகை",
    si: "වර්ගය",
  },
  year: {
    en: "Year",
    ta: "ஆண்டு",
    si: "වසර",
  },
  month: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },
  amount: {
    en: "No of Children",
    ta: "குழந்தைகளின் எண்ணிக்கை",
    si: "ළමුන් සංඛ්යාව",
  },
  status: {
    en: "Status",
    ta: "நிலை",
    si: "තත්වය",
  },
  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  info_label: {
    en: "Click and update the goal",
    ta: "கிளிக் செய்து இலக்கத்தை மேம்படுத்தவும்",
    si: "ක්ලික් කර ඉලක්කය යාවත්කාලීන කරන්න",
  },
  district: {
    en: "RDHS",
    ta: "RDHS",
    si: "RDHS",
  },
  goal: {
    en: "No of Children",
    ta: "குழந்தைகளின் எண்ணிக்கை",
    si: "ළමුන් සංඛ්යාව",
  },
  edit: {
    en: "Edit",
    ta: "தொகு",
    si: "සංස්කරණය කරන්න",
  },
  save: {
    en: "Save",
    ta: "சேமிக்கவும்",
    si: "ගබඩා කරන්න",
  },
  push_to_funding_list: {
    en: "Push to Funding List",
    ta: "நிதியளிப்பு பட்டியலுக்கு அழுத்தவும்",
    si: "අරමුදල් ලැයිස්තුවට තල්ලු කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "නිෂ්ක්‍රීය කරන්න",
  },
  re_enroll: {
    en: "Re Enroll",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  january: {
    en: "January",
    ta: "ஜனவரி",
    si: "ජනවාරි",
  },
  february: {
    en: "February",
    ta: "பிப்ரவரி",
    si: "පෙබරවාරි",
  },
  march: {
    en: "March",
    ta: "மார்ச்",
    si: "මාර්තු",
  },
  april: {
    en: "April",
    ta: "ஏப்ரல்",
    si: "අප්රේල්",
  },
  may: {
    en: "May",
    ta: "மே",
    si: "මැයි",
  },
  june: {
    en: "June",
    ta: "ஜூன்",
    si: "ජූනි",
  },
  july: {
    en: "July",
    ta: "ஜூலை",
    si: "ජුලි",
  },
  august: {
    en: "August",
    ta: "ஆகஸ்ட்",
    si: "අගෝස්තු",
  },
  september: {
    en: "September",
    ta: "செப்டம்பர்",
    si: "සැප්තැම්බර්",
  },
  october: {
    en: "October",
    ta: "அக்டோபர்",
    si: "ඔක්තෝම්බර්",
  },
  november: {
    en: "November",
    ta: "நவம்பர்",
    si: "නොවැම්බර්",
  },
  december: {
    en: "December",
    ta: "டிசம்பர்",
    si: "දෙසැම්බර්",
  },
  active: {
    en: "Active",
    ta: "செயலில்",
    si: "ක්‍රියාකාරී",
  },
  locked: {
    en: "Locked",
    ta: "பூட்டப்பட்டது",
    si: "අගුලු දමා ඇත",
  },
  upcoming: {
    en: "Upcoming",
    ta: "வரவிருக்கிறது",
    si: "ඉදිරියට",
  },
  alert_save_success: {
    en: "Goal Saved Successfully",
    ta: "இலக்கு வெற்றிகரமாக சேமிக்கப்பட்டது",
    si: "ඉලක්කය සාර්ථකව සුරැකිණි",
  },

  alert_update_success: {
    en: "Goal Updated Successfully",
    ta: "இலக்கு வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    si: "ඉලක්කය සාර්ථකව යාවත්කාලීන කරන ලදී",
  },

  total: {
    en: "Total No of Children:",
    ta: "மொத்த குழந்தைகளின் எண்ணிக்கை:",
    si: "මුළු දරුවන් සංඛ්යාව:",
  },
};
