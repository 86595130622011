import React, { useContext, useMemo, useState } from "react";
import { Table } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Card } from "antd";
import CommonFilter from "../modules/CommonFilter";
import getPageLabels from "../../Utils/Label.service";
import { FUNDSRELEASEBYBENEFICIARY_PAGE_LABELS } from "../../Utils/Labels/FundsReleaseByBenificiary.page.labels";
import { i18n } from "../../App";
import PayoutsByBenificiaryFilter from "../../Filters/PayoutsByBenificiaryFilter";
import { CSVLink } from "react-csv";

function FundsReleaseByBenificiary() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(FUNDSRELEASEBYBENEFICIARY_PAGE_LABELS, language.language),
    [language]
  );

  const columns = [
    {
      title: lang.child_id,
      dataIndex: "uid",
    },
    {
      title: lang.public_id,
      dataIndex: "public_id",
    },
    {
      title: lang.name,
      dataIndex: "name",
    },
    {
      title: lang.category,
      dataIndex: "category",
    },
    {
      title: lang.account_number,
      dataIndex: "bank_account",
    },
    {
      title: lang.telephone_number,
      dataIndex: "phone_primary",
    },
    {
      title: lang.semester_month,
      dataIndex: "semester",
    },
    {
      title: lang.remaining_funds,
      dataIndex: "remaining",
    },
    {
      title: lang.funds_released,
      dataIndex: "released",
    },
    {
      title: lang.to_rise,
      dataIndex: "amount",
    },
  ];
  const [data, setData] = useState([]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <PayoutsByBenificiaryFilter set={setData} />
        </div>
      </div>

      <div className="mt-4">
        <Card>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fos-heading ml-2"> {lang.heading}</div>
            <CSVLink
              data={data}
              headers={columns.map((col) => ({
                label: col.title,
                key: col.dataIndex,
              }))}
              filename={"funds_released_per_beneficiary.csv"}
              className="download-button"
            >
              {lang.download_report} <VerticalAlignBottomOutlined />
            </CSVLink>
          </div>
          <Table className="mt-3" columns={columns} dataSource={data} />
        </Card>
      </div>
    </div>
  );
}

export default FundsReleaseByBenificiary;
