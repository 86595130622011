import React, { useContext, useEffect, useMemo } from "react";
import { Space, Table, Tag } from "antd";
import { Card } from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import "../../Styles/main.scss";
import getPageLabels from "../../Utils/Label.service";
import { TOTALDONATIONSTHISMONTH_LABELS } from "../../Utils/Labels/CounterLabels/TotalDonationThisMonth.labels";
import { i18n } from "../../App";

function TotalDonationThisMonth({ value = 0 }) {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(TOTALDONATIONSTHISMONTH_LABELS, language.language),
    [language]
  );

  return (
    <>
      <Card className="fos-card">
        <div className="d-flex justify-content-between">
          <div className="fos-card-heading font-bold">{value}</div>
          <div className="fos-card-icon d-flex fos-pink">
            <CarryOutOutlined />
          </div>
        </div>
        <p className="fos-card-description mt-3">{lang.counter}</p>
      </Card>
    </>
  );
}
export default TotalDonationThisMonth;
