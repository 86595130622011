export const BENIFICISRIES_PAGE_LABELS = {
  heading: {
    en: "Beneficiary",
    ta: "பயனாளி",
    si: "ප්‍රතිලාභියා",
  },
  heading_small: {
    en: "Children Details",
    ta: "குழந்தைகளின் விவரங்கள்",
    si: "දරුවන්ගේ විස්තර",
  },
  filter: {
    en: "Filter",
    ta: "வடிகட்டி",
    si: "පෙරහන",
  },
  year_filter: {
    en: "Year",
    ta: "ஆண்டு",
    si: "වසර",
  },
  month_filter: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },
  week_filter: {
    en: "Week",
    ta: "வாரம்",
    si: "සතිය",
  },
  child_id: {
    en: "Child ID",
    ta: "குழந்தை ஐடி",
    si: "ළමා හඳුනාගැනීමේ අංකය",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  name: {
    en: " Child Name",
    ta: "குழந்தையின் பெயர்",
    si: "දරුවාගේ නම",
  },
  status: {
    en: "Verification Status",
    ta: "சரிபார்ப்பு நிலை",
    si: "සත්‍යාපන තත්වය",
  },
  funding: {
    en: "Enrollment Status",
    ta: "பதிவு நிலை",
    si: "ඇතුළත් වීමේ තත්ත්වය",
  },
  age: {
    en: "Age",
    ta: "வயது",
    si: "වයස",
  },
  bank_account: {
    en: "Bank Account",
    ta: "வங்கி கணக்கு",
    si: "බැංකු ගිණුම",
  },
  phone_primary: {
    en: "Phone Number 1",
    ta: "தொலைபேசி எண் 1",
    si: "දුරකථන අංකය 1",
  },
  phone_secondary: {
    en: "Phone Number 2",
    ta: "தொலைபேசி எண் 2 ",
    si: "දුරකථන අංකය 2",
  },
  district: {
    en: "RDHS Area",
    ta: "RDHS பகுதி",
    si: "RDHS ප්‍රදේශය",
  },
  moh_area: {
    en: "MOH Area",
    ta: "MOH பகுதி",
    si: "MOH ප්‍රදේශය",
  },
  no_of_enrolment: {
    en: "No of Enrolment",
    ta: "பதிவு எண்",
    si: "ඇතුළත් වීමේ අංකය",
  },
  exclusion_reason: {
    en: "Exclusion Reason",
    ta: "விலக்கு காரணம்",
    si: "බැහැර කිරීමේ හේතුව",
  },
  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  push_to_funding_list: {
    en: "Push to Funding List",
    ta: "நிதியளிப்பு பட்டியலுக்கு அழுத்தவும்",
    si: "අරමුදල් ලැයිස්තුවට තල්ලු කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "අක්‍රිය කරන්න",
  },
  re_enroll: {
    en: "Re Enroll",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
};
