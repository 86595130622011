import React, { useContext, useMemo, useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import getPageLabels from "../../Utils/Label.service";
import { ALLDONATIONS_PAGE_LABELS } from "../../Utils/Labels/AllDonations.page.labels";
import { i18n } from "../../App";
import { getDonations } from "../../Api";
import TableFilter from "../form/TableFilter";

function AllDonations() {
  const { language } = useContext(i18n);
  const lang = useMemo(
    () => getPageLabels(ALLDONATIONS_PAGE_LABELS, language.language),
    [language]
  );
  const navigate = useNavigate();

  const columns = [
    {
      title: lang.child_id,
      dataIndex: "reference_no",
      search: true,
      render: (_, record) => (
        <a onClick={() => navigate(`/donationDetails/${record.reference_no}`)}>
          {record.reference_no}
        </a>
      ),
    },
    {
      title: lang.transaction_id,
      dataIndex: "transaction_id",
      search: true,
    },
    {
      title: lang.donor_id,
      dataIndex: "donor_id",
      search: true,
    },
    {
      title: lang.details,
      dataIndex: "details",
      key: "details",
      search: true,
      render: (_, record) => {
        return <Button className='fos-btn-primary ml-4' onClick={() => navigate(`/donationDetails/${record.reference_no}`)}>{lang.view_details}</Button>
      }
    },
    {
      title: lang.donor_name,
      dataIndex: "first_name",
      search: true,
    },
    {
      title: lang.total_amount_donated,
      dataIndex: "amount",
      search: true,
    },
    {
      title: lang.donation_date,
      dataIndex: "updated",
      render: (date) => {
        if (date) {
          const formattedDate = new Date(date).toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          return formattedDate;
        }
        return "";
      },
      search: true,
    },
  ];

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getDonations()
      .then((out) => {
        const mapped = out.data.data.map((itm, index) => {
          return { ...itm, key: index };
        });

        setData(mapped);
        setFilteredData(mapped); // Initialize filteredData with all data
      })
      .catch((e) => {
        console.log("CATCH", e);
      });
  }, []);

  // Function to handle filtering based on user input
  const handleFilter = ({ column, searchText }) => {
    if (!column || !searchText) {
      // If either column or searchText is not provided, reset the filteredData to all data
      setFilteredData(data);
      return;
    }

    // Implement your filtering logic here
    const filtered = data.filter((item) => {
      const cellValue = item[column] || ""; // Get the cell value for the selected column
      return cellValue
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    setFilteredData(filtered);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fos-heading">{lang.heading}</div>
        </div>
        <div>
          <div className="mt-4"></div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <div className="mt-1">
            {/* Render the TableFilter component */}
            <TableFilter columns={columns} onFilter={handleFilter} />
          </div>
        </div>
      </div>

      {/* Render the table with filtered data */}
      <div className="mt-1">
        <Table columns={columns} dataSource={filteredData} />
      </div>
    </div>
  );
}

export default AllDonations;
