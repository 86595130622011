export const ALLDONORS_PAGE_LABELS = {
  heading: {
    en: "All Donors",
    ta: "அனைத்து நன்கொடையாளர்கள்",
    si: "සියලුම පරිත්‍යාගශීලීන්",
  },
  donor_id: {
    en: "Donor ID",
    ta: "நன்கொடையாளர் ஐடி",
    si: "දායක හඳුනාගැනීමේ අංකය",
  },
  firstname: {
    en: "Firstname",
    ta: "முதற்பெயர்",
    si: "මුල් නම",
  },
  lastname: {
    en: "Lastname",
    ta: "குடும்பப்பெயர்",
    si: "අවසන් නම",
  },
  status: {
    en: "Status",
    ta: "நிலை",
    si: "තත්වය",
  },
  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  activate: {
    en: "Activate",
    ta: "செயல்படுத்த",
    si: "සක්‍රිය කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "නිෂ්ක්‍රීය කරන්න",
  },
  alert_deactivate_success: {
    en: "Deactivate successfully.",
    ta: "வெற்றிகரமாக செயலிழக்கப்பட்டது.",
    si: "සාර්ථකව නිෂ්ක්‍රීය කරන ලදී.",
  },
  alert_activate_success: {
    en: "Activated successfully.",
    ta: "வெற்றிகரமாக செயல்படுத்தப்பட்டது.",
    si: "සාර්ථකව සක්‍රිය කරන ලදී.",
  },
  email: {
    en: "Email",
    ta: "மின்னஞ்சல்",
    si: "විද්යුත් තැපෑල",
  },
};
