import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { Card, Avatar, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { i18n } from "../App";
import { MAKEDONATIONS_PAGE_LABELS } from "../Utils/Labels/MakeDonations.page.labels";
import getPageLabels from "../Utils/Label.service";
import snehaImage from "../Assests/logo.webp";

import { useReactToPrint } from "react-to-print";

import FHB from "../Assests/FHB.png";
import HPB from "../Assests/HPB.png";
import NM from "../Assests/NM.png";
// import who from "../Assests/who.png";
import PS from "../Assests/PS.png";
import GOV from "../Assests/GOV.png";
import { ASSET_URL, getCertificate } from "../Api";

const Certificate = () => {
  let { id } = useParams();
  const [certificate, setCertificate] = useState(null);
  const { language } = useContext(i18n);
  const lang = useMemo(
    () => getPageLabels(MAKEDONATIONS_PAGE_LABELS, language.language),
    [language]
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(()=>{
    getCertificate({ id: id }).then((out)=>{
      setCertificate(out?.data?.data);
    })
  },[]);

  return (certificate ? <div className="certificate-popup">
    <div className="certificate-content">
      <div className="d-flex justify-content-end align-items-center">
        <div className="mt-4">
          <Button
            style={{ width: "max-content", marginBottom: "20px" }}
            className="fos-btn-primary"
            type="success"
            // color="primary"
            onClick={handlePrint}
            title="Print certificate"
          >
            Print certificate
          </Button>
        </div>
      </div>
      <div
        className="d-flex justify-content-center "
        ref={componentRef}
        style={{ padding: "0", margin: "0" }}
      >
        {" "}
        <div className="w-60 p-5 fos-certificate">
          <img src={snehaImage} alt="Sneha Image" className="sneha-image" />
          <Card className="p-2">
            <div className="d-flex justify-content-center">
              <div>
                <CheckCircleTwoTone className="certificate-check" />
                <div className="fos-card-heading font-bold text-center">
                  {" "}
                  {lang.donation_successful}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div>
                <div className="text-center">
                  <Avatar
                    src={
                      <img
                        className="fos-user-img"
                        src={`${ASSET_URL}/avatar/${certificate?.img}`}
                        alt="avatar"
                      />
                    }
                    size={"large"}
                  />

                  <div className="fos-card-heading font-bold">
                    {certificate?.first_name}&nbsp;
                    {certificate?.last_name}
                  </div>
                </div>
                <div className="fos-label text-center">
                  {" "}
                  {/* Kusal Perera{" "} */}
                </div>
                <div className="fos-label text-center">
                  {" "}
                  {lang.thankyou_msg}
                </div>
                <div className="fos-label text-center">
                  {" "}
                  {lang.thankyou_msg2}
                </div>
              </div>
            </div>

            <div className="row mt-3 justify-content-center">
              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.donor_id}</div>
                <div className="fos-label-detail">
                  {certificate?.donor_id.toString().padStart(5, "0")}
                </div>
              </div>

              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.date}</div>
                <div className="fos-label-detail">
                  {certificate?.updated
                    ? new Intl.DateTimeFormat().format(
                      new Date(certificate?.updated)
                    )
                    : ""}
                </div>
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.public_id}</div>
                {certificate?.beneficiaries?.map((ben, index) => (
                  <div className="fos-label-detail" key={index}>
                    {ben?.public_id}
                  </div>
                ))}
              </div>

              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.time}</div>
                <div className="fos-label-detail">
                  {certificate?.updated
                    ? new Intl.DateTimeFormat("en-AU", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    }).format(new Date(certificate?.updated))
                    : ""}
                </div>
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.transaction_id}</div>
                <div className="fos-label-detail">
                  {certificate?.transaction_id}
                </div>
              </div>

              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.amount}</div>
                <div className="fos-label-detail">
                  {new Intl.NumberFormat("lk-LK", {
                    style: "currency",
                    currency: "LKR",
                  }).format(certificate?.amount)}
                </div>
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-md-6 text-center">
                <div className="fos-label">{lang.reference_no}</div>
                <div className="fos-label-detail">
                  {certificate?.reference_no}
                </div>
              </div>

              <div className="col-md-6 text-center">
                <div className="fos-label"></div>
                <div className="fos-label-detail">
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="stakeholder-logos">
                <img src={FHB} alt="FHB" />
                <img src={HPB} alt="HPB" />
                <img src={NM} alt="NM" />
                <img src={PS} alt="PS" />
                <img src={GOV} alt="GOV" />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div> : <div>Invalid Certificate</div>);
}

export default Certificate;

