import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown, Space, Card, Input } from "antd";
import DistrictFilter from "../modules/DistrictFilter";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router-dom";
import getPageLabels from "../../Utils/Label.service";
import { AREA_PAGE_LABELS } from "../../Utils/Labels/Area.page.labels";
import { i18n } from "../../App";
import { Toast } from "../modules/Alert";
import { getOrgUnits } from "../../Api";

function Area() {
  const { language } = useContext(i18n);
  const [data, setData] = useState([]);

  const lang = useMemo(
    () => getPageLabels(AREA_PAGE_LABELS, language.language),
    [language]
  );

  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    getOrgUnits()
      .then((out) => {
        if (out.status === 200) {
          const mapped = out.data.data.map((itm, index) => {
            return { ...itm, key: index };
          });
          setData(mapped);
        }
      })
      .catch((e) => {
        console.log("CATCH", e);
      });
  }, []);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const navigate = useNavigate();

  const handleActionClick = (rowKey) => {
    const selectedRecord = data.find((record) => record.key === rowKey);
    setSelectedRow(selectedRecord);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };
  const columns = [
    {
      title: lang.area_id,
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: 'RDHS',
      dataIndex: "rdhs",
      key: "rdhs",
      fixed: "left",
    },
    {
      title: 'MOH',
      dataIndex: "moh",
      key: "moh",
    },
    {
      title: 'PHM',
      dataIndex: "phm",
      key: "phm",
    },
    {
      title: 'PDHS',
      dataIndex: "province",
      key: "province",
    },
  ];
  const columns1 = [
    {
      title: "Area ID",

      dataIndex: "username",
      key: "name",
      fixed: "left",
    },
    {
      title: "Goal",

      dataIndex: "Goal",
      key: "age",
      fixed: "left",
    },
  ];

  const handleAddNewAreaClick = () => {
    navigate("/AddNewArea");
  };
  const onFinish1 = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_push_to_funding_list_success,
    });
  };
  const onFinish2 = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_deactivate_success,
    });
  };
  const onFinish3 = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_re_enroll_success,
    });
  };
  return (
    <div>
      <div className="row mt-4 ">
        <div className="col-md-12">
          <Card>
            <div className="d-flex align-items-center justify-content-between">
              <div className="fos-heading ml-2">{lang.heading}</div>
              {/* <Button
                className="w-auto fos-btn-primary d-flex align-items-center"
                onClick={handleAddNewAreaClick}
              >
                {lang.add_area_btn}
                <PlusOutlined />
              </Button> */}
            </div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
            {selectedRow && (
              <Modal
                title={<div className="fos-heading text-center">Action</div>}
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={
                  [
                    // <Button key="close" onClick={handleModalClose}>
                    //   Close
                    // </Button>,
                  ]
                }
              >
                <div className="d-flex justify-content-between mt-4">
                  <Button className="fos-btn-primary" onClick={onFinish1}>
                    {" "}
                    {lang.push_to_funding_list}{" "}
                  </Button>
                  <Button className="fos-btn-primary" onClick={onFinish2}>
                    {lang.deactivate}{" "}
                  </Button>
                  <Button className="fos-btn-primary" onClick={onFinish3}>
                    {" "}
                    {lang.re_enroll}{" "}
                  </Button>
                </div>
              </Modal>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Area;
