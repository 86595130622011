import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal, Form } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown, Space, Card, Input } from "antd";
import DistrictFilter from "../modules/DistrictFilter";
import getPageLabels from "../../Utils/Label.service";
import { MONTHLYTARGETFORDISTRICT_PAGE_LABELS } from "../../Utils/Labels/MonthlyTargetForDistrict.page.labels";
import { i18n } from "../../App";
import {
  getDistrictGoalMonth,
  getDistrictGoals,
  getOrgUnits,
  getRDHSAreas,
  updateDistrictGoalMonth,
} from "../../Api";

function MonthlyTargetForDestrict() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [data, setData] = useState([]);
  const [rdhsAreas, setRdhsAres] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const handleActionClick = (row) => {
    row.disabled =
      row?.year < new Date().getFullYear() ||
      (row?.year == new Date().getFullYear() &&
        row?.month <= new Date().getMonth());
    setSelectedRow(row);
  };

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };

  const { language } = useContext(i18n);

  const lang = useMemo(
    () =>
      getPageLabels(MONTHLYTARGETFORDISTRICT_PAGE_LABELS, language.language),
    [language]
  );

  const fetchAllGoals = () => {
    getDistrictGoals({ year: year }).then((out) => {
      const goals = out.data.data;
      setData(
        goals.map((goal) => {
          let status = 0;
          if (goal.year < new Date().getFullYear()) {
            status = 0;
          } else if (goal.year == new Date().getFullYear()) {
            if (goal.month == new Date().getMonth()) {
              status = 1;
            } else if (goal.month < new Date().getMonth()) {
              status = 0;
            } else {
              status = 2;
            }
          } else {
            status = 2;
          }
          return { ...goal, status };
        })
      );
    });
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values, selectedRow);
    updateDistrictGoalMonth({
      goal_id: selectedRow.id,
      districts: Object.keys(values).map((key, index) => {
        return { district: key, goal: values[key] };
      }),
    }).then((out) => {
      console.log(out);
      setSelectedMonth(null);
      setSelectedRow(null);
      setData([]);
      fetchAllGoals();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const DISTRICTS = [
    lang.ampara,
    lang.anuradhapura,
    lang.badulla,
    lang.batticaloa,
    lang.colombo,
    lang.galle,
    lang.gampaha,
    lang.hambantota,
    lang.jaffna,
    lang.kalutara,
    lang.kandy,
    lang.kegalle,
    lang.kilinochchi,
    lang.kurunegala,
    lang.mannar,
    lang.matale,
    lang.matara,
    lang.monaragala,
    lang.mullaitivu,
    lang.nuwara_eliya,
    lang.polonnaruwa,
    lang.puttalam,
    lang.ratnapura,
    lang.trincomalee,
    lang.vavuniya,
  ];

  const MONTHS = [
    lang.january,
    lang.february,
    lang.march,
    lang.april,
    lang.may,
    lang.june,
    lang.july,
    lang.august,
    lang.september,
    lang.october,
    lang.november,
    lang.december,
  ];

  const [data1, setData1] = useState([]);

  const columns = [
    {
      title: lang.district_goal_id,
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: lang.year,
      dataIndex: "year",
      key: "year",
      fixed: "left",
    },
    {
      title: lang.month,
      dataIndex: "month",
      key: "month",
      render: (_, record) => MONTHS[record.month],
    },
    {
      title: lang.amount,
      dataIndex: "goal",
      key: "goal",
    },
    {
      title: lang.status,
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status == 0) {
          return <div className="fos-lable-red ">{lang.locked}</div>;
        } else if (record.status == 1) {
          return <div className="fos-lable-green ">{lang.active}</div>;
        } else {
          return <div className="fos-lable-blue ">{lang.upcoming}</div>;
        }
      },
    },
    {
      title: lang.action,
      key: "operation",
      fixed: "right",
      render: (_, record) => (
        <div className="fos-view-btn" onClick={() => handleActionClick(record)}>
          <EyeOutlined />
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      title: lang.district,

      dataIndex: "District",
      key: "district",
      fixed: "left",
    },
    {
      title: lang.goal,
      dataIndex: "Goal",
      key: "goal",
      fixed: "left",
    },
  ];

  const computeSumOfGoals = (dataSource) => {
    let sum = 0;
    dataSource.forEach((record) => {
      sum += parseInt(record.Goal.props.children.props.value || 0);
    });
    return sum;
  };

  const sumOfGoals = computeSumOfGoals(data1);
  const totalRow = {
    District: <b>{lang.total}</b>,
    Goal: <b style={{ margin: 10 }}>{sumOfGoals}</b>,
  };

  const modifiedData1 = [...data1, totalRow];

  const fetchOrgUnits = () => {
    getRDHSAreas().then((out) => {
      setRdhsAres(out.data.data);
    });
  };

  useEffect(() => {
    fetchOrgUnits();
    fetchAllGoals();
  }, []);

  useEffect(()=>{ fetchAllGoals() },[year]);

  useEffect(() => {
    if (selectedRow) {
      const rdhs_list = rdhsAreas.map((m, index) => {
        return {
          District: m,
          Goal: (
            <Form.Item style={{ margin: 0 }} name={`${index}`}>
              <Input
                type="number"
                disabled={selectedRow?.disabled}
                placeholder={m}
                bordered={false}
                required
              />
            </Form.Item>
          ),
        };
      });
      setData1(rdhs_list);
      getDistrictGoalMonth(selectedRow).then((out) => {
        setSelectedMonth(out.data.data);
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedMonth) {
      Object.keys(selectedMonth).forEach((key, index) => {
        form.setFieldValue(key, selectedMonth[key]?.goal);
      });
    } else {
      form.resetFields();
    }
  }, [selectedMonth]);

  return (
    <div>
      <div className="">
        <DistrictFilter setYear={setYear} />
      </div>

      <div className="row mt-4 ">
        <div className="col-md-8">
          <Card>
            <div class="fos-card-heading font-bold">{lang.heading}</div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
            {selectedRow && (
              <Modal
                title={
                  <div className="fos-heading text-center">{lang.action}</div>
                }
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={
                  [
                    // <Button key="close" onClick={handleModalClose}>
                    //   Close
                    // </Button>,
                  ]
                }
              >
                <div className="d-flex justify-content-between mt-4">
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.push_to_funding_list}{" "}
                  </Button>
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.deactivate}{" "}
                  </Button>
                  <Button className="fos-btn-primary">
                    {" "}
                    {lang.re_enroll}{" "}
                  </Button>
                </div>
              </Modal>
            )}
          </Card>
        </div>
        {selectedRow ? (
          <div className="col-md-4">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Card>
                <div class="fos-card-heading font-bold">{`${
                  selectedRow ? selectedRow?.year : ""
                } ${selectedRow ? MONTHS[selectedRow?.month] : ""}`}</div>
                <div class="fos-label mt-2">{lang.info_label}</div>
                {selectedRow ? (
                  <Table
                    columns={columns1}
                    dataSource={modifiedData1}
                    scroll={{ x: 150 }}
                    sticky
                    className="mt-3"
                    rowClassName="shrink-cell"
                    pagination={false}
                  />
                ) : null}
                {selectedRow?.status > 1 ? (
                  <div className="d-flex mt-3">
                    {/* <Button className='fos-btn-border'>{lang.edit}</Button> */}
                    <Button className="fos-btn-primary ml-4" htmlType="submit">
                      {lang.save}
                    </Button>
                  </div>
                ) : null}
              </Card>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MonthlyTargetForDestrict;
