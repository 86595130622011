import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Card } from "antd";
import moment from "moment";
import { getLogsAll } from "../../Api";
import getPageLabels from "../../Utils/Label.service";
import { ACTIVITYLOG_PAGE_LABELS } from "../../Utils/Labels/ActivityLog.page.labels";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { i18n } from "../../App";
import { CSVLink } from "react-csv";

function ActivityLog() {
  const { language } = useContext(i18n);
  const lang = useMemo(
    () => getPageLabels(ACTIVITYLOG_PAGE_LABELS, language.language),
    [language]
  );

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getLogsAll()
      .then((response) => {
        if (response.status === 200) {
          const formattedLogs = response?.data?.data.map((log) => {
            return {
              ...log,
              created: moment(log.created).format("MMMM Do YYYY"),
              time: moment(log.created).format("h:mm:ss a"),
              year: moment(log.created).format("YYYY"),
              month: moment(log.created).format("MM"),
              date: moment(log.created).format("DD"),
            };
          });

          // Sorting the logs by the created date and time
          formattedLogs.sort(
            (a, b) =>
              moment(b.created + " " + b.time, "MMMM Do YYYY h:mm:ss a") -
              moment(a.created + " " + a.time, "MMMM Do YYYY h:mm:ss a")
          );
          console.log("Formatted logs", formattedLogs);
          setLogs(formattedLogs);
        } else {
          console.error("Failed to fetch logs:", response);
        }
      })
      .catch((error) => {
        console.error("Network error while fetching logs:", error);
      });
  }, []);

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: 200,
    },
    {
      title: "Month",
      key: "month",
      dataIndex: "month",
      width: 200,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 200,
    },
    {
      title: "Time",
      key: "created",
      width: 200,
      render: (text, record) => (
        <div>
          <div>{record.time}</div>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "user",
      key: "user",
      width: 100,
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "message",
      key: "message",
      width: 300,
    },
    {
      title: "DataValue",
      dataIndex: "data",
      key: "data",
      width: 300,
    },
  ];

  return (
    <div className="activity-log-container">
      <Card className="activity-log-card" bodyStyle={{ padding: 0 }}>
        <div className="custom-table-container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="table-heading">{lang.heading}</div>
            <CSVLink
              data={logs.map(({ created, data, date, id, message, module, month, status, time, user, year }) => {
                return [created, data, date, id, message, module, month, status, time, user, year]
              })}
              headers={columns.map((col) => ({
                label: col.title,
                key: col.dataIndex,
              }))}
              filename={"activity_log.csv"}
              className="download-button"
            >
              {lang.download_report} <VerticalAlignBottomOutlined />
            </CSVLink>
          </div>
          <Table
            columns={columns}
            dataSource={logs}
            rowKey="id"
            className="custom-table"
          />
        </div>
      </Card>
    </div>
  );
}

export default ActivityLog;
