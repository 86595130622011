export const DONATIONTARGETBYDISTRICT_PAGE_LABELS = {
  heading: {
    en: "Completion Goal By RDHS Area",
    ta: "RDHS பகுதி மூலம் நிறைவு இலக்கு",
    si: "RDHS ප්‍රදේශය මගින් සම්පූර්ණ කිරීමේ ඉලක්කය",
  },
  download_report: {
    en: "Download Report",
    ta: "அறிக்கையைப் பதிவிறக்கவும்",
    si: "වර් තාව බාගත කරන්න",
  },
  district: {
    en: "RDHS Area",
    ta: "RDHS பகுதி",
    si: "RDHS ප්‍රදෙශ්ය",
  },
  Goal_Selected_Month: {
    en: "Goal For The Selected Month (No of Children)",
    ta: "தேர்ந்தெடுக்கப்பட்ட மாதத்திற்கான இலக்கு (குழந்தைகளின் எண்ணிக்கை)",
    si: "තෝරාගත් මාසය සඳහා ඉලක්කය (ළමුන් සංඛ්යාව)",
  },
  Amount_Donation_Recieved: {
    en: "Completed No of Children",
    ta: "முடிக்கப்பட்ட குழந்தைகளின் எண்ணிக்கை",
    si: "සම්පුර්ණ කරන ලද දරුවන්ගේ සංඛ්යාව",
  },
};
