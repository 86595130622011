// Alert.jsx
import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

function Alert({ message, onClose }) {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    visible && (
      <div className="fos-alert ">
        <span className="message">{message}</span>
        <button className="close-btn" onClick={handleClose}>
          <CloseCircleOutlined />
        </button>
      </div>
    )
  );
}

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const Toast2 = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 4000,
  showCloseButton: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const failureMsg = {
  'default':{
    'en' : 'fail',
    'si' : 'fail',
    'ta' : 'fail',
  }
}


const statusMsg = {
      200 : { 
        'en' : 'succuss',
        'si' : 'succuss',
        'ta' : 'succuss',
      },
      400 : { 
        'en' : 'Validation error',
        'si' : 'Validation error',
        'ta' : 'Validation error',
      },
      401 : { 
        'en' : 'Unauthorized',
        'si' : 'Unauthorized',
        'ta' : 'Unauthorized',
      },
      402 : { 
        'en' : 'Something went wrong',
        'si' : 'Something went wrong',
        'ta' : 'Something went wrong',
      },
      403 : { 
        'en' : 'Not Found',
        'si' : 'Not Found',
        'ta' : 'Not Found',
      },
      404 : { 
        'en' : 'Forbidden',
        'si' : 'Forbidden',
        'ta' : 'Forbidden',
      },
      500 : { 
        'en' : 'error',
        'si' : 'error',
        'ta' : 'error',
      },

};

export const failureAlert = (failure=null,lang) => {
  console.error('save failed',failure);
  Toast.fire({
    icon: "error",
    title: failureMsg['default'][lang],
  });
};

export const responseAlert = (status, lang, success=null) => {
  switch (status) {
    case 200:
      Toast.fire({
        icon: "success",
        title: success??statusMsg[status][lang],
      });
      break;
    case 400:
      Toast.fire({
        icon: "error",
        title: statusMsg[status][lang],
      });
      break;
    case 401:
      Toast.fire({
        icon: "error",
        title: statusMsg[status][lang],
      });
      break;
    case 403:
      Toast.fire({
        icon: "error",
        title: statusMsg[status][lang],
      });
      break;
    case 404:
      Toast.fire({
        icon: "error",
        title: statusMsg[status][lang],
      });
      break;
    default:
      // Other errors
      //default error code - 500
      Toast.fire({
        icon: "error",
        title: statusMsg[500][lang],
      });
  }
};

export default Alert;
