import { useContext, useMemo } from "react";
import { getErrorMsgLabels } from "../../../Utils/Label.service";
import { USER_FORM_RULE_MESSAGE } from "../../../Utils/validations/user_validation";
import { i18n } from "../../../App";

const UserRules = () => {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getErrorMsgLabels(USER_FORM_RULE_MESSAGE, language.language),
    [language]
  );

  const RULES = {
    user_name: [
      {
        required: true,
        message: lang?.user_name?.required,
      },
      // {
      //   max: 10,
      //   message: lang?.user_name?.max,
      // },
    ],
    email: [
      {
        required: true,
        message: lang?.email?.required,
      },
      {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Email pattern
        message: lang?.email?.pattern,
      },
    ],
    password: [
      {
        required: true,
        message: lang?.password?.required,
      },
      {
        min: 8, // Minimum password length
        message: lang?.password?.min,
      },
    ],
    confirm_password: [
      {
        required: true,
        message: lang?.confirm_password?.required,
      },
      {
        min: 8, // Minimum password length
        message: lang?.confirm_password?.min,
      },
    ],
    user_roles: [
      {
        required: true,
        message: lang?.user_roles?.required,
      },
    ],
    name: [
      {
        required: true,
        message: lang?.name?.required,
      },
    ],
    area_type: [
      {
        required: true,
        message: lang?.area_type?.required,
      },
    ],
  };
  return RULES;
};
export default UserRules;
