export const MYDONATIONDONOR_PAGE_LABELS = {
  heading: {
    en: "Donate",
    ta: "தானம் செய்யுங்கள்",
    si: "පරිත්‍යාග කරන්න",
  },
  heading_small: {
    en: "Donations",
    ta: "நன்கொடைகள்",
    si: "පරිත්‍යාග",
  },
  heading_small2: {
    en: "All Time",
    ta: "அனைத்து",
    si: "සෑම",
  },
  donation_id: {
    en: "Donation ID",
    ta: "நன்கொடைகள் ஐடி",
    si: "පරිත්‍යාග හඳුනාගැනීමේ අංකය",
  },
  transaction_id: {
    en: "Transaction ID",
    ta: "பரிவர்த்தனை ஐடி",
    si: "ගනුදෙනු හඳුනාගැනීමේ අංකය",
  },
  category: {
    en: "Category",
    ta: "வகை",
    si: "ප්‍රභේදය",
  },
  amount: {
    en: "Amount(LKR)",
    ta: "தொகை (LKR)",
    si: "මුදල (LKR)",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  date_and_time: {
    en: "Date and Time",
    ta: "தேதி மற்றும் நேரம்",
    si: "දිනය සහ වේලාව",
  },
  payment_method: {
    en: " Payment Method",
    ta: "பணம் செலுத்தும் முறை",
    si: "ගෙවීම් ක්‍රමය",
  },
  pop_up: {
    en: "PopUp",
    ta: "பாப்அப்",
    si: "උත්පතන",
  },
  beneficiaries: {
    en: "Beneficiaries",
    ta: "பயனாளிகள்",
    si: "ප්‍රතිලාභීන්",
  },
  beneficiaries_assigned: {
    en: "Beneficiaries Assigned",
    ta: "பயனாளிகள்",
    si: "ප්‍රතිලාභීන්",
  },
  open_pop_up: {
    en: "Open PopUp",
    ta: "பாப்அப்பைத் திறக்கவும்",
    si: "උත්පතන විවෘත කරන්න",
  },
  viewcertificate: {
    en: "View Certificate",
    ta: "சான்றிதழைப் பார்க்கவும்",
    si: "සහතිකය බලන්න",
  },
};
