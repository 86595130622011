import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "antd";
import "../../Styles/main.scss";
import getPageLabels from "../../Utils/Label.service";
import { TARGETCHART_PAGE_LABELS } from "../../Utils/Labels/ChartLabels/TargetChart.page.label";
import { i18n } from "../../App";
import { getChildCountByEnrollment } from "../../Api";

const Target = () => {
  const { language } = useContext(i18n);
  const [data, setData] = useState(new Array(6))

  const lang = useMemo(
    () => getPageLabels(TARGETCHART_PAGE_LABELS, language.language),
    [language]
  );

  const options = {
    yAxis: {
      type: "category",
      data: [
        lang.enrolled,
        lang.pending,
        lang.funding,
        lang.complete,
        lang.excluded,
        lang.deceased,
      ],
    },
    xAxis: {
      type: "value",
    },
    series: [
      {
        data: [
          {
            value: data[0] ?? 0,
            itemStyle: { color: "blue" },
          },
          {
            value: data[1] ?? 0,
            itemStyle: { color: "red" },
          },
          {
            value: data[2] ?? 0,
            itemStyle: { color: "green" },
          },
          {
            value: data[3] ?? 0,
            itemStyle: { color: "yellow" },
          },
          {
            value: data[4] ?? 0,
            itemStyle: { color: "orange" },
          },
          {
            value: data[5] ?? 0,
            itemStyle: { color: "purple" },
          },
        ],
        type: "bar",
      },
    ],
    tooltip: {
      trigger: "item",
    },
  };

  useEffect(() => {
    getChildCountByEnrollment().then((out) => {
      if (out.data.data) {
        setData(out.data.data)
      }
    })
  }, []);

  return (
    <>
      <Card className="">
        <div className="d-flex justify-content-between">
          <div className="fos-card-heading font-bold">{lang.heading}</div>
        </div>
        <div className="mt-3 w-100">
          <ReactECharts option={options} />
        </div>
      </Card>
    </>
  );
};

export default Target;
