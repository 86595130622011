export const TOTALDONATIONCHART_PAGE_LABELS = {
  age1: {
    en: "Age < 1 Years",
    ta: "வயது < 1 ஆண்டுகள்",
    si: "වයස අවුරුදු < 1",
  },
  age2: {
    en: "Age 1 Year",
    ta: "வயது 1 வருடம்",
    si: "වයස අවුරුදු 1",
  },
  age3: {
    en: "Age 2 Years",
    ta: "வயது 2 ஆண்டுகள்",
    si: "වයස අවුරුදු 2",
  },
  age4: {
    en: "Age 3 Years",
    ta: "வயது 3 ஆண்டுகள்",
    si: "වයස අවුරුදු 3",
  },
  age5: {
    en: "Age 4 Years",
    ta: "வயது 4 ஆண்டுகள்",
    si: "වයස අවුරුදු 4",
  },

  heading: {
    en: "Child Count By Age Range",
    ta: "வயது வரம்பில் குழந்தை எண்ணிக்கை",
    si: "වයස් සීමාව අනුව ළමුන් ගණන",
  },
};
