import React, { useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, BookFilled } from '@ant-design/icons';
import { Card } from 'antd';
import { login, registerDonor } from '../Api';
import { useDispatch } from 'react-redux';
import collage from "../../src/Assests/login1.png";
import logo from '../../src/Assests/logo.webp';
import { useNavigate } from "react-router-dom";
import { RegisterFormRules } from "../Components/Pages/validations/registration_validation_rules";
import { Toast } from "../Components/modules/Alert";



function Register() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const onFinish = (values) => {
        console.log('Success:', values);
        registerDonor(values)
            .then((val) => {
                if (val.status === 200) {
                    Toast.fire({
                        icon: "success",
                        title: "Registration Successful!",
                    });
                    setTimeout(() => navigate('/login'), 2000);
                } else {
                    throw new Error(val?.data?.message);
                }
                form.resetFields();
            })
            .catch((error) => {
                Toast.fire({
                    icon: "error",
                    title: "Registration Failed!",
                    text: error.message,
                });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const rules = RegisterFormRules();


    const handleloginClick = () => {
        navigate("/login");
    };

    const handlforgetpasswordClick = () => {
        navigate("/forgotpassword"); //add forgot password page here
    };

      const handlehomeClick = () => {
        navigate("/");
      };

    return (

        <div className='row p-0 m-0 fos-register'>
            <div className='col-md-6 fos-login-background fos-background justify-content-center align-items-center d-flex' >
                <div className='text-center'>
                <div className="fos-heading mb-4 fos-white" style={{fontSize: 30, marginLeft: 50}}>
            Feed a child and make them smile
          </div>
          <img className="w-50 fos-collage-image" src={collage}></img>
                </div>
            </div>
            <div className='col-md-6'>

                <div className='row  justify-content-center align-items-center d-flex' style={{ height: '100vh' }}>

                    <div className='p-5'>
                        <div className=''>
                            <img className='fos-login-logo' src={logo}></img>
                        </div>
                        <div className="fos-card-heading font-bold" style={{ marginBottom: 20 }}>
                            Create account
                        </div>
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                            form={form}
                        >
                            <div className='d-flex'>
                                <Form.Item className='w-100 p-2'
                                    label='First Name'
                                    name="first_name"
                                    rules={Object.values(rules?.firstname ?? {})}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item className='w-100 p-2'
                                    label='Last Name'
                                    name="last_name"
                                    rules={Object.values(rules?.lastname ?? {})}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='d-flex'>
                                <Form.Item className='w-100  p-2'
                                    label='Email'
                                    name="email"
                                    rules={Object.values(rules?.email ?? {})}
                                >
                                    <Input />
                                </Form.Item>


                            </div>
                            <div className='d-flex'>
                                <Form.Item className='w-100  p-2'
                                    label='Phone Number'
                                    name="phone"
                                    rules={Object.values(rules?.phone ?? {})}
                                >
                                    <Input />
                                </Form.Item>


                            </div>
                            <div className='d-flex'>
                                <Form.Item className='w-100 p-2'
                                    label='Password'
                                    name="password"
                                    rules={Object.values(rules?.password ?? {})}
                                >
                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                                </Form.Item>

                                <Form.Item
                                    className='w-100 p-2'
                                    label='Confirm Password'
                                    name="confirm_password"
                                    rules={Object.values(rules.confirm_password(form))}
                                >
                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                                </Form.Item>
                            </div>
                            <Form.Item className='p-2'>
                                <div className='d-flex justify-content-between '>

                                    <Checkbox>Remember me</Checkbox>


                                    {/* <a className="login-form-forgot" onClick={handlforgetpasswordClick}>
                                        Forgot password
                                    </a> */}
                                </div>

                            </Form.Item>
                            {/* <div className='p-2'>
                                <Checkbox>I agree to all the Terms and Privacy policy </Checkbox>
                            </div> */}


                            <Form.Item className='p-2'

                            >
                                <Button type="primary" htmlType="submit" className='fos-btn-primary w-100 mt-2'>
                                    Register
                                </Button>
                            </Form.Item>
                            <div className='text-center mt-2'>
                                <a className="login-form-forgot " onClick={handleloginClick}>
                                    Already have an account?
                                </a>
                            </div>
                            <div className='text-center mt-2'>
                                <a className="login-form-forgot " onClick={handlehomeClick}>
                                    Home
                                </a>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Register;