import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined, BookFilled } from "@ant-design/icons";
import { Card } from "antd";
import { forgotPasswordDonor, loginDonor, updatePasswordDonor, verifyOtpDonor } from "../Api";
import { useDispatch } from "react-redux";
import collage from "../../src/Assests/login1.png";
import logo from "../../src/Assests/logo.webp";
import { useNavigate } from "react-router-dom";
import { LoginFormRules } from "../Components/Pages/validations/login_validation_rules";
import { Toast } from "../Components/modules/Alert";



function Forgot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rules = LoginFormRules();
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState(null);
  const [id, setId] = useState(null);
  const [otp, setOtp] = useState(null);

  const sendOtp = (values) => {
    forgotPasswordDonor(values).then((val) => {
      if (val.status == 200) {
        Toast.fire({
          icon: "success",
          title: "OTP sent",
        });
        setStage(1);
        setEmail(values.email);
      } else {
        throw new Error("Login Failed! Invalid username or password.");
      }
    })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: "OTP Failed",
          text: error.message,
        });
      });
  };

  const verifyOtp = (values) => {
    verifyOtpDonor({ ...values, email }).then((val) => {
      if (val.status == 200) {
        Toast.fire({
          icon: "success",
          title: "OTP verification successful",
        });
        setOtp(values.otp);
        setId(val.data.data);
        setStage(2);
      } else {
        throw new Error("Login Failed! Invalid username or password.");
      }
    })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: "OTP verification Failed",
          text: error.message,
        });
      });
  };

  const updatePassword = (values) => {
    if (values.password != values.confirm_password) {
      Toast.fire({
        icon: "error",
        title: "Passwords does not match",
        text: "Passwords does not match",
      });
      return;
    }
    updatePasswordDonor({ ...values, email, id, otp }).then((val) => {
      if (val.status == 200) {
        Toast.fire({
          icon: "success",
          title: "Password updated",
        });
        navigate("/login");
      } else {
        throw new Error("Login Failed! Invalid username or password.");
      }
    })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: "Password update failed",
          text: error.message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="row p-0 m-0">
      <div className="col-md-6 fos-login-background fos-background justify-content-center align-items-center d-flex">
        <div className="text-center">
          <div className="fos-heading mb-4 fos-white" style={{ fontSize: 30, marginLeft: 50 }}>
            Feed a child and make them smile
          </div>
          <img className="w-50 fos-collage-image" src={collage}></img>
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="row  justify-content-center align-items-center d-flex"
          style={{ height: "100vh" }}
        >
          <div className="p-5">
            <div className="">
              <img className="fos-login-logo" src={logo}></img>
            </div>
            <div
              className="fos-card-heading font-bold"
              style={{ marginBottom: 20 }}
            >
              Forgot Password
            </div>
            {stage == 0 ?
              <Form
                name="basic"
                onFinish={sendOtp}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="email"
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email Address"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="fos-btn-primary w-100 mt-5"
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
              : null}
            {stage == 1 ?
              <Form
                name="basic"
                onFinish={verifyOtp}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="OTP"
                  name="otp"
                >
                  <Input
                    type="number"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="OTP"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="fos-btn-primary w-100 mt-5"
                  >
                    Verify OTP
                  </Button>
                </Form.Item>
              </Form>
              : null}
            {stage == 2 ?
              <Form
                name="basic"
                onFinish={updatePassword}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="New Password"
                  name="password"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="confirm_password"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="fos-btn-primary w-100 mt-5"
                  >
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;