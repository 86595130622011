import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "antd";
import "../../Styles/main.scss";
import getPageLabels from "../../Utils/Label.service";
import { NEWREGISTRATIONCHART_PAGE_LABELS } from "../../Utils/Labels/ChartLabels/NewRegistrationChart.page.label";
import { i18n } from "../../App";
import { getChildRegistrationsByMonth } from "../../Api";

const NewRegistrations = () => {
  const { language } = useContext(i18n);
  const [data, setData] = useState(new Array(12))

  const lang = useMemo(
    () => getPageLabels(NEWREGISTRATIONCHART_PAGE_LABELS, language.language),
    [language]
  );

  const options = {
    xAxis: {
      type: "category",
      data: [
        lang.jan,
        lang.feb,
        lang.mar,
        lang.apr,
        lang.may,
        lang.jun,
        lang.jul,
        lang.aug,
        lang.sep,
        lang.oct,
        lang.nov,
        lang.dec,
      ],
      axisLabel: {
        interval: 0,
        rotate: 50,
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data,
        type: "line",
      },
    ],
    tooltip: {
      trigger: "item",
    },
  };

  useEffect(() => {
    getChildRegistrationsByMonth().then((out) => {
      setData(out.data.data)
    })
  }, []);

  return (
    <>
      <Card className="">
        <div className="d-flex justify-content-between">
          <div className="fos-card-heading font-bold">{lang.heading}</div>
        </div>
        <div className="mt-3 w-100">
          <ReactECharts option={options} />
        </div>
      </Card>
    </>
  );
};

export default NewRegistrations;
