export const Login_FORM_RULE_MESSAGE = {
    username: {
        required: {
            en: "Please input your username!",
            ta: "உங்கள் பயனர்பெயரை உள்ளிடவும்!",
            si: "කරුණාකර ඔබේ පරිශීලක නමක් ඇතුළත් කරන්න!"
        }
    },
    password: {
        required: {
            en: "Please input your password!",
            ta: "உங்கள் கடவுச்சொல்லை உள்ளிடவும்!",
            si: "කරුණාකර ඔබේ මුරපදය ඇතුළත් කරන්න!"
        },
        min: {
            en: "Password must be at least 8 characters long.",
            ta: "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.",
            si: "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් දිග විය යුතුය."
          }
    },
    rememberMe: {
        en: "Remember me",
        ta: "என் விவரங்களை நினைவில் கொள்க",
        si: "මගේ විස්තර මතක තියාගන්න"
    },
    forgotPassword: {
        en: "Forgot password",
        ta: "கடவுச்சொல்லை மறந்துவிட்டேன்",
        si: "මුරපදය අමතකද?"
    },
    agreeToTerms: {
        en: "I agree to all the Terms and Privacy policy",
        ta: "அனைத்து விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கைகளையும் நான் ஏற்கிறேன்",
        si: "මම සියලු නියම සහ රහස්‍යතා ප්‍රතිපත්තියට එකඟ වෙමි."
    },
    login: {
        en: "Login",
        ta: "உள்நுழைய",
        si: "පිවිසුම"
    },
    needAccount: {
        en: "Need an account?",
        ta: "ஒரு கணக்கு வேண்டுமா?",
        si: "ගිණුමක් අවශ්‍යයි?"
    }
};
  