export const ALLDONATIONS_PAGE_LABELS = {
  heading: {
    en: "Donations",
    ta: "நன்கொடைகள்",
    si: "පරිත්‍යාග",
  },
  heading_small: {
    en: "All Time",
    ta: "அனைத்து",
    sÍi: "සමස්ත",
  },
  child_id: {
    en: "Donation ID",
    ta: "நன்கொடை ஐடி",
    si: "පරිත්‍යාග හඳුනාගැනීමේ අංකය",
  },
  transaction_id: {
    en: "Transaction ID",
    ta: "பரிவர்த்தனை ஐடி",
    si: "ගනුදෙනු හඳුනාගැනීමේ අංකය",
  },
  donor_id: {
    en: "Donor ID",
    ta: "பரிவர்த்தனை ஐடி",
    si: "පරිත්යාගශීලියාගේ හඳුනාගැනීමේ අංකය",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  donor_name: {
    en: "Donor Name",
    ta: "நன்கொடையாளர் பெயர்",
    si: "දායකයාගේ නම",
  },
  total_amount_donated: {
    en: "Total Donated Amount (LKR)",
    ta: "மொத்த நன்கொடைத் தொகை (LKR)",
    si: "මුළු පරිත්‍යාග මුදල (LKR)",
  },
  donation_date: {
    en: "Donation Date",
    ta: "நன்கொடை தேதி",
    si: "පරිත්‍යාග දිනය",
  },
  details: {
    en: "Details",
    ta: "விவரங்கள்",
    si: "විස්තර",
  },
  view_details: {
    en: "View Details",
    ta: "விபரங்களை பார்",
    si: "විස්තර පෙන්වන්න",
  }
};
