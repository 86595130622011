export const ADDNEWUSER_PAGE_LABELS = {
  heading: {
    en: "Add New User",
    ta: "புதிய பயனர்கள் சேகரிக்கவும்",
    si: "නව පරිශීලකයෙකු එකතු කරන්න",
  },
  username_lable: {
    en: "Username",
    ta: "பயனர் பெயர்",
    si: "පරිශීලක නාමය",
  },
  name_lable: {
    en: "Name",
    ta: "பெயர்",
    si: "නාමය",
  },
  email_lable: {
    en: "Email",
    ta: "மின்னஞ்சல்",
    si: "විද්යුත් තැපෑල",
  },
  username_placeholder: {
    en: "Username",
    ta: "பயனர் பெயர்",
    si: "පරිශීලක නාමය",
  },
  password_label: {
    en: "Password",
    ta: "கடவுச்சொல்",
    si: "මුරපදය",
  },
  password_placeholder: {
    en: "Password",
    ta: "கடவுச்சொல்",
    si: "මුරපදය",
  },
  confirmpassword_label: {
    en: "Confirm Password",
    ta: "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    si: "මුරපදය තහවුරු කරන්න",
  },
  confirmpassword_placeholder: {
    en: "Re Enter Password",
    ta: "கடவு சொல்லை திருப்பி உள்ளிடு",
    si: "මුරපදය යළි ඇතුළු කරන්න",
  },
  select_user_role_label: {
    en: "User Role",
    ta: "பயனர் பங்கைத் தேர்ந்தெடுக்கவும்",
    si: "පරිශීලක භූමිකාව තෝරන්න",
  },
  select_area_label: {
    en: "Select Area",
    ta: "பகுதியில் வேண்டும்",
    si: "ප්‍රදේශය තෝරන්න",
  },
  save_btn: {
    en: "Save",
    ta: "சேமிக்கவும்",
    si: "ගබඩා කරන්න",
  },
  alert_save_success: {
    en: "Added user successfully.",
    ta: "ஒரு பயனர் வெற்றிகரமாக சேர்க்கப்பட்டார்.",
    si: "පරිශීලකයෙකු සාර්ථකව එකතු කරන ලදී.",
  },
  alert_save_error: {
    en: "User Addition fail.",
    ta: "பயனர் சேர்த்தல் தோல்வி",
    si: "පරිශීලක එකතු කිරීම අසාර්ථකයි.",
  },
};
