import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const TableFilter = ({ columns, onFilter }) => {
  const [selectedHeader, setSelectedHeader] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleHeaderChange = (e) => {
    setSelectedHeader(e.target.value);
    if (!e.target.value) {
      setSearchText("");
      setIsDisabled(!isDisabled);
    } else {
      setIsDisabled();
    }
    onFilter({ column: e.target.value, searchText: searchText });
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);

    // Call the provided onFilter function with selectedHeader and searchText
    onFilter({ column: selectedHeader, searchText: e.target.value });
  };

  return (
    <div className="table-filter">
      <select value={selectedHeader} onChange={handleHeaderChange}>
        <option value="">Select column</option>
        {columns.map((col) => {
          if (col?.search ?? false) {
            return (
              <option key={col.dataIndex} value={col?.dataIndex ?? ""}>
                {col.title}
              </option>
            );
          }
        })}
      </select>
      <input
        type="text"
        placeholder="Search..."
        value={searchText}
        disabled={isDisabled}
        onChange={handleSearchTextChange}
      />

      {/* Use the Font Awesome filter icon inside the button */}
      {/* <button className="filter-button">
        <FontAwesomeIcon icon={faFilter} className="filter-icon" /> Filter
      </button> */}
    </div>
  );
};

export default TableFilter;
