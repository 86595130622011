import React, { useContext, useEffect, useMemo, useState, useRef } from "react";

import {
  Button,
  message,
  Steps,
  Form,
  Input,
  Table,
  theme,
  Card,
  Space,
  Avatar,
  InputNumber,
  Tooltip,
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import {
  FormOutlined,
  AccountBookOutlined,
  CheckOutlined,
  CheckCircleTwoTone,
  CloseOutlined,
  AuditOutlined,
  BarChartOutlined,
  DollarOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import user from "../../Assests/user.webp";
import FosUser from "../../Assests/ava.jpg";
import snehaImage from "../../Assests/logo.webp";
import getPageLabels from "../../Utils/Label.service";
import { MAKEDONATIONS_PAGE_LABELS } from "../../Utils/Labels/MakeDonations.page.labels";
import { i18n } from "../../App";
import {
  addDonation,
  addDonationTransaction,
  getDonation,
  openIPG,
  suggestBeneficiary,
} from "../../Api";
import { Toast, Toast2 } from "../modules/Alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { useReactToPrint } from "react-to-print";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import def_userimg from "../../Assests/ava.jpg";
import { getMyDonorProfile } from "../../Api";
import { ASSET_URL } from "../../Api/index";

import FHB from "../../Assests/FHB.png";
import HPB from "../../Assests/HPB.png";
import NM from "../../Assests/NM.png";
// import who from "../Assests/who.png";
import PS from "../../Assests/PS.png";
import GOV from "../../Assests/GOV.png";

import { getPublicCounts } from "../../Api";
import { MINIMUM_AMOUNT } from "../../Constants";
const { Column } = Table;

const MakeDonation = () => {
  const { language } = useContext(i18n);

  const navigate = useNavigate();
  const handleDonationNav = () => {
    navigate("/myDonations");
  };
  const lang = useMemo(
    () => getPageLabels(MAKEDONATIONS_PAGE_LABELS, language.language),
    [language]
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [amount, setAmount] = useState(0);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [lineAmount, setLineAmount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [reference, setReference] = useState(null);
  const [formData, setFormData] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(def_userimg);
  const [addMore, setAddMore] = useState(true);

  useEffect(() => {
    setRemaining(0);
    setLineAmount(0);
    setBeneficiaries([]);
  }, [amount]);

  const suggest = () => {
    suggestBeneficiary({
      beneficiaries: beneficiaries.map((b) => b.public_id),
    }).then((bene) => {
      if (bene.status == 200) {
        const ben = beneficiaries;
        if (ben[ben.length - 1]) {
          ben[ben.length - 1].amount = lineAmount;
          setRemaining(remaining - lineAmount);
          setLineAmount(0);
        }
        ben.push(bene.data.data.beneficiary);
        setBeneficiaries([...ben]);
      } else {
        Toast.fire({
          icon: "error",
          title: bene.data.message,
        });
      }
    })
  };

  const handleNavigate = (direction) => {
    if (current == 0) {
      if (amount < MINIMUM_AMOUNT) {
        Toast2.fire({
          icon: "error",
          title: lang.alert_amountR500,
        });
        return;
      }
      if (beneficiaries.length < 1) {
        setRemaining(amount);
        suggest();
      }
    }
    setCurrent(current + direction);
  };

  const getProfile = async () => {
    try {
      const response = await getMyDonorProfile();
      const prof = response.data.data;
      if (prof && prof.img) {
        const savedImageURL = `${ASSET_URL}/avatar/${prof.img}`;
        setAvatarSrc(savedImageURL);
      }
    } catch (error) {
      // Handle errors from the API call
      console.error("Error fetching profile:", error);
    }
  };

  getProfile();

  const getPaymentForm = (obj) => {
    // Live
    const GATEWAY = "https://egateway.peoplesbank.lk/ipg_qr_mdl_pg/index.php";
    // Test
    // const GATEWAY = "https://egateway.peoplesbank.lk/ipg_qr_middle_page_uat/index.php";
    const elements = [];
    for (const [key, value] of Object.entries(obj)) {
      elements.push(<input type="hidden" value={value} name={key} />);
    }
    return (
      <form
        action={GATEWAY}
        method="post"
      >
        {elements}
        {/* <input type="submit" className="fos-btn-primary" value={lang.pay_now} /> */}
        <Button
          htmlType="submit"
          className="fos-btn-primary">
          {lang.pay_now}
        </Button>
      </form>
    );
  };

  const totalRecivable = 90000;

  const contentStyle = {
    lineHeight: "Normal",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const columns = [
    {
      title: lang.child_public_id,
      dataIndex: "public_id",
    },
    // {
    //   title: lang.received_amount,
    //   dataIndex: "received",
    // },
    {
      title: lang.receivable_amount,
      dataIndex: "receivable",
      render: (text) =>
        `${text.toLocaleString()}/${totalRecivable.toLocaleString()}`,
    },
    {
      title: lang.required_funds,
      dataIndex: "receivable",
    },
    {
      title: lang.enter_amount,
      dataIndex: "amount",
      width: 200,
      render: (_, record, index) => (
        <InputNumber
          bordered={false}
          disabled={index != beneficiaries.length - 1 || remaining == 0}
          placeholder={record.amount ?? "Amount"}
          type="number"
          controls={false}
          onChange={(e) => {
            setLineAmount(e);
            console.log(remaining, e, amount);
            if(remaining - e == 0) {
              setAddMore(false);
            } else {
              setAddMore(true);
            }
          }}
        />
      ),
    },
    {
      title: lang.action,
      dataIndex: "action",
      width: 150,
      render: (_, record, index) => (
        <div style={{ display: "flex" }}>
          {index == beneficiaries.length - 1 && addMore ? (
            <Button
              type="primary"
              onClick={() => {
                if (!lineAmount) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amountN,
                  });
                  return;
                }
                if (lineAmount < MINIMUM_AMOUNT) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amountR500,
                  });
                  return;
                }
                if (remaining - lineAmount < 0) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amount_notenough,
                  });
                  return;
                }
                if (lineAmount > beneficiaries[index].receivable) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amount_greater,
                  });
                  return;
                }
                if (remaining - lineAmount >= 1) {
                  suggest();
                } else {
                  beneficiaries[index].amount = lineAmount;
                  setRemaining(remaining - lineAmount);
                }
              }}
            >
              {lang.add_child}
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (current == 4) {
      getDonation({ reference_no: reference })
        .then((out) => {
          console.log("Donation out", out);
          setCertificate(out.data.data);
        })
        .catch((e) => {
          console.log("Donation ERROR", e);
        });
    }
  }, [current]);

  const steps = [
    {
      title: lang.heading_small_1,
      icon: <FormOutlined />,
      content: (
        <div className="pp-5">
          <div>
            <label className="fos-card-heading font-bold" htmlFor="name">
              {" "}
              {lang.heading_small_2}
            </label>
          </div>
          <div>
            <InputNumber
              className="w-25 mt-4"
              placeholder={lang.enter_amountR}
              controls={false}
              type="number"
              value={amount == 0 ? "" : amount}
              onChange={(e) => setAmount(e)}
            />
          </div>
        </div>
      ),
    },

    {
      title: lang.allocate_donations,
      icon: <AccountBookOutlined />,
      content: (
        <div className="pp-5">
          <div className="text-start">
            <label className="fos-card-heading font-bold" htmlFor="name">
              {lang.allocate_donations}{" "}
            </label>
          </div>
          <div className="mt-1 text-start fos-heading-small">
            {lang.heading_small_3}
          </div>
          <div className="d-flex mt-3">
            <div className="fos-card-heading text-start font-bold ">
              <div>
                {lang.heading_small_4} :&nbsp;
                {new Intl.NumberFormat("lk-LK", {
                  style: "currency",
                  currency: "LKR",
                }).format(amount)}
                &nbsp;
              </div>
              <div className="font-bold mmt-10 ">
                ({lang.remaining}&nbsp;:&nbsp;
                {new Intl.NumberFormat("lk-LK", {
                  style: "currency",
                  currency: "LKR",
                }).format(remaining)}
                )
                <Tooltip title={lang.remaining_description}>
                  <span></span> &nbsp;
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </div>
          </div>
          <Table
            className="mt-3"
            columns={columns}
            dataSource={beneficiaries}
          />
        </div>
      ),
    },
    {
      title: lang.referral_number,
      icon: <AuditOutlined />,
      content: (
        <div className="pp-5">
          <div>
            <label htmlFor="name" style={{ fontSize: 20 }}>
              <b style={{ color: "black" }}>{lang.referral_number}</b> -{" "}
              <span
                className="fos-card-heading font-bold"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  await navigator.clipboard.writeText(reference);
                  window.alert("Copied to clipboard");
                }}
              >
                {reference}
              </span>
            </label>
          </div>
          <div className="mt-1 text-center fos-heading-small">
            <b style={{ color: "red" }}>({lang.heading_small_5})</b>
          </div>
          {formData ? getPaymentForm(formData) : null}
        </div>
      ),
    },
    {
      title: lang.payment,
      icon: <DollarOutlined />,
      content: (
        <div className="pp-5  www-50">
          <div className="text-start">
            <label className="fos-card-heading font-bold" htmlFor="name">
              Card Details{" "}
            </label>
          </div>

          <Form
            className="mt-3"
            name="basic"
            autoComplete="off"
            layout="vertical"
            action="https://egateway.peoplesbank.lk/ipg_qr_middle_page_uat/index.php"
            method="post"
          >
            <Form.Item
              label="Card Holder Name"
              name="CardHolder"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Card Number"
              name="cardNumber"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="d-flex justify-content-between">
              <Form.Item
                label="Valid Through"
                name="validNumber"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="CVV"
                name="cvvNumber"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button
              htmlType="submit"
              onClick={() => {
                // addDonationTransaction({
                //   reference_no: reference,
                //   payment_method: 0,
                // })
                //   .then((out) => {
                //     console.log("TRANSACTION OUT", out);
                //     setCurrent(4);
                //   })
                //   .catch((e) => {
                //     console.log("TR Error", e);
                //   });
                // openIPG().then(out => {
                //   console.log("IPG OUT", out);
                //   const form = new FormData();
                //   for (const [key, value] of Object.entries(out.data.data)) {
                //     console.log(`${key}: ${value}`);
                //     form.append(key, value);
                //   }
                //   axios.post('https://egateway.peoplesbank.lk/ipg_qr_middle_page/index.php', form, { headers: {"Access-Control-Allow-Origin": "*", "Accept": "*/*",} })
                // });
              }}
            >
              Confirm Payment
            </Button>
          </Form>
        </div>
      ),
    },
    {
      title: lang.finish,
      icon: <CheckOutlined />,
      content: (
        <>
          <div className="d-flex justify-content-center pp-5">
            <div className="mt-4">
              <Button
                style={{ width: "max-content" }}
                className="fos-btn-primary"
                type="success"
                // color="primary"
                onClick={handlePrint}
                title="Print certificate"
              >
                Print certificate
              </Button>
            </div>
          </div>
          <div
            className="d-flex justify-content-center "
            ref={componentRef}
            style={{ padding: "0", margin: "0" }}
          >
            <div className="ww-50 pp-5 fos-certificate">
              <img
                src={snehaImage}
                alt="Sneha Image"
                className="sneha-image"
              // style={{ height: 20 }}
              />
              <Card className="p-2">
                <div className="d-flex justify-content-center">
                  <div>
                    <CheckCircleTwoTone className="certificate-check" />
                    <div className="fos-card-heading font-bold text-center">
                      {" "}
                      {lang.donation_successful}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div>
                    <div className="text-center">
                      <Avatar
                        src={
                          <img
                            className="fos-user-img"
                            src={avatarSrc || FosUser}
                          />
                        }
                        size={90}
                      />
                    </div>
                    <div className="fos-card-heading font-bold">
                      {certificate?.donor?.first_name}&nbsp;
                      {certificate?.donor?.last_name}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg2}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.donor_id}</div>
                    <div className="fos-label-detail">
                      {certificate?.donor?.id.toString().padStart(5, "0")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fos-label">{lang.date}</div>
                    <div className="fos-label-detail">
                      {certificate?.transaction?.updated
                        ? new Intl.DateTimeFormat().format(
                          new Date(certificate?.transaction?.updated)
                        )
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.public_id}</div>
                    {certificate?.beneficiaries?.map((ben) => (
                      <div className="fos-label-detail">{ben.public_id}</div>
                    ))}
                  </div>
                  <div className="col-md-6">
                    <div className="fos-label">{lang.time}</div>
                    <div className="fos-label-detail">
                      {certificate?.transaction?.updated
                        ? new Intl.DateTimeFormat("en-AU", {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          timeZoneName: "short",
                        }).format(new Date(certificate?.transaction?.updated))
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.transaction_id}</div>
                    <div className="fos-label-detail">
                      #{certificate?.transaction?.transaction_id}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fos-label">{lang.amount}</div>
                    <div className="fos-label-detail">
                      {new Intl.NumberFormat("lk-LK", {
                        style: "currency",
                        currency: "LKR",
                      }).format(certificate?.donation?.amount)}
                    </div>
                  </div>
                </div>
                <div className="stakeholder-logos">
                  <img src={FHB} alt="FHB" />
                  <img src={HPB} alt="HPB" />
                  <img src={NM} alt="NM" />
                  <img src={PS} alt="PS" />
                  <img src={GOV} alt="GOV" />
                </div>
              </Card>
            </div>
          </div>

          <div className="social-icons">
            <a
              href="https://www.facebook.com/NDivSL"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  fontSize: "36px",
                  color: "#1877f2",
                  marginRight: "20px",
                  marginTop: "15px",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/nutritiondivision_sl/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  fontSize: "36px",
                  color: "#bc2a8d",
                  marginRight: "20px",
                }}
              />
            </a>
            <a
              href="https://twitter.com/your-twitter-url"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                style={{
                  fontSize: "36px",
                  color: "#1da1f2",
                  marginRight: "20px",
                }}
              />
            </a>
          </div>
        </>
      ),
    },
  ];

  const name = window.localStorage.getItem("name");

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  return (
    <>
      <div className="row mb-5">
        <div className="col-md-8">
          <Card className="fos-card">
            <div className="d-flex">
              {/* <div className="">
                                <img className='w-25' src={user}></img>
                            </div> */}
              <div className="">
                <div className="fos-card-heading font-bold">
                  {lang.hi + ", " + name + "!"}
                </div>
                <div className="fos-card-description">{lang.welcome}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mmt-10">
          <Card className="fos-card fos-gradient-card ">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading fos-white font-bold">
                {lang.you_can}
              </div>
              <div className="fos-card-icon d-flex fos-maroon">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description fos-white">
              {/* Rs 500.00 - Rs90,000.00 <br></br>per a single donation per child */}
              {lang.per_child}
            </p>
          </Card>
        </div>
      </div>
      <Steps current={current} items={items}>
        {steps.map((item, index) => (
          <Steps.Step key={index} icon={item.icon} />
        ))}
      </Steps>
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        className="d-flex text-center"
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && current < 2 && (
          <Button
            className="fos-btn-primary"
            style={{
              margin: "0 8px",
            }}
            onClick={() => handleNavigate(-1)}
          >
            {lang.previous_btn}
          </Button>
        )}
        {current < 2 && (remaining == 0 || !addMore) && (
          <Button
            type="primary"
            className="fos-btn-primary"
            onClick={() => {
              if (current == 1 && (remaining == 0 || !addMore)) {
                if(remaining !== 0) {
                  beneficiaries[beneficiaries.length - 1].amount = lineAmount;
                }
                addDonation({
                  amount: amount,
                  beneficiaries: beneficiaries.map((ben) => {
                    return {
                      beneficiary_id: ben.public_id,
                      amount: ben.amount,
                    };
                  }),
                })
                  .then((out) => {
                    console.log("Donation OUT", out);
                    if (
                      out &&
                      out.data &&
                      out.data.data &&
                      out.data.data.reference_no
                    ) {
                      setReference(out.data.data.reference_no);
                      setFormData(out.data.data.form);
                    }
                  })
                  .catch((e) => {
                    console.log("DONATION ERROR", e);
                  });
              }
              handleNavigate(+1);
            }}
          >
            {lang.next_btn}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            className="fos-btn-primary"
            type="primary"
            onClick={() => {
              Toast2.fire({
                icon: "success",
                title: lang.alert_process_done,
              });
              handleDonationNav();
            }}
          >
            {lang.done_btn}
          </Button>
        )}
      </div>
    </>
  );
};

export default MakeDonation;
