import React, { useContext, useEffect, useMemo, useState } from "react";
import TotalDonationThisYear from "../modules/TotalDonationThisYear";
import TotalDonationThisMonth from "../modules/TotalDonationThisMonth";
import RegisteredChildrenThisYear from "../modules/RegisteredChildrenThisYear";
import RegisteredChildrenThisMonth from "../modules/RegisteredChildrenThisMonth";
import Donation from "../charts/Donation";
import TotalDonation from "../charts/TotalDonations";
import NewRegistrations from "../charts/NewRegistrations";
import Target from "../charts/Target";
import Loader from "../modules/Loader";
import { getInternalCounts } from "../../Api";
import getPageLabels from "../../Utils/Label.service";
import { HOME_PAGE_LABELS } from "../../Utils/Labels/Home.page.labels";
import { i18n } from "../../App";

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [counts, setCounts] = useState(null);
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(HOME_PAGE_LABELS, language.language),
    [language]
  );

  const name = window.localStorage.getItem("name");

  const fetchCounts = () => {
    getInternalCounts({}).then((out) => {
      setCounts(out.data.data);
    });
  };

  useEffect(() => {
    fetchCounts();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Set the time duration

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <Loader></Loader>} {/* Conditionally render the loader */}
      <div className={isLoading ? "hidden" : ""}>
        <div className="fos-heading">
          {lang.hi} {name}
        </div>
        <div className="fos-heading-small mt-3">{lang.heading_small}</div>
      </div>
      <div className="row mt-5">
        <div className="col-md-3 animate__animated animate__fadeInUp ">
          <TotalDonationThisYear value={`${counts?.count1} LKR`} className="" />
        </div>
        <div className="col-md-3 animate__animated animate__fadeInUp">
          <TotalDonationThisMonth value={`${counts?.count2} LKR`} />
        </div>
        <div className="col-md-3 animate__animated animate__fadeInUp">
          <RegisteredChildrenThisYear value={counts?.count3} />
        </div>
        <div className="col-md-3 animate__animated animate__fadeInUp">
          <RegisteredChildrenThisMonth value={counts?.count4} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 animate__animated animate__fadeInUp">
          <Donation />
        </div>
        <div className="col-md-6 animate__animated animate__fadeInUp">
          <NewRegistrations />
        </div>
        <div className="col-md-6 mt-3 animate__animated animate__fadeInUp">
          {" "}
          <Target />
        </div>
        <div className="col-md-6 mt-3 animate__animated animate__fadeInUp">
          <TotalDonation />
        </div>
      </div>
    </>
  );
}
export default Home;
