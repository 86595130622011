import React from "react";
import { Space, Table, Tag } from 'antd';

const notFoundStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
};

const contentStyles = {
    textAlign: 'center',
};

const headingStyles = {
    fontSize: '8rem',
    color: '#ff5555',
    marginBottom: '20px',
};

const paragraphStyles = {
    fontSize: '1.5rem',
    marginBottom: '10px',
};

const listStyles = {
    listStyleType: 'none',
    padding: 0,
};

const linkStyles = {
    color: '#007bff',
    textDecoration: 'none',
};

function NotFound(){
    return(
        <div style={notFoundStyles}>
            <div style={contentStyles}>
                <h1 style={headingStyles}>404</h1>
                <p style={paragraphStyles}>Oops! The page you are looking for doesn't exist.</p>
                <p style={paragraphStyles}>Let's get you back on track:</p>
                <ul style={listStyles}>
                    <li>Check the URL for typos.</li>
                    <li>Return to the <a href="/" style={linkStyles}>home page</a>.</li>
                    <li>Contact our <a href="/contact" style={linkStyles}>support</a> for assistance.</li>
                </ul>
            </div>
        </div>
    )
}

export default NotFound;
