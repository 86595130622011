import { useContext, useMemo } from "react";
import { getErrorMsgLabels } from "../../../Utils/Label.service";
import { Register_FORM_RULE_MESSAGE } from "../../../Utils/validations/register_validations";
import { i18n } from "../../../App";

export const RegisterFormRules = () => {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getErrorMsgLabels(Register_FORM_RULE_MESSAGE, language.language),
    [language]
  );

  const RULES = {
    firstname: [
      {
        required: true,
        message: lang?.firstname?.required,
      },
    ],
    lastname: [
      {
        required: true, 
        message: lang?.lastname?.required,
      },
    ],
    email: [
      {
        required: true,
        message: lang?.email?.required,
      },
      {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Email pattern
        message: lang?.email?.pattern,
      },
    ],
    phone: [
      {
        required: true, 
        message: lang?.phone?.required,
      },
      {
        pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, // Assuming 10-digit phone number requirement
        message: lang?.phone?.pattern,
      },
    ],
    password: [
      {
        required: true, 
        message: lang?.password?.required,
      },
      {
        min: 8, // Minimum password length
        message: lang?.password?.min,
      },
    ],
    confirm_password: (form) => [
      {
          required: true,
          message: lang?.confirm_password?.required,
      },
      {
          min: 8, // Minimum password length
          message: lang?.confirm_password?.min,
      },
      {
          validator: (_, value) => {
              // Check if the value matches the 'password' field
              if (value && value !== form.getFieldValue('password')) {
                  return Promise.reject(lang?.confirm_password?.password_not_matched);
              }
              return Promise.resolve();
          },
      },
  ],
    rememberMe: [
      // define rules for rememberMe if needed
    ],
    forgotPassword: [
      //  define rules for forgotPassword if needed
    ],
    agreeToTerms: [
      //  define rules for agreeToTerms if needed
    ],
  };

  return RULES;
}
export default RegisterFormRules;
