import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "antd";
import "../../Styles/main.scss";
import getPageLabels from "../../Utils/Label.service";
import { TOTALDONATIONCHART_PAGE_LABELS } from "../../Utils/Labels/ChartLabels/TotalDonationChart.page.labels";
import { i18n } from "../../App";
import { getChildCountByAge } from "../../Api";

const TotalDonations = () => {
  const { language } = useContext(i18n);
  const [data, setData] = useState(new Array(5))

  const lang = useMemo(
    () => getPageLabels(TOTALDONATIONCHART_PAGE_LABELS, language.language),
    [language]
  );
  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "center",
      left: "start",
      orient: "vertical",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: data[0] ?? 0, name: lang.age1 },
          { value: data[1] ?? 0, name: lang.age2 },
          { value: data[2] ?? 0, name: lang.age3 },
          { value: data[3] ?? 0, name: lang.age4 },
          { value: data[4] ?? 0, name: lang.age5 },
        ],
      },
    ],
  };

  useEffect(() => {
    getChildCountByAge().then((out) => {
      if (out.data.data) {
        setData(out.data.data)
      }
    })
  }, []);

  return (
    <>
      <Card className="">
        <div className="d-flex justify-content-between">
          <div className="fos-card-heading font-bold">{lang.heading}</div>
        </div>
        <div className="mt-3 w-100">
          <ReactECharts option={options} />
        </div>
      </Card>
    </>
  );
};

export default TotalDonations;
