import React, { useState } from 'react';
import { Button, Popconfirm, Tooltip, Select } from 'antd';
import { reEnroll, setDeceasedBeneficiary, setExcludedBeneficiary, toFundingBeneficiary, verifyBeneficiary } from '../../Api';
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const BeneciaryActions = ({
  title = "",
  icon = null,
  color = "black",
  background = "white",
  record = null,
  action = 0,
  refresh = () => { },
}) => {
  const navigate = useNavigate();
  const [exclusionReason, setExclusionReason] = useState(null);
  const showReason = action === 4;

  let showable = false;
  let preFunction = async () => { };
  switch (action) {
    case 0: // Verify Button
      if (record?.sync_state == 0 && record?.funding_state == 1) {
        showable = true;
        preFunction = async () => {
          await verifyBeneficiary({ id: record?.uid });
        };
      }
      break;
    case 1: // Push to funding
      if (record?.sync_state == 1 && record?.funding_state == 1) {
        showable = true;
        preFunction = async () => {
          await toFundingBeneficiary({ id: record?.uid });
        };
      }
      break;
    case 2: // Divert funds
      if (record?.sync_state == 2 && record?.funding_state != 0) {
        showable = true;
        preFunction = async () => {
          navigate(`/divert/${record?.uid}`);
        };
      }
      break;
    case 3: // Re-enroll
      if (
        record?.sync_state < 2 &&
        (record?.funding_state == 3 || record?.funding_state == 4)
      ) {
        showable = true;
        preFunction = async () => {
          await reEnroll({ id: record?.uid });
        };
      }
      break;
    case 4: // Exclude
      if ([1, 2, 3, 5].includes(record?.funding_state)) {
        showable = true;
        preFunction = async () => {
          // Use exclusionReason in the API call
          await setExcludedBeneficiary({ id: record?.uid, remarks: exclusionReason });
          // Reset the exclusion reason after submission
          setExclusionReason(null);
        };
      }
      break;
    case 5: // Deceased
      if (record?.funding_state != 5) {
        showable = true;
        preFunction = async () => {
          await setDeceasedBeneficiary({ id: record?.uid, remarks: "" });
        };
      }
      break;
    default:
      break;
  }
  const confirm = () =>
    new Promise((resolve) => {
      preFunction().then((out) => refresh());
      setTimeout(() => resolve(null), 3000);
    });

  return (showable ?
    <Popconfirm
      zIndex='1000'
      title={`Are you sure you want to mark ${title}?`}
      description={
        showReason && (
          <>
            <Select
              placeholder="Reason"
              value={exclusionReason}
              onChange={(value) => setExclusionReason(value)}
              style={{ width: '50%' }}
            >
              <Option value="other">Other</Option>
              <Option value="fraud">Fraud</Option>
            </Select>
          </>
        )
      }
      okButtonProps={{ style: { display: exclusionReason ? 'none' : '' } }}
      onConfirm={confirm}
      onCancel={() => setExclusionReason(null)}
    >

      <Tooltip title={title} placement='bottom'>
        <Button style={{ background: background, color: color, width: 40, height: 40 }} icon={icon} />
      </Tooltip>
    </Popconfirm>
    : null
  );
}

export default BeneciaryActions;





