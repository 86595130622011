import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button } from "antd";
import PopupComponent from "./../form/PopUp";
import CertificatePopup from "./../form/CertificatePopup";
import { useNavigate } from "react-router-dom";
import FosUser from "../../Assests/ava.jpg";

import getPageLabels from "../../Utils/Label.service";
import { MYDONATIONDONOR_PAGE_LABELS } from "../../Utils/Labels/MyDonationDonor.page.labels";
import { i18n } from "../../App";
import { getMyDonations } from "../../Api";
import TableFilter from "../form/TableFilter";
import { getMyDonorProfile } from "../../Api";
import snehaImage from "../../Assests/logo.webp";
import { ASSET_URL } from "../../Api/index";
import { GET_DATE_TIME } from "../../Utils/Constants";

function DonorDonation() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(MYDONATIONDONOR_PAGE_LABELS, language.language),
    [language]
  );

  const [fosUserImg, setFosUserImg] = useState(FosUser);

  const navigate = useNavigate();

  const columns = [
    {
      title: lang.donation_id,
      dataIndex: "reference_no",
      search: true,
    },
    {
      title: lang.transaction_id,
      dataIndex: "transaction_id",
      search: true,
    },
    {
      title: lang.category,
      dataIndex: "Category",
      search: true,
      render: (_) => "SAM8",
    },
    {
      title: lang.amount,
      dataIndex: "amount",
      search: true,
    },
    {
      title: lang.date_and_time,
      dataIndex: "updated",
      search: true,
      render: (_, record) => {
        if (record.updated) {
          return GET_DATE_TIME(new Date(record.updated));
        }
        return null;
      },
    },
    {
      title: lang.beneficiaries,
      dataIndex: "Beneficiaries",
      render: (_, record) => {
        return <PopupComponent beneficiaries={record.beneficiaries} />;
      },
      width: 150,
    },
    {
      title: lang.viewcertificate,
      dataIndex: "viewcertificate",
      render: (_, record) => {
        return (
          <Button
            className="fos-btn-primary"
            onClick={() => handleViewCertificate(record)}
          >
           {lang.viewcertificate}
          </Button>
        );
      },
      width: 150,
    },
  ];
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleViewCertificate = (certificate) => {
    setSelectedCertificate(certificate);
    // console.log("crt", certificate);
    setPopupVisible(true);
  };

  useEffect(() => {
    getMyDonations()
      .then((out) => {
        setData(out.data.data);
        setFilteredData(out.data.data); // Initialize filteredData with all data
      })
      .catch((e) => {
        console.log("CATCH", e);
      });

      const getProfile = async () => {
        try {
          const response = await getMyDonorProfile();
          const prof = response.data.data;
          if (prof && prof.img) {
            const savedImageURL = `${ASSET_URL}/avatar/${prof.img}`;
            setFosUserImg(savedImageURL); // Set the fetched image URL as the user's image
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      };

      getProfile();
  
  }, []);

  // Function to handle filtering based on user input
  const handleFilter = ({ column, searchText }) => {
    if (!column || !searchText) {
      // If either column or searchText is not provided, reset the filteredData to all data
      setFilteredData(data);
      return;
    }

    // Implement your filtering logic here
    const filtered = data.filter((item) => {
      const cellValue = item[column] || ""; // Get the cell value for the selected column
      return cellValue
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    setFilteredData(filtered);
  };

  const handleMakeDonationClick = () => {
    navigate("/MakeDonation");
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-md-6 d-flex align-items-end ">
          <Button
            className="fos-btn-primary mb-4 ml-4"
            onClick={handleMakeDonationClick}
          >
            {lang.heading}
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="fos-heading ml-2">{lang.heading}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <div className="mt-1">
            {/* Render the TableFilter component */}
            <TableFilter columns={columns} onFilter={handleFilter} />
          </div>
        </div>
      </div>

      <div className="mt-1">
        <Table columns={columns} dataSource={filteredData} />
        {isPopupVisible && (
          <CertificatePopup
            certificate={selectedCertificate}
            lang={lang}
            snehaImage={snehaImage}
            FosUser={fosUserImg}
            onClose={() => setPopupVisible(false)}
          />
        )}
      </div>
    </div>
  );
}

export default DonorDonation;
