import React, { useContext, useEffect, useMemo, useState, useRef } from "react";

import {
  Button,
  message,
  Steps,
  Form,
  Input,
  Table,
  theme,
  Card,
  Space,
  Avatar,
  InputNumber,
  Tooltip,
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import {
  FormOutlined,
  AccountBookOutlined,
  CheckOutlined,
  CheckCircleTwoTone,
  CloseOutlined,
  AuditOutlined,
  BarChartOutlined,
  DollarOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import user from "../../Assests/user.webp";
import FosUser from "../../Assests/ava.jpg";
import snehaImage from "../../Assests/logo.webp";
import getPageLabels from "../../Utils/Label.service";
import { MAKEDONATIONS_PAGE_LABELS } from "../../Utils/Labels/MakeDonations.page.labels";
import { i18n } from "../../App";
import {
  addDonation,
  addDonationTransaction,
  getDonation,
  getMyDonation,
  issueCertificate,
  openIPG,
  suggestBeneficiary,
} from "../../Api";
import { Toast2 } from "../modules/Alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { useReactToPrint } from "react-to-print";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import def_userimg from "../../Assests/ava.jpg";
import { getMyDonorProfile } from "../../Api";
import Loader from "../modules/Loader";
import { ASSET_URL } from "../../Api/index";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { MINIMUM_AMOUNT } from "../../Constants";

const { Column } = Table;

const DonationConfirmation = () => {
  let { donation_id } = useParams();
  const { language } = useContext(i18n);

  const navigate = useNavigate();
  const handleDonationNav = () => {
    navigate("/myDonations");
  };
  const lang = useMemo(
    () => getPageLabels(MAKEDONATIONS_PAGE_LABELS, language.language),
    [language]
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(3);
  const [amount, setAmount] = useState(0);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [lineAmount, setLineAmount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [reference, setReference] = useState(null);
  const [formData, setFormData] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(def_userimg);
  const [donation, setDonation] = useState(null);

  useEffect(() => {
    setRemaining(0);
    setLineAmount(0);
    setBeneficiaries([]);
  }, [amount]);

  const suggest = () => {
    suggestBeneficiary({
      beneficiaries: beneficiaries.map((b) => b.public_id),
    }).then((bene) => {
      if (bene.status == 200) {
        const ben = beneficiaries;
        if (ben[ben.length - 1]) {
          ben[ben.length - 1].amount = lineAmount;
          setRemaining(remaining - lineAmount);
          setLineAmount(0);
        }
        ben.push(bene.data.data.beneficiary);
        setBeneficiaries([...ben]);
      }
    });
  };

  const handleMakeDonationClick = () => {
    navigate("/MakeDonation");
  };

  const handleNavigate = (direction) => {
    if (current == 0) {
      if (amount < MINIMUM_AMOUNT) {
        Toast2.fire({
          icon: "error",
          title: lang.alert_amountR500,
        });
        return;
      }
      if (beneficiaries.length < 1) {
        setRemaining(amount);
        suggest();
      }
    }
    setCurrent(current + direction);
  };

  const getProfile = async () => {
    try {
      const response = await getMyDonorProfile();
      const prof = response.data.data;
      if (prof && prof.img) {
        const savedImageURL = `${ASSET_URL}/avatar/${prof.img}`;
        setAvatarSrc(savedImageURL);
      }
    } catch (error) {
      // Handle errors from the API call
      console.error("Error fetching profile:", error);
    }
  };

  const issue = async () => {
    try {
      issueCertificate({ reference_no: donation_id ?? donation?.reference_no }).then((out) => {
        if (out.status == 200) {
          setCertificate(donation);
          setCurrent(4);
        }
      });
    } catch (error) {
      // Handle errors from the API call
      console.error("Error fetching profile:", error);
    }
  };

  useEffect(() => {
    getProfile();
    getMyDonation({ donation_id }).then((out) => {
      if (out.status != 200) {
        navigate("/makeDonation");
      } else {
        const don = out.data.data;
        setDonation(don);
        if (don?.donation?.status == 3) {
          setCertificate(don);
          setCurrent(4);
        }
      }
    });
  }, []);

  const getPaymentForm = (obj) => {
    const elements = [];
    for (const [key, value] of Object.entries(obj)) {
      elements.push(<input type="hidden" value={value} name={key} />);
    }
    return (
      <form
        action="https://testsecureacceptance.cybersource.com/pay"
        method="post"
      >
        {elements}
        <input type="submit" value="Pay Now" />
      </form>
    );
  };

  const renderDonation = (don) => {
    const style = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "24px",
      margin: "10px 0 50px 0",
    };

    const messages = [
      {
        message: "Payment Success!",
        icon: <CheckCircleOutlined />,
        color: "#52c41a",
        button: (
          <Button
            style={{ width: "max-content", marginTop: "10px" }}
            className="fos-btn-primary"
            type="success"
            onClick={() => issue()}
          >
            View Certificate
          </Button>
        ),
        condition: 1,
      },
      {
        message: "Payment Pending...",
        icon: <ClockCircleOutlined />,
        color: "#faad14",
        condition: 0,
      },
      {
        message: "Payment Failed!",
        icon: <ExclamationCircleOutlined />,
        color: "red",
        button: (
          <Button
            style={{ width: "max-content", marginTop: "10px" }}
            className="fos-btn-primary"
            type="success"
            onClick={handleMakeDonationClick}
          >
            Make Donation
          </Button>
        ),
        condition: 2,
      },
    ];

    let ret = null;
    switch (don?.donation?.status) {
      case 0:
        ret = messages.find((msg) => msg.condition === 0);
        if (ret) {
          return (
            <div style={{ ...style, color: ret.color }}>
              {ret.icon && (
                <span style={{ marginRight: "10px" }}>{ret.icon}</span>
              )}
              <span>{ret.message}</span>
              {ret.button && (
                <div style={{ marginTop: "10px" }}>{ret.button}</div>
              )}
            </div>
          );
        }
        break;
      case 1:
        ret = messages.find((msg) => msg.condition === 1);
        if (ret) {
          return (
            <div style={{ ...style, color: ret.color }}>
              {ret.icon && (
                <span style={{ marginRight: "10px" }}>{ret.icon}</span>
              )}
              <span>{ret.message}</span>
              {ret.button && (
                <div style={{ marginTop: "10px" }}>{ret.button}</div>
              )}
            </div>
          );
        }
        break;
      case 2:
        ret = messages.find((msg) => msg.condition === 2);
        if (ret) {
          return (
            <div style={{ ...style, color: ret.color }}>
              {ret.icon && (
                <span style={{ marginRight: "10px" }}>{ret.icon}</span>
              )}
              <span>{ret.message}</span>
              {ret.button && (
                <div style={{ marginTop: "10px" }}>{ret.button}</div>
              )}
            </div>
          );
        }
        break;
      case 3:
        return <div style={style}>CERTIFICATE ISSUED</div>;
      default:
        return null;
    }

    return null;
  };

  const contentStyle = {
    lineHeight: "Normal",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const columns = [
    {
      title: lang.child_public_id,
      dataIndex: "public_id",
    },
    {
      title: lang.received_amount,
      dataIndex: "received",
    },
    {
      title: lang.receivable_amount,
      dataIndex: "receivable",
    },
    {
      title: lang.required_funds,
      dataIndex: "receivable",
    },
    // {
    //   title: lang.receivable_change,
    //   dataIndex: "receivable",
    // },
    {
      title: lang.enter_amount,
      dataIndex: "amount",
      width: 200,
      render: (_, record, index) => (
        <InputNumber
          bordered={false}
          disabled={index != beneficiaries.length - 1 || remaining == 0}
          placeholder={record.amount ?? "Amount"}
          type="number"
          controls={false}
          onChange={(e) => setLineAmount(e)}
        />
      ),
    },
    {
      title: lang.action,
      dataIndex: "action",
      width: 150,
      render: (_, record, index) => (
        <div style={{ display: "flex" }}>
          {index == beneficiaries.length - 1 ? (
            <Button
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => {
                if (!lineAmount) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amountN,
                  });
                  return;
                }
                if (remaining - lineAmount < 0) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amount_notenough,
                  });
                  return;
                }
                if (lineAmount > beneficiaries[index].receivable) {
                  Toast2.fire({
                    icon: "error",
                    title: lang.alert_amount_greater,
                  });
                  return;
                }
                if (remaining - lineAmount >= 1) {
                  suggest();
                } else {
                  beneficiaries[index].amount = lineAmount;
                  setRemaining(remaining - lineAmount);
                }
              }}
            />
          ) : null}
        </div>
      ),
    },
  ];

  const steps = [
    {
      title: lang.heading_small_1,
      icon: <FormOutlined />,
      content: (
        <div className="pp-5">
          <div>
            <label className="fos-card-heading font-bold" htmlFor="name">
              {" "}
              {lang.heading_small_2}
            </label>
          </div>
          <div>
            <InputNumber
              className="w-25 mt-4"
              placeholder={lang.enter_amountR}
              controls={false}
              type="number"
              value={amount == 0 ? "" : amount}
              onChange={(e) => setAmount(e)}
            />
          </div>
        </div>
      ),
    },

    {
      title: lang.allocate_donations,
      icon: <AccountBookOutlined />,
      content: (
        <div className="pp-5">
          <div className="text-start">
            <label className="fos-card-heading font-bold" htmlFor="name">
              {lang.allocate_donations}{" "}
            </label>
          </div>
          <div className="mt-1 text-start fos-heading-small">
            {lang.heading_small_3}
          </div>
          <div className="d-flex mt-3">
            <div className="fos-card-heading text-start font-bold ">
              <div>{lang.heading_small_4} :&nbsp; </div>
              <div className="font-bold mmt-10 ">
                <b>
                  {new Intl.NumberFormat("lk-LK", {
                    style: "currency",
                    currency: "LKR",
                  }).format(amount)}
                </b>
                &nbsp;({lang.remaining}&nbsp;:&nbsp;
                {new Intl.NumberFormat("lk-LK", {
                  style: "currency",
                  currency: "LKR",
                }).format(remaining)}
                )
              </div>
            </div>
          </div>
          <Table
            className="mt-3"
            columns={columns}
            dataSource={beneficiaries}
          />
        </div>
      ),
    },
    {
      title: lang.referral_number,
      icon: <AuditOutlined />,
      content: (
        <div className="pp-5">
          <div>
            <label htmlFor="name">
              <b style={{ fontSize: 18 }}>{lang.referral_number}</b> -{" "}
              <span
                className="fos-card-heading font-bold"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  await navigator.clipboard.writeText(reference);
                  window.alert("Copied to clipboard");
                }}
              >
                {reference}
              </span>
            </label>
          </div>
          <div className="mt-1 text-center fos-heading-small">
            ({lang.heading_small_5})
          </div>
          {formData ? getPaymentForm(formData) : null}
        </div>
      ),
    },
    {
      title: lang.payment,
      icon: <DollarOutlined />,
      content: (
        <div className="pp-5  www-50">
          {donation ? renderDonation(donation) : <Loader />}
        </div>
      ),
    },
    {
      title: lang.finish,
      icon: <CheckOutlined />,
      content: (
        <>
          <div className="d-flex justify-content-center pp-5">
            <div className="mt-4">
              <Button
                style={{ width: "max-content" }}
                className="fos-btn-primary"
                type="success"
                // color="primary"
                onClick={handlePrint}
                title="Print certificate"
              >
                Print certificate
              </Button>
            </div>
          </div>
          <div
            className="d-flex justify-content-center "
            ref={componentRef}
            style={{ padding: "0", margin: "0" }}
          >
            <div className="ww-50 pp-5 fos-certificate">
              <img src={snehaImage} alt="Sneha Image" className="sneha-image" />
              <Card className="p-2">
                <div className="d-flex justify-content-center">
                  <div>
                    <div
                      className="text-center"
                      style={{
                        width: "64px",
                        height: "64px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleTwoTone
                        style={{ fontSize: "26px", marginLeft: "136px" }}
                      />
                    </div>
                    <div className="fos-card-heading font-bold">
                      {" "}
                      {lang.donation_successful}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div>
                    <div className="text-center">
                      <Avatar
                        src={
                          <img
                            className="fos-user-img"
                            src={avatarSrc || FosUser}
                          />
                        }
                        size={90}
                      />
                    </div>
                    <div className="fos-card-heading font-bold">
                      {certificate?.donor?.first_name}&nbsp;
                      {certificate?.donor?.last_name}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg}
                    </div>
                    <div className="fos-label text-center">
                      {" "}
                      {lang.thankyou_msg2}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.donor_id}</div>
                    <div className="fos-label-detail">
                      {certificate?.donor?.id.toString().padStart(5, "0")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fos-label">{lang.date}</div>
                    <div className="fos-label-detail">
                      {certificate?.transaction?.updated
                        ? new Intl.DateTimeFormat().format(
                            new Date(certificate?.transaction?.updated)
                          )
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.public_id}</div>
                    {certificate?.beneficiaries?.map((ben) => (
                      <div className="fos-label-detail">{ben.public_id}</div>
                    ))}
                  </div>
                  <div className="col-md-6">
                    <div className="fos-label">{lang.time}</div>
                    <div className="fos-label-detail">
                      {certificate?.transaction?.updated
                        ? new Intl.DateTimeFormat("en-AU", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZoneName: "short",
                          }).format(new Date(certificate?.transaction?.updated))
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="fos-label">{lang.transaction_id}</div>
                    <div className="fos-label-detail">
                      #{certificate?.transaction?.transaction_id}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fos-label">{lang.amount}</div>
                    <div className="fos-label-detail">
                      {new Intl.NumberFormat("lk-LK", {
                        style: "currency",
                        currency: "LKR",
                      }).format(certificate?.donation?.amount)}
                    </div>
                  </div>
                </div>
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/NDivSL"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{
                        fontSize: "36px",
                        color: "#1877f2",
                        marginRight: "20px",
                        marginTop: "15px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/nutritiondivision_sl/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{
                        fontSize: "36px",
                        color: "#bc2a8d",
                        marginRight: "20px",
                      }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/your-twitter-url"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{
                        fontSize: "36px",
                        color: "#1da1f2",
                        marginRight: "20px",
                      }}
                    />
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </>
      ),
    },
  ];

  const name = window.localStorage.getItem("name");

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  return (
    <>
      <div className="row mb-5">
        <div className="col-md-8">
          <Card className="fos-card">
            <div className="d-flex">
              {/* <div className="">
                                <img className='w-25' src={user}></img>
                            </div> */}
              <div className="">
                <div className="fos-card-heading font-bold">
                  {lang.hi + ", " + name + "!"}
                </div>
                <div className="fos-card-description">{lang.welcome}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mmt-10">
          <Card className="fos-card fos-gradient-card ">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading fos-white font-bold">
                {lang.you_can}
              </div>
              <div className="fos-card-icon d-flex fos-maroon">
                <BarChartOutlined />
              </div>
            </div>
            <p className="fos-card-description fos-white">
              {/* Rs 500.00 - Rs90,000.00 <br></br>per a single donation per child */}
              {lang.per_child}
            </p>
          </Card>
        </div>
      </div>
      <Steps current={current} items={items}>
        {steps.map((item, index) => (
          <Steps.Step key={index} icon={item.icon} />
        ))}
      </Steps>
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        className="d-flex text-center"
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && current < 2 && (
          <Button
            className="fos-btn-primary"
            style={{
              margin: "0 8px",
            }}
            onClick={() => handleNavigate(-1)}
          >
            {lang.previous_btn}
          </Button>
        )}
        {current < 3 && remaining == 0 && (
          <Button
            type="primary"
            className="fos-btn-primary"
            onClick={() => {
              if (current == 1 && remaining == 0) {
                addDonation({
                  amount: amount,
                  beneficiaries: beneficiaries.map((ben) => {
                    return {
                      beneficiary_id: ben.public_id,
                      amount: ben.amount,
                    };
                  }),
                })
                  .then((out) => {
                    console.log("Donation OUT", out);
                    if (
                      out &&
                      out.data &&
                      out.data.data &&
                      out.data.data.reference_no
                    ) {
                      setReference(out.data.data.reference_no);
                      setFormData(out.data.data.form);
                    }
                  })
                  .catch((e) => {
                    console.log("DONATION ERROR", e);
                  });
              }
              handleNavigate(+1);
            }}
          >
            {lang.next_btn}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            className="fos-btn-primary"
            type="primary"
            onClick={() => {
              Toast2.fire({
                icon: "success",
                title: lang.alert_process_done,
              });
              handleDonationNav();
            }}
          >
            {lang.done_btn}
          </Button>
        )}
      </div>
    </>
  );
};

export default DonationConfirmation;
