import {
  UserOutlined,
  DesktopOutlined,
  PieChartOutlined,
  DiffOutlined,
  FileDoneOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  RiseOutlined,
  SettingOutlined,
  DollarOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import Home from "./Components/Pages/Home";
import Beneficiaries from "./Components/Pages/Benificiaries";
import AllDonation from "./Components/Pages/AllDonations";
import DonationDetails from "./Components/Pages/DonationDetails";
import CommonFilter from "./Components/modules/CommonFilter";
import MonthlyTargetForDestrict from "./Components/Pages/MonthlyTargetForDistrict";
import MonthlyTargetForCategory from "./Components/Pages/MonthlyTargetForCategory";
import AnnulTargetForNational from "./Components/Pages/AnnulTargetForNational";
import SetFundsReleaseDate from "./Components/Pages/SetFundsReleaseDate";
import DonationRecievedByBenificiary from "./Components/Pages/DonationRecievedByBenificiary";
import DonationRecievedByCategory from "./Components/Pages/DonationRecievedByCategory";
import DonationRecievedByDonor from "./Components/Pages/DonationRecievedByDonor";
import FundsReleaseByBenificiary from "./Components/Pages/FundsReleaseByBenificiary";
import DonationRecievedByDistrict from "./Components/Pages/DonationRecievedByDistrict";
import DonationTargetByDistrict from "./Components/Pages/DonationTargetByDistrict";
import AllUsers from "./Components/Pages/AllUsers";
import AddNewUser from "./Components/Pages/AddNewUser";
import Area from "./Components/Pages/Area";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ManageDashboard from "./Components/Pages/ManageDashboard";
import AddNewUserRole from "./Components/Pages/AddNewUserRole";
import AddNewArea from "./Components/Pages/AddNewArea";
import MakeDonation from "./Components/Donor Module/MakeDonation";
import DonorDonation from "./Components/Donor Module/DonorDonation";
import ActivityLog from "./Components/Pages/ActivityLog";
import AccountSetting from "./Components/Pages/AccountSetting";
import DonorDashboard from "./Components/Donor Module/DonorDashboard";
import AllDonors from "./Components/Pages/AllDonors";
import AdminLogin from "./Pages/AdminLogin";

import UserRoles from "./Components/Pages/UserRoles";
import PublicDashboard from "./Pages/PublicDashboard";
import {LANGUAGE_CODE} from "./Utils/Constants";

function getItem(
  label,
  key,
  icon,
  children,
  url = null,
  element = null,
  auth = true,
  nav = true,
  donor = false
) {
  return {
    key,
    icon,
    children,
    label,
    url,
    element,
    auth,
    nav,
    donor,
  };
}

export const items = [
  getItem(
    "dashboard",
    "1",
    <AppstoreOutlined />,
    null,
    "/",
    <PublicDashboard />,
    false,
    false,
    true
  ),
  getItem("home", "12", <AppstoreOutlined />, null, "/home", <Home />),
  getItem(
    "benificiaries",
    "2",
    <DesktopOutlined />,
    null,
    "/beneficiaries",
    <Beneficiaries />
  ),
  getItem(
    "donation_module",
    "3",
    <FileDoneOutlined />,
    [
      getItem(
        "all_donations",
        "3.1",
        null,
        null,
        "/allDonations",
        <AllDonation />
      ),
      // getItem(
      //   "donation_details",
      //   "3.2",
      //   null,
      //   null,
      //   "/donationDetails",
      //   <DonationDetails />
      // ),
      // getItem(
      //   "common_filter",
      //   "3.3",
      //   null,
      //   null,
      //   "/commonFilters",
      //   <CommonFilter />
      // ),
      getItem(
        "monthly_goal_for_district",
        "3.4",
        null,
        null,
        "/monthlyGoalForDistrict",
        <MonthlyTargetForDestrict />
      ),
      getItem(
        "monthly_goal_for_category",
        "3.5",
        null,
        null,
        "/monthlyGoalForCategory",
        <MonthlyTargetForCategory />
      ),
      // getItem(
      //   "annual_goal_for_national",
      //   "3.6",
      //   null,
      //   null,
      //   "/annualGoalForNational",
      //   <AnnulTargetForNational />
      // ),
      getItem(
        "set_funds_release_date",
        "3.7",
        null,
        null,
        "/setFundsReleaseDate",
        <SetFundsReleaseDate />
      ),
    ],
    null
  ),
  getItem("reports", "4", <DiffOutlined />, [
    getItem(
      "donations_recieved_by_beneficiary",
      "4.1",
      null,
      null,
      "/donationRecievedByBenificiary",
      <DonationRecievedByBenificiary />
    ),
    getItem(
      "donations_recieved_by_category",
      "4.2",
      null,
      null,
      "/donationRecievedByCategory",
      <DonationRecievedByCategory />
    ),
    getItem(
      "donations_recieved_by_donor",
      "4.3",
      null,
      null,
      "/donationRecievedByDonor",
      <DonationRecievedByDonor />
    ),
    getItem(
      "funds_release_by_beneficiary",
      "4.4",
      null,
      null,
      "/fundsReleaseByBenificiary",
      <FundsReleaseByBenificiary />
    ),
    getItem(
      "donations_recieved_by_district",
      "4.5",
      null,
      null,
      "/donationRecievedByDistrict",
      <DonationRecievedByDistrict />
    ),
    getItem(
      "donations_goal_by_district",
      "4.6",
      null,
      null,
      "/donationGoalByDistrict",
      <DonationTargetByDistrict />
    ),
  ]),
  getItem(
    "manage_dashboard",
    "5",
    <AppstoreAddOutlined />,
    null,
    "/manageDashboard",
    <ManageDashboard />
  ),
  getItem("user_module", "6", <UserOutlined />, [
    getItem("all_users", "6.1", null, null, "/allUsers", <AllUsers />),
    getItem("all_donors", "6.2", null, null, "/allDonors", <AllDonors />),
    getItem("user_roles", "6.3", null, null, "/userRoles", <UserRoles />),
    // getItem("user_areas", "6.3", null, null, "/", null),
    // getItem("add_new_user", "6.4", null, null, "/addNewUser", <AddNewUser />),
    // getItem(
    //   "add_new_user_role",
    //   "6.5",
    //   null,
    //   null,
    //   "/AddNewUserRole",
    //   <AddNewUserRole />
    // ),
    getItem("area", "6.6", null, null, "/area", <Area />),
    // getItem("add_new_area", "6.7", null, null, "/AddNewArea", <AddNewArea />),
  ]),
  getItem(
    "activity_log",
    "7",
    <RiseOutlined />,
    null,
    "/activityLog",
    <ActivityLog />
  ),
  getItem(
    "donor_dashboard",
    "8",
    <AppstoreOutlined />,
    null,
    "/donorDashboard",
    <DonorDashboard />,
    true,
    true,
    true
  ),
  getItem(
    "make_donation",
    "9",
    <DollarOutlined />,
    null,
    "/makeDonation",
    <MakeDonation />,
    true,
    true,
    true
  ),
  getItem(
    "donor_donation",
    "10",
    <GiftOutlined />,
    null,
    "/myDonations",
    <DonorDonation />,
    true,
    true,
    true
  ),
  getItem(
    "account_settings",
    "11",
    <SettingOutlined />,
    null,
    "/settings",
    <AccountSetting />,
    true,
    true,
    true
  ),
  getItem(
    "Login",
    "13",
    <PieChartOutlined />,
    null,
    "/login",
    <Login />,
    false,
    false
  ),
  getItem(
    "Register",
    "14",
    <PieChartOutlined />,
    null,
    "/admin_login",
    <AdminLogin />,
    false,
    false
  ),
  getItem(
    "Register",
    "15",
    <PieChartOutlined />,
    null,
    "/Register",
    <Register />,
    false,
    false
  ),
];

export const ROLE = [
  { value: 0, text: "Admin" },
  { value: 1, text: "MOH" },
  { value: 2, text: "RDHS" },
];

export const AREA = [
  { value: 0, text: "Panadura MOH" },
  { value: 1, text: "Kaluthara MOH" },
  { value: 2, text: "Colombo MOH" },
];

export const PERMISSION = [
  { value: 0, text: "admin permission " },
  { value: 1, text: "MOH permission" },
  { value: 2, text: "RDHS permission" },
];

const allRoutes = [];
items.forEach((item) => {
  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
      if (child.url && child.element) {
        allRoutes.push(child);
      }
    });
  } else {
    if (item.url && item.element) {
      allRoutes.push(item);
    }
  }
});
export { allRoutes };

export const MINIMUM_AMOUNT = 10;
