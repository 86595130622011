import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined, BookFilled } from "@ant-design/icons";
import { Card } from "antd";
import { loginDonor } from "../Api";
import { useDispatch } from "react-redux";
import collage from "../../src/Assests/login1.png";
import logo from "../../src/Assests/logo.webp";
import { useNavigate } from "react-router-dom";
import { LoginFormRules } from "../Components/Pages/validations/login_validation_rules";
import { Toast } from "../Components/modules/Alert";



function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rules = LoginFormRules();

  const onFinish = (values) => {
    loginDonor(values).then((val) => {
      if (val.status == 200) {
        window.localStorage.setItem("token", val.data.data.token);
        window.localStorage.setItem("donor", val.data.data.donor);
        window.localStorage.setItem("name", val.data.data.name);
        navigate("/donorDashboard");
      Toast.fire({
        icon: "success",
        title: "Login Successful!",
      });
    } else {
      throw new Error(val?.data?.message);
    }
  })
  .catch((error) => {
    Toast.fire({
      icon: "error",
      title: "Login Failed!",
      text: error.message,
    });
  });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleregisterClick = () => {
    navigate("/register");
  };

  const handlforgetpasswordClick = () => {
    navigate("/forgotpassword"); //add forgot password page here
  };

  return (
    <div className="row p-0 m-0">
      <div className="col-md-6 fos-login-background fos-background justify-content-center align-items-center d-flex">
        <div className="text-center">
          <div className="fos-heading mb-4 fos-white" style={{fontSize: 30, marginLeft: 50}}>
            Feed a child and make them smile
          </div>
          <img className="w-50 fos-collage-image" src={collage}></img>
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="row  justify-content-center align-items-center d-flex"
          style={{ height: "100vh" }}
        >
          <div className="p-5">
            <div className="">
              <img className="fos-login-logo" src={logo}></img>
            </div>
            <div
              className="fos-card-heading font-bold"
              style={{ marginBottom: 20 }}
            >
              Login
            </div>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Email/Phone Number"
                name="username"
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email/Phone Number"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item className="">
                <div className="d-flex justify-content-between ">
                  <Checkbox>Remember me</Checkbox>

                  <a className="login-form-forgot" onClick={handlforgetpasswordClick}>
                    Forgot password
                  </a>
                </div>
              </Form.Item>
              {/* <a href='/register'>
                                New user registration!
                            </a> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="fos-btn-primary w-100 mt-5"
                >
                  Login
                </Button>
              </Form.Item>
              <div className="text-center mt-2">
                <a className="login-form-forgot " onClick={handleregisterClick}>
                  Need an account?
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;