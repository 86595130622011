import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Select, Space, Table, Tag } from 'antd';
import { Card } from 'antd';
import { CarryOutOutlined } from '@ant-design/icons';
import '../../Styles/main.scss';
import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Dropdown, Button } from 'antd';
import FosUser from '../../Assests/ava.jpg';
import getPageLabels from "../../Utils/Label.service";
import { SETFUNDSRELEASEDATE_PAGE_LABELS } from "../../Utils/Labels/SetFundsReleaseDate.page.labels";
import { i18n } from "../../App";
import { Toast } from "../modules/Alert";
import { getPayoutDate, getReleases, releaseFunds } from '../../Api';
import { GET_DATE_TIME } from '../../Utils/Constants';



function SetFundsReleaseDate() {
    const [payDay, setPayDay] = useState(null);
    const [releases, setReleases] = useState([]);

    const refreshPayouts = () => {
        getPayoutDate().then(out => {
            const pay = out.data.data;
            setPayDay(pay);
        })
        getReleases().then(out => {
            const releasesOut = out.data.data;
            setReleases(releasesOut);
        });
    }

    const { language } = useContext(i18n);

    const lang = useMemo(
        () => getPageLabels(SETFUNDSRELEASEDATE_PAGE_LABELS, language.language),
        [language]
    );

    const onFinish = (values) => {
        releaseFunds().then(out => {
            Toast.fire({
                icon: "success",
                title: lang.alert_save_success,
            });
            refreshPayouts();
        })
    };

    useEffect(() => {
        refreshPayouts()
    }, []);

    const columns = [
        {
            title: lang.year,
            dataIndex: "pay_year",
            search: true,
            key: "pay_year",
            fixed: "left",
        },
        {
            title: lang.month,
            dataIndex: "pay_month",
            search: true,
            key: "pay_month",
            fixed: "left",
        },
        {
            title: lang.no_children_recieved_funds,
            dataIndex: "payouts",
            search: true,
            key: "payouts",
        },
    ];

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card className="p-2">
                        <Form onFinish={onFinish}>
                            <div className="">
                                <div className="fos-card-heading font-bold"> {lang.heading}</div>
                                <div className="fos-label"> {lang.info_1}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mt-3">
                                    <div className="fos-label"> {lang.next_fund_release_date}</div>
                                    <div className="fos-label-detail"> {payDay?.date}</div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="fos-label"> {lang.no_children_recieve_funds}</div>
                                    <div className="fos-label-detail">{payDay?.count}</div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="fos-label">{lang.current_select_date}</div>
                                    <div className="fos-label-detail">{GET_DATE_TIME(new Date())}</div>
                                    {/* {payDay ? <Form.Item name="date">
                                        <Select style={{ width: 150 }} defaultValue={payDay?.pay_date} options={payDay?.range.map((day) => { return { label: day, value: day } })} showSearch />
                                    </Form.Item> : null} */}
                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                {payDay ? null : <Button className='fos-btn-primary ml-4' htmlType='submit'>{lang.release_funds}</Button>}
                            </div>

                        </Form>

                    </Card>

                </div>

                <div className="col-md-12 mt-3">
                    <Card className="p-2">
                        <Table
                            columns={columns}
                            dataSource={releases}
                            scroll={{ x: 150 }}
                            sticky
                            className="mt-3"
                        />

                    </Card>

                </div>

            </div>


        </>
    )
}
export default SetFundsReleaseDate;