export const ALLUSERS_PAGE_LABELS = {
  heading: {
    en: "All Users",
    ta: "அனைத்து பயனாளர்கள்",
    si: "සියලුම පරිශීලකයින්",
  },
  add_user_btn: {
    en: "Add New User",
    ta: "புதிய பயனரைச் சேர்க்கவும்",
    si: "නව පරිශීලක එකතු කරන්න",
  },
  username: {
    en: "User Name",
    ta: "பயனர் பெயர்",
    si: "පරිශීලක නාමය",
  },
  email: {
    en: "Email",
    ta: "மின்னஞ்சல்",
    si: "විද්යුත් තැපෑල",
  },
  name: {
    en: "Name",
    ta: "பெயர்",
    si: "නම",
  },
  area_type: {
    en: "Area Type",
    ta: "பகுதி வகை",
    si: "ප්රදේශයේ වර්ගය",
  },
  user_role: {
    en: "User Role",
    ta: "பயனர் பங்கு",
    si: "පරිශීලක භූමිකාව",
  },
  status: {
    en: "Status",
    ta: "நிலை",
    si: "තත්ත්වය",
  },

  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  push_to_funding_list: {
    en: "Push to Funding List",
    ta: "நிதியளிப்பு பட்டியலுக்கு அழுத்தவும்",
    si: "අරමුදල් ලැයිස්තුවට තල්ලු කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "අක්‍රිය කරන්න",
  },
  activate: {
    en: "Activate",
    ta: "செயல்படுத்த",
    si: "සක්‍රිය කරන්න",
  },
  alert_push_to_funding_list_success: {
    en: "Push to Funding List successfully",
    ta: "வெற்றிகரமாக நிதியளிப்பு பட்டியலுக்கு புஷ்.",
    si: "සාර්ථකව අරමුදල් ලැයිස්තුවට තල්ලු කරන ලදී.",
  },
  alert_deactivate_success: {
    en: "Deactivate successfully.",
    ta: "வெற்றிகரமாக செயலிழக்கப்பட்டது.",
    si: "සාර්ථකව නිෂ්ක්‍රීය කරන ලදී.",
  },
  alert_activate_success: {
    en: "User Activated successfully. ",
    ta: "பயனர் வெற்றிகரமாக செயல்படுத்தப்பட்டார்.",
    si: "පරිශීලකයා සාර්ථකව සක්‍රිය කර ඇත.",
  },
};
