export const ACCOUNTSETTINGS_PAGE_LABELS = {
  heading: {
    en: "Account Settings",
    ta: "கணக்கு அமைப்புகள்",
    si: "ගිණුම් සැකසුම්",
  },
  upload: {
    en: "Upload",
    ta: "பதிவேற்றம்",
    si: "උඩුගත කරන්න",
  },
  edit_profile_image: {
    en: "Edit Profile Image",
    ta: "சுயவிவரப் படத்தைத் திருத்து",
    si: "පැතිකඩ රූපය සංස්කරණය කරන්න",
  },
  edit_first_name: {
    en: "Edit First Name",
    ta: "முதல் பெயரைத் திருத்தவும்",
    si: "මුල් නම සංස්කරණය කරන්න",
  },
  edit_surname: {
    en: "Edit Surname",
    ta: "குடும்பப்பெயரை திருத்தவும்",
    si: "වාසගම සංස්කරණය කරන්න",
  },
  addcard: {
    en: "Add Card No",
    ta: "கார்டு எண்ணைச் சேர்க்கவும்",
    si: "කාඩ්පත් අංකය එකතු කරන්න",
  },
  validthrough: {
    en: "Valid Through",
    ta: "செல்லுபடியாகும் நேரம்",
    si: "වලංගු කාලය",
  },
  cvv: {
    en: "CVV",
    ta: "சிவிவியைச் சேர்க்கவும்",
    si: "CVV එකතු කරන්න",
  },
  save: {
    en: "Update",
    ta: "புதுப்பிக்கவும்",
    si: "යාවත්කාලීන කරන්න",
  },

  //alerts
  alert_update_success: {
    en: "Account Updated Successfully.",
    ta: "கணக்கு வெற்றிகரமாக புதுப்பிக்கப்பட்டது.",
    si: "ගිණුම සාර්ථකව යාවත්කාලීන කරන ලදී.",
  },
  alert_update_error: {
    en: "Error Updating Account.",
    ta: "கணக்கைப் புதுப்பிப்பதில் பிழை.",
    si: "ගිණුම යාවත්කාලීන කිරීමේ දෝෂයකි.",
  },
};
