export const MONTHLYTARGETFORDISTRICT_PAGE_LABELS = {
  heading: {
    en: "Monthly Goal for RDHS",
    ta: "மாவட்டத்திற்கான மாதாந்திர இலக்கு",
    si: "දිස්ත්‍රික්කය සඳහා මාසික ඉලක්කය",
  },
  district_goal_id: {
    en: "RDHS Goal ID",
    ta: "மாவட்ட இலக்கு ஐடி",
    si: "දිස්ත්‍රික්ක ඉලක්ක හඳුනාගැනීමේ අංකය",
  },
  year: {
    en: "Year",
    ta: "ஆண்டு",
    si: "වසර",
  },
  month: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },
  amount: {
    en: "No of Children",
    ta: "குழந்தைகளின் எண்ணிக்கை",
    si: "දරුවන් සංඛ්‍යාව",
  },
  status: {
    en: "Status",
    ta: "நிலை",
    si: "තත්වය",
  },
  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  info_label: {
    en: "Click and update the goal",
    ta: "கிளிக் செய்து இலக்கத்தை மேம்படுத்தவும்",
    si: "ක්ලික් කර ඉලක්කය යාවත්කාලීන කරන්න",
  },
  district: {
    en: "RDHS",
    ta: "RDHS",
    si: "RDHS",
  },
  goal: {
    en: "No of Children",
    ta: "குழந்தைகளின் எண்ணிக்கை",
    si: "දරුවන් සංඛ්යාව",
  },
  edit: {
    en: "Edit",
    ta: "தொகு",
    si: "සංස්කරණය කරන්න",
  },
  save: {
    en: "Save",
    ta: "சேமிக்கவும்",
    si: "ගබඩා කරන්න",
  },
  push_to_funding_list: {
    en: "Push to Funding List",
    ta: "நிதியளிப்பு பட்டியலுக்கு அழுத்தவும்",
    si: "අරමුදල් ලැයිස්තුවට තල්ලු කරන්න",
  },
  deactivate: {
    en: "Deactivate",
    ta: "செயலிழக்கச் செய்",
    si: "අක්‍රිය කරන්න",
  },
  re_enroll: {
    en: "Re Enroll",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  ampara: {
    en: "Ampara",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  anuradhapura: {
    en: "Anuradhapura",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  badulla: {
    en: "Badulla",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  batticaloa: {
    en: "Batticaloa",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  colombo: {
    en: "Colombo",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  galle: {
    en: "Galle",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  gampaha: {
    en: "Gampaha",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  hambantota: {
    en: "Hambantota",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  jaffna: {
    en: "Jaffna",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  kalutara: {
    en: "Kalutara",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  kandy: {
    en: "Kandy",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  kegalle: {
    en: "Kegalle",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  kilinochchi: {
    en: "Kilinochchi",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  kurunegala: {
    en: "Kurunegala",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  mannar: {
    en: "Mannar",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  matale: {
    en: "Matale",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  matara: {
    en: "Matara",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  monaragala: {
    en: "Monaragala",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  mullaitivu: {
    en: "Mullaitivu",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  nuwara_eliya: {
    en: "Nuwara Eliya",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  polonnaruwa: {
    en: "Polonnaruwa",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  puttalam: {
    en: "Puttalam",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  ratnapura: {
    en: "Ratnapura",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  trincomalee: {
    en: "Trincomalee",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  vavuniya: {
    en: "Vavuniya",
    ta: "மீண்டும் பதிவு செய்யவும்",
    si: "නැවත ලියාපදිංචි කරන්න",
  },
  january: {
    en: "January",
    ta: "ஜனவரி",
    si: "ජනවාරි",
  },
  february: {
    en: "February",
    ta: "பிப்ரவரி",
    si: "පෙබරවාරි",
  },
  march: {
    en: "March",
    ta: "மார்ச்",
    si: "මාර්තු",
  },
  april: {
    en: "April",
    ta: "ஏப்ரல்",
    si: "අප්රේල්",
  },
  may: {
    en: "May",
    ta: "மே",
    si: "මැයි",
  },
  june: {
    en: "June",
    ta: "ஜூன்",
    si: "ජූනි",
  },
  july: {
    en: "July",
    ta: "ஜூலை",
    si: "ජුලි",
  },
  august: {
    en: "August",
    ta: "ஆகஸ்ட்",
    si: "අගෝස්තු",
  },
  september: {
    en: "September",
    ta: "செப்டம்பர்",
    si: "සැප්තැම්බර්",
  },
  october: {
    en: "October",
    ta: "அக்டோபர்",
    si: "ඔක්තෝම්බර්",
  },
  november: {
    en: "November",
    ta: "நவம்பர்",
    si: "නොවැම්බර්",
  },
  december: {
    en: "December",
    ta: "டிசம்பர்",
    si: "දෙසැම්බර්",
  },
  active: {
    en: "Active",
    ta: "செயலில்",
    si: "ක්‍රියාකාරී",
  },
  locked: {
    en: "Locked",
    ta: "பூட்டப்பட்டது",
    si: "අගුලු දමා ඇත",
  },
  upcoming: {
    en: "Upcoming",
    ta: "வரவிருக்கிறது",
    si: "ඉදිරියට",
  },

  alert_save_success: {
    en: "GoalSaved Successfully",
    ta: "இலக்கு வெற்றிகரமாக சேமிக்கப்பட்டது",
    si: "ඉලක්කය සාර්ථකව සුරැකිණි",
  },

  alert_update_success: {
    en: "Goal Updated Successfully",
    ta: "இலக்கு வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    si: "ඉලක්කය සාර්ථකව යාවත්කාලීන කරන ලදී",
  },

  total: {
    en: "Total No of Children:",
    ta: "மொத்த குழந்தைகளின் எண்ணிக்கை:",
    si: "මුළු දරුවන් සංඛ්යාව:",
  },
};
