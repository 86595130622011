export const ANNUALTARGETFORNATIONAL_PAGE_LABELS = {
  heading: {
    en: "Set Goal",
    ta: "இலக்கை அமைக்கவும்",
    si: "ඉලක්කය සකසන්න",
  },
  select_year: {
    en: "Select Year",
    ta: "ஆண்டைத் தேர்ந்தெடுக்கவும்",
    si: "වසර තෝරන්න",
  },
  select_category: {
    en: "Select Category",
    ta: "பிரிவை தேர்வு செய்க",
    si: "ප්‍රභේදය තෝරන්න",
  },
  select_category_2: {
    en: "Goal (No of Children)",
    ta: "இலக்கு (குழந்தைகளின் எண்ணிக்கை)",
    si: "ඉලක්කය (දරුවන් සංඛ්යාව)",
  },
  month: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },
  category: {
    en: "Category",
    ta: "வகை",
    si: "වර්ගය",
  },
  goal: {
    en: "No of Children",
    ta: "குழந்தைகளின் எண்ணிக்கை",
    si: "ළමුන් සංඛ්යාව",
  },
  generate: {
    en: "Generate",
    ta: "உருவாக்கு",
    si: "උත්පාදනය කරන්න",
  },

  //alerts
  alert_generate_success: {
    en: "Goal Generated successfully.",
    ta: "இலக்கு வெற்றிகரமாக உருவாக்கப்பட்டது.",
    si: "ඉලක්කය සාර්ථකව ජනනය කරන ලදී.",
  },
  alert_generate_error: {
    en: "Error Generating Goal.",
    ta: "இலக்கை உருவாக்குவதில் பிழை.",
    si: "ඉලක්කය ජනනය කිරීමේ දෝෂයකි.",
  },
};
