import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Form, Select } from "antd";
import { PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Card, Input } from "antd";
import { useNavigate } from "react-router-dom";
import getPageLabels from "../../Utils/Label.service";
import { USERROLES_PAGE_LABELS } from "../../Utils/Labels/UserRoles.page.labels";
import { i18n } from "../../App";
import {
  addUserRole,
  addUserRolePermission,
  getPermissions,
  getUserRoles,
  removeUserRolePermission,
} from "../../Api";
import { responseAlert, failureAlert } from "../modules/Alert";
import PermissionsTable from "../modules/PermissionsTable";

function UserRoles() {
  const { language } = useContext(i18n);

  const [form] = Form.useForm();

  const lang = useMemo(
    () => getPageLabels(USERROLES_PAGE_LABELS, language.language),
    [language]
  );

  const [selectedRow, setSelectedRow] = useState(null);

  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedPermissions(
        selectedRow.permissions.map((perm) => perm.permission_id)
      );
    } else {
      setSelectedPermissions([]); // Clear selected permissions if no role is selected
    }
  }, [selectedRow]);

  const navigate = useNavigate();

  const columns = [
    {
      title: lang.role_name,
      dataIndex: "role",
      key: "role",
      fixed: "left",
    },
    {
      title: lang.permission,
      dataIndex: "permissions",
      key: "permissions",
      fixed: "left",
      render: (_, record) =>
        record.permissions.map((perm) => (
          <>
            <a className="login-form-forgot">{perm.permission}</a>&nbsp;
          </>
        )),
    },
    {
      title: lang.action,
      dataIndex: "action",
      key: "action",
      fixed: "left",
      render: (_, record) => (
        <Button onClick={() => setSelectedRow(record)}>{lang.select}</Button>
      ),
    },
  ];

  const handleAddUserRoleClick = () => {
    navigate("/AddNewUserRole");
  };

  const refreshRoles = () => {
    getUserRoles().then((out) => {
      setData(out.data.data);
    });
  };

  const removeUserPermission = (id) => {
    removeUserRolePermission({
      role_id: selectedRow.id,
      permission_id: id,
    }).then((response) => {

      responseAlert(
        response.status,
        language.language,
        "Role permission successfully removed."
      );
      form.resetFields();
      refreshRoles();
      //setSelectedRow(null);

    })
    .catch((error) => {
      // Catch any network errors
      failureAlert(error, language.language);
    });
  };

  const onFinish = (values) => {
    // Handle form submission here

    if (!selectedRow) {
      const body = values;
      delete body.permission;
      if (body.role) {
        addUserRole(body)
          .then((response) => {
            responseAlert(
              response.status,
              language.language,
              lang.alert_save_success
            );
            form.resetFields();
            refreshRoles();
          })
          .catch((error) => {
            // Catch any network errors
            failureAlert(error, language.language);
          });
      } else {
        failureAlert(lang.alert_save_error, language.language);
      }
    } else {
      if (values.permission) {
        //console.log(selectedRow);
        addUserRolePermission({
          role_id: selectedRow.id,
          permission_id: values.permission,
        })
          .then((response) => {
            responseAlert(
              response.status,
              language.language,
              lang.alert_save_success
            );
            form.resetFields();
            refreshRoles();
           // setSelectedRow(null);
          })
          .catch((error) => {
            // Catch any network errors
            failureAlert(error, language.language);
          });
      } else {
        failureAlert(lang.alert_save_success, language.language);
      }
    }
  };
  
  const updateRolePermissions = (permissions,id) => {
    if (permissions.includes(id)) {
      addUserRolePermission({
        role_id: selectedRow.id,
        permission_id: id,
      })
        .then((response) => {
          responseAlert(
            response.status,
            language.language,
            lang.alert_save_success
          );
          form.resetFields();
          refreshRoles();
        })
        .catch((error) => {
          // Catch any network errors
          failureAlert(error, language.language);
        });
    } else {
      removeUserPermission(id);
    }
  };


  useEffect(() => {
    refreshRoles();
    getPermissions().then((res) => {
      const perms = res.data.data;
      setPermissions(
        perms.map((pm) => {
          return { label: pm.permission, value: pm.id };
        })
      );
    });
  }, []);

  return (
    <div>
      <div className="row mt-4 ">
        <div className="col-md-12">
          <Card>
            <Form
              onFinish={onFinish}
              autoComplete="off"
              name="basic"
              layout="vertical"
              form={form}
            >
              <div className="row">
                <div className="col-md-4">
                  <Form.Item label={lang.role_name_label} name="role">
                    <Input
                      placeholder={
                        selectedRow ? selectedRow.role : lang.role_name
                      }
                      disabled={selectedRow}
                    />
                  </Form.Item>
                  {!selectedRow ? (
                    <Button
                      className="w-auto fos-btn-primary d-flex align-items-center"
                      htmlType="submit"
                    >
                      {lang.add_user_role_btn}
                      <PlusOutlined />
                    </Button>
                  ) : null}
                  {selectedRow ? (
                    <Button
                      className="w-auto fos-btn-primary d-flex align-items-center mb-4"
                      onClick={() => setSelectedRow(null)}
                    >
                      {lang.exit}
                      {/* <CloseCircleFilled /> */}
                    </Button>
                  ) : null}
                </div>
                {selectedRow ? (
                //   <div className="col-md-6 fos-dotted-border">
                //     <div className="row">
                //       <div className="col-md-6">
                //         <Form.Item label={lang.permission} name="permission">
                //           <Select
                //             showSearch
                //             placeholder={lang.role_name_placeholder}
                //             options={permissions}
                //           />
                //         </Form.Item>
                //       </div>
                //       <div className="col-md-4 mt-4">
                //         <Button
                //           className="w-auto d-flex align-items-center"
                //           htmlType="submit"
                //         >
                //           {lang.permission_add_name_label}
                //         </Button>
                //       </div>
                //     </div>
                //     <div className="row">
                //       <div className="flex">
                //         {selectedRow.permissions.map((perm) => (
                //           <>
                //             <a href="" className="login-form-forgot">
                //               {perm?.permission}
                //             </a>
                //             &nbsp;&nbsp;
                //             <CloseCircleFilled
                //               style={{ cursor: "pointer" }}
                //               onClick={() =>
                //                 removeUserPermission(perm.permission_id)
                //               }
                //             />
                //           </>
                //         ))}
                //       </div>
                //     </div>
                //   </div>
                // 
                
                // {/* Add the PermissionsTable component here */}
                <div className="col-md-8 ">
                  <div className="row mx-2 fos-dotted-border">
                    <h5 className="px-4 py-2">Permissions</h5>
                    <>
                      {/* Include the PermissionsTable component for selecting new permissions */}
                      <PermissionsTable
                        role={selectedRow?.id}
                        selectedPermissions={selectedRow?.permissions.map(
                          (perm) => perm.permission_id
                        )}
                        allPermissions={permissions}
                        onPermissionChange={(permissions,permission) => {
                          // Handle permission changes her
                          updateRolePermissions(permissions,permission);
                          console.log("onPermissionChange",permissions,permission);
                        }}
                      />
                    </>
                  </div>
                </div> ) : null}
              </div>
            </Form>   
          </Card>   
        </div>
      </div>
      <div className="row mt-4 ">
        <div className="col-md-12">
          <Card>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="fos-heading ml-2">{lang.heading}</div>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
          </Card>
        </div>
      </div> 
    </div>
  );
}

export default UserRoles;
