export const FUNDSRELEASEBYBENEFICIARY_PAGE_LABELS = {
  heading: {
    en: "Funds Released per Beneficiary",
    ta: "ஒரு பயனாளிக்கு விடுவிக்கப்படும் நிதி",
    si: "ප්එක් ප්‍රතිලාභියෙකුට නිකුත් කරන ලද අරමුදල්",
  },
  download_report: {
    en: "Download Report",
    ta: "அறிக்கையைப் பதிவிறக்கவும்",
    si: "වර් තාව බාගත කරන්න",
  },
  child_id: {
    en: "Child ID",
    ta: "குழந்தை ஐடி",
    si: "ළමා හඳුනාගැනීමේ අංකය",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  name: {
    en: "Name",
    ta: "குழந்தையின் பெயர்",
    si: "දරුවාගේ නම",
  },
  category: {
    en: "Category",
    ta: "வகை",
    si: "ප්‍රභේදය",
  },
  account_number: {
    en: "Account Number",
    ta: "கணக்கு எண்",
    si: "ගිණුම් අංකය",
  },
  telephone_number: {
    en: "Telephone Number",
    ta: "தொலைபேசி எண்",
    si: "දුරකථන අංකය",
  },

  semester_month: {
    en: "Semester(Month)",
    ta: "செமஸ்டர் (மாதம்)",
    si: "අධ්‍යයන වාරය (මාසය)",
  },
  remaining_funds: {
    en: "Remaining funds (LKR)",
    ta: "மீதமுள்ள நிதி (LKR)",
    si: "ඉතිරි අරමුදල් (LKR)",
  },
  funds_released: {
    en: "Funds released (LKR)",
    ta: "நிதி வெளியிடப்பட்டது (LKR)",
    si: "නිකුත් කළ අරමුදල් (LKR)",
  },
  to_rise: {
    en: "To Release (LKR)",
    ta: "வெளியிட (LKR)",
    si: "නිදහස්කිරීමට (LKR)",
  },
};
