import React, { useReducer,useMemo } from 'react';
import { Layout, Menu, Button, theme } from 'antd';
import 'bootstrap/dist/css/bootstrap.css';

import AppRouter from './AppRouter';


import {LANGUAGE_CODE} from "./Utils/Constants";

const { Header, Sider, Content } = Layout;

const items = [
  {
    label: 'Value 1',
    key: '1',

  },
  {
    label: 'Value 2',
    key: '2',

  },

];

const handleMenuClick = (e) => {

  console.log('click', e);
};
const menuProps = {
  items,
  onClick: handleMenuClick,
};

export const i18n = React.createContext({
  language: LANGUAGE_CODE.EN, dispatch: () => {}
})

const languageReducer = (state, action) => {
  const {type} = action

  switch (type.type) {
      case "si":
          return {...state, language: LANGUAGE_CODE.SI}
      case "ta":
          return {...state, language: LANGUAGE_CODE.TA}
      case "en":
          return {...state, language: LANGUAGE_CODE.EN}
      default:
          return state
  }
}

const App = () => {
  const [langState, dispatch] = useReducer(languageReducer, {language: sessionStorage.getItem("lang")??LANGUAGE_CODE.EN})

  const i18nDispatch = useMemo(() => ({
      dispatch: (language) => {
          dispatch({type: language})
      }
  }), [])

  return (
    <i18n.Provider value={{language: langState, dispatch: i18nDispatch}}>
      <AppRouter />
    </i18n.Provider>
  );
};

export default App;
