export const SETFUNDSRELEASEDATE_PAGE_LABELS = {
  heading: {
    en: "Funds Release",
    ta: "நிதி வெளியீடு",
    si: "අරමුදල් නිකුත් කිරීම",
  },
  info_1: {
    en: "This setting allows you to release funds to the children within the system (This setting is only applicable within the system and does not reflect real world transactions).",
    ta: "இந்த அமைப்பானது கணினியில் உள்ள குழந்தைகளுக்கு நிதியை வெளியிட உங்களை அனுமதிக்கிறது (இந்த அமைப்பு கணினியில் மட்டுமே பொருந்தும் மற்றும் நிஜ உலக பரிவர்த்தனைகளை பிரதிபலிக்காது).",
    si: "මෙම සැකසුම මඟින් පද්ධතිය තුළ දරුවන්ට අරමුදල් මුදා හැරීමට ඔබට ඉඩ සලසයි (මෙම සැකසුම පද්ධතිය තුළ පමණක් අදාළ වන අතර සැබෑ ලෝක ගනුදෙනු පිළිබිඹු නොකරයි).",
  },
  next_fund_release_date: {
    en: "Last Fund Released Date",
    ta: "அடுத்த நிதி வெளியீட்டு தேதி",
    si: "ඊළඟ අරමුදල් මුදා හැරීමේ දිනය",
  },
  no_children_recieve_funds: {
    en: "Number Of Children who will Receive Funds",
    ta: "நிதி பெறும் குழந்தைகளின் எண்ணிக்கை",
    si: "මුදල් ලබන දරුවන්ගේ සංඛ්යාව",
  },
  no_children_recieved_funds: {
    en: "Number Of Children who Received Funds",
    ta: "நிதியைப் பெற்ற குழந்தைகளின் எண்ணிக்கை",
    si: "අරමුදල් ලැබුණු දරුවන් සංඛ්යාව",
  },
  current_select_date: {
    en: "Date Today",
    ta: "தற்போதைய தேர்வு நாள் (1 முதல் 28 வரை)",
    si: "වත්මන් තේරීම් දිනය (1 සිට 28 දක්වා)",
  },
  day_of_every_month: {
    en: "09 Day of every month",
    ta: "ஒவ்வொரு மாதமும் 09 நாள்",
    si: "සෑම මසකම දින 09",
  },
  edit: {
    en: "Edit",
    ta: "தொகு",
    si: "සංස්කරණය කරන්න",
  },
  save: {
    en: "Save",
    ta: "சேமிக்கவும்",
    si: "ගබඩා කරන්න",
  },
  year: {
    en: "Year",
    ta: "ஆண்டு",
    si: "වසර",
  },
  month: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },

  //alerts

  alert_save_success: {
    en: "Funds Release Date Successfully Set.",
    ta: "நிதி வெளியீட்டு தேதி வெற்றிகரமாக அமைக்கப்பட்டது.",
    si: "අරමුදල් මුදා හැරීමේ දිනය සාර්ථකව සකසා ඇත.",
  },
  alert_save_error: {
    en: "Error Setting Funds Release Date.",
    ta: "நிதி வெளியீட்டு தேதியை அமைப்பதில் பிழை.",
    si: "අරමුදල් මුදා හැරීමේ දිනය සැකසීමේ දෝෂයකි.",
  },
  alert_update_success: {
    en: "Funds Release Date Updated Successfully.",
    ta: "நிதி வெளியீட்டு தேதி வெற்றிகரமாக புதுப்பிக்கப்பட்டது.",
    si: "අරමුදල් මුදා හැරීමේ දිනය සාර්ථකව යාවත්කාලීන කරන ලදී.",
  },
  alert_create_success: {
    en: "Error Updating Funds Release Date.",
    ta: "நிதி வெளியீட்டுத் தேதியைப் புதுப்பிப்பதில் பிழை.",
    si: "අරමුදල් මුදා හැරීමේ දිනය යාවත්කාලීන කිරීමේ දෝෂයකි.",
  },
  release_funds: {
    en: "Release Funds",
    ta: "நிதியை விடுவிக்கவும்",
    si: "අරමුදල් නිදහස් කරන්න"
  }
};
