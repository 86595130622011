export const USER_FORM_RULE_MESSAGE = {
  user_name: {
    required: {
      en: "Please enter your Username!",
      ta: "தயவுசெய்து உங்கள் பயனர்பெயரை உள்ளிடவும்!",
      si: "කරුණාකර ඔබගේ පරිශීලක නාමය ඇතුලත් කරන්න!",
    },
  },

  name: {
    required: {
      en: "Please enter your Name!",
      ta: "உங்களுடைய பெயரை பதிவு செய்யவும்!",
      si: "ඔබේ නම ඇතුලත් කරන්න!",
    },
  },

  email: {
    required: {
      en: "Please enter your email!",
      ta: "உங்கள் மின்னஞ்சலை உள்ளிடவும்!",
      si: "කරුණාකර ඔබේ විද්යුත් තැපැල් ලිපිනය ඇතුළත් කරන්න!",
    },
    pattern: {
      en: "Please enter a valid email address!",
      ta: "செல்லுபடியாக ஒரு செயல்முறை மின்னஞ்சல் முகவரியை உள்ளிடவும்!",
      si: "කරුණාකර වලංගු විද්යුත් තැපැල් ලිපිනයක් ඇතුළත් කරන්න!",
    },
  },

  password: {
    required: {
      en: "Please enter your password!",
      ta: "உங்கள் கடவுச்சொல்லை உள்ளிடவும்!",
      si: "කරුණාකර ඔබේ මුරපදය ඇතුළත් කරන්න!",
    },
    min: {
      en: "Password must be at least 8 characters long.",
      ta: "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.",
      si: "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් දිග විය යුතුය.",
    },
  },
  confirm_password: {
    required: {
      en: "Please confirm your password!",
      ta: "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்!",
      si: "කරුණාකර ඔබේ මුරපදය තහවුරු කරන්න!",
    },
    min: {
      en: "Password must be at least 8 characters long.",
      ta: "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.",
      si: "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් දිග විය යුතුය.",
    },
  },
  user_roles: {
    required: {
      en: "Please select a User Role!",
      ta: "ஒரு பயனர் பாத்திரத்தைத் தேர்ந்தெடுக்கவும்!",
      si: "කරුණාකර පරිශීලක භූමිකාවක් තෝරන්න!",
    },
  },
  area_type: {
    required: {
      en: "'Area Type' is a required field!",
      ta: "'பகுதி வகை' ஒரு தேவையான புலம்!",
      si: "'ප්‍රදේශයේ වර්ගය' අවශ්‍ය ක්ෂේත්‍රයකි!",
    },
  },
};
