import { Layout } from "antd";
import SideBar from "./Components/base/sidebar";
import AppHeader from "./Components/base/header";
import { Content } from "antd/es/layout/layout";

const AppLayout = ({ children }) => (
    <Layout>
        <SideBar />
        <Layout>
            <AppHeader />
            <Content style={{ margin: '24px 16px', padding: 24, minHeight: '90vh' }}>
                {children}
            </Content>
        </Layout>
    </Layout >
)

export default AppLayout;