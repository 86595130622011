export const TARGETCHART_PAGE_LABELS = {
  enrolled: {
    en: "Enrolled",
    ta: "பதிவு செய்யப்பட்டது",
    si: "ලියාපදිංචි වූ ",
  },
  pending: {
    en: "Pending",
    ta: "நிலுவையில் உள்ளது",
    si: "පොරොත්තුවෙන්",
  },
  funding: {
    en: "Funding",
    ta: "நிதியுதவி",
    si: "මූල්යකරණය",
  },
  complete: {
    en: "Complete",
    ta: "முழுமை",
    si: "සම්පූර්ණ",
  },
  excluded: {
    en: "Excluded",
    ta: "விலக்கப்பட்டது",
    si: "බැහැර කළ",
  },
  deceased: {
    en: "Deceased",
    ta: "இறந்தார்",
    si: "මිය ගිය",
  },

  heading: {
    en: "Child count by Enrollment Status",
    ta: "மாதவாரியாக புதிய குழந்தை பதிவுகள் (எண்ணிக்கை)",
    si: "මාසය අනුව නව ළමා ලියාපදිංචි කිරීම් (ගණනය)",
  },
};
