
import { Table, Checkbox } from "antd";
import React, { useState,useEffect } from "react";

const PermissionsTable = (props) => {

  const { role, selectedPermissions, allPermissions, onPermissionChange } = props;
  const [checkedPermissions, setCheckedPermissions] = useState(selectedPermissions);
  const [roleId, setRole] = useState(null);

  useEffect(() => {
    if(roleId !==  role){
      setCheckedPermissions(selectedPermissions);
      setRole(role);
    }
  }, [roleId,selectedPermissions,role]);
    


  const handlePermissionChange = (permissionId) => {
    let updatedPermissions;

    if (checkedPermissions.includes(permissionId)) {
      // If the permission is already checked, uncheck it
      updatedPermissions = checkedPermissions.filter((value) => value !== permissionId);
    } else {
      // If the permission is not checked, check it
      updatedPermissions = [...checkedPermissions, permissionId];
    }

    setCheckedPermissions(updatedPermissions);
    onPermissionChange(updatedPermissions,permissionId);
  };
  
  const columns = [
    {
      title: "#",
      dataIndex: "value",
      key: "id",
      render: (record) => (
        <Checkbox
            checked={checkedPermissions.includes(record)}
            onChange={() => handlePermissionChange(record)}
          >
          </Checkbox>
      ),
    },
    {
      title: "Permission",
      dataIndex: "label",
      key: "name",
    },
    
  ];

  

  return <Table columns={columns} dataSource={allPermissions} />;
};

export default PermissionsTable;
