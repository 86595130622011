export const DIVERT_FUNDS_PAGE_LABELS = {
    divert_donations: {
      en: "Divert Donations",
      ta: "நன்கொடைகளை திசை திருப்புங்கள்",
      si: "පරිත්‍යාග වෙනතකට යොමු කරන්න",
    },
    enter_divert_amount: {
      en: "Confirm Divertion of Funds",
      ta: "திசைதிருப்ப வேண்டிய தொகையை உள்ளிடவும்",
      si: "හරවා යැවීමට මුදල ඇතුළත් කරන්න",
    },
    total_donation: {
      en: "Amount to Divert",
      ta: "திசை திருப்ப வேண்டிய தொகை",
      si: "හරවා යැවීමට ඇති මුදල",
    },
    remaining: {
      en: "Remaining",
      ta: "மீதியில்",
      si: "ඉතිරි",
    },
    child_public_id: {
      en: "Child Public ID",
      ta: "குழந்தை பொது ஐடி",
      si: "පොදු ළමා හඳුනාගැනීමේ අංකය",
    },
    received_amount: {
      en: "Received Amount",
      ta: "பெற்ற தொகை",
      si: "ලබාගත් මුදල",
    },
    receivable_amount: {
      en: "Receivable Amount",
      ta: "பெற வேண்டிய தொகை",
      si: "ලැබීමට ඇති මුදල",
    },
    diverted_amount: {
      en: "Diverted Amount",
      ta: "மாற்றப்பட்ட தொகை",
      si: "හරවා යැවූ මුදල",
    },
    enter_amount: {
      en: "Amount Receiving (LKR)",
      ta: "பெறும் தொகை (LKR)",
      si: "ලැබෙන මුදල (LKR)",
    },
    alert_amountN: {
      en: "Amount required",
      ta: " தேவையான தொகை",
      si: "මුදල ඇතුලත් කරන්න",
    },
    alert_amount_notenough: {
      en: "Don't have enough amount left",
      ta: "போதுமான தொகை மிச்சமில்லை",
      si: "ප්‍රමාණවත් මුදලක් ශේෂ වී නොමැත",
    },
    alert_amount_greater: {
      en: "Amount is greater than receivable amount",
      ta: "பெறத்தக்க தொகையை விட தொகை அதிகமாக உள்ளது",
      si: "ලැබිය යුතු මුදලට වඩා බැර කරන මුදල වැඩිය",
    },
    alert_process_done: {
      en: "Donation Successful",
      ta: "நன்கொடை வெற்றி",
      si: "පරිත්‍යාගය සාර්ථකයි",
    },
    action: {
      en: "Action",
      ta: "நடவடிக்கை",
      si: "ක්‍රියාව",
    },
    done_btn: {
      en: "Done",
      ta: "முடிந்தது",
      si: "අවසන් කරන්න",
    },
    divert_btn: {
      en: "To divert",
      ta: "திசைதிருப்ப",
      si: "හැරවීමට",
    },
    full_name: {
      en: "Full Name",
      ta: "முழு பெயர்",
      si: "සම්පූර්ණ නම",
    },
    system_id: {
      en: "System ID",
      ta: "கணினி ஐடி",
      si: "පද්ධති හඳුනාගැනීමේ අංකය",
    },
    public_id: {
      en: "Public ID",
      ta: "பொது ஐடி",
      si: "පොදු හඳුනාගැනීමේ අංකය",
    },
    age: {
      en: "Age",
      ta: "வயது",
      si: "වයස",
    },
    moh_area: {
      en: "MOH Area",
      ta: "MOH பகுதி",
      si: "MOH ප්‍රදේශය",
    },
    current_amount: {
      en: "Recieved Amount (LKR)",
      ta: "தற்போதைய தொகை (LKR)",
      si: "වත්මන් මුදල (LKR)",
    },
    due_amount: {
      en: "Recievable Amount (LKR)",
      ta: "நிலுவை தொகை (LKR)",
      si: "නියමිත මුදල (LKR)",
    },
    status: {
      en: "Status",
      ta: "நிலை",
      si: "තත්ත්වය",
    },
  
  };
  