import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal, Tooltip } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Card } from "antd";
import getPageLabels from "../../Utils/Label.service";
import { ALLDONORS_PAGE_LABELS } from "../../Utils/Labels/AllDonors.page.labels";
import { i18n } from "../../App";
import { Toast } from "../modules/Alert";
import { activateDeactivateDonor, getDonors } from "../../Api";
import TableFilter from "../form/TableFilter";

function AllDonors() {
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(ALLDONORS_PAGE_LABELS, language.language),
    [language]
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleActionClick = (rowKey) => {
    const selectedRecord = data.find((record) => record.key === rowKey);
    setSelectedRow(selectedRecord);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };

  const renderActions = (record) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {record.active == 1 ? <Tooltip title={lang.activate} placement="bottom">
          <Button
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#52c41a",
              color: "#FFFFFF",
              borderRadius: "5px",
              width: "38px",
              height: "38px",
              margin: "0 5px",
            }}
            onClick={() => activate(record.id)}
          >
            <CheckOutlined style={{ fontSize: "18px", color: "#FFFFFF" }} />
          </Button>
        </Tooltip> : null}
        {record.active == 0 ? <Tooltip title={lang.deactivate} placement="bottom">
          <Button
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#ff4f4f",
              color: "#FFFFFF",
              borderRadius: "5px",
              width: "38px",
              height: "38px",
              margin: "0 5px",
            }}
            onClick={() => deactivate(record.id)}
          >
            <CloseOutlined style={{ fontSize: "18px", color: "#FFFFFF" }} />
          </Button>
        </Tooltip> : null}
      </div>
    );
  };

  const columns = [
    {
      title: lang.donor_id,
      dataIndex: "id",
      search: true,
      key: "id",
      fixed: "left",
    },
    {
      title: lang.firstname,
      dataIndex: "first_name",
      search: true,
      key: "first_name",
      fixed: "left",
    },
    {
      title: lang.lastname,
      dataIndex: "last_name",
      search: true,
      key: "last_name",
    },
    {
      title: lang.email,
      dataIndex: "email",
      search: true,
      key: "email",
    },
    {
      title: lang.status,
      dataIndex: "active",
      key: "2",
      render: (_, record) => (
        <div
          className={record.active == 0 ? "fos-lable-green" : "fos-lable-red"}
        >
          {record.active == 0 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      title: lang.action,
      key: "operation",
      fixed: "right",
      width: 200,
      render: (_, record) => renderActions(record),
    },
  ];

  const refresh = () => {
    getDonors()
      .then((out) => {
        setData(out.data.data);
        setFilteredData(out.data.data); // Initialize filteredData with all data
      })
      .catch((e) => {
        console.log("CATCH", e);
      });
  }

  const activate = (id) => {
    activateDeactivateDonor({ id, status: 0 })
      .then((out) => {
        refresh();
        Toast.fire({
          icon: "success",
          title: lang.alert_activate_success,
        });
      })
  };
  const deactivate = (id) => {
    activateDeactivateDonor({ id, status: 1 })
      .then((out) => {
        refresh();
        Toast.fire({
          icon: "success",
          title: lang.alert_deactivate_success,
        });
      })
  };

  useEffect(() => {
    refresh();
  }, []);

  // Function to handle filtering based on user input
  const handleFilter = ({ column, searchText }) => {
    if (!column || !searchText) {
      // If either column or searchText is not provided, reset the filteredData to all data
      setFilteredData(data);
      return;
    }

    // Implement your filtering logic here
    const filtered = data.filter((item) => {
      const cellValue = item[column] || ""; // Get the cell value for the selected column
      return cellValue
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    setFilteredData(filtered);
  };

  return (
    <div>
      <div className="row mt-4 ">
        <div className="col-md-12">
          <Card>
            <div className="d-flex align-items-center justify-content-between">
              <div className="fos-heading ml-2">{lang.heading}</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div></div>
              <div>
                <div className="mt-1">
                  {/* Render the TableFilter component */}
                  <TableFilter columns={columns} onFilter={handleFilter} />
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
            {selectedRow && (
              <Modal
                title={
                  <div className="fos-heading text-center">{lang.action}</div>
                }
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={
                  [
                    // <Button key="close" onClick={handleModalClose}>
                    //   Close
                    // </Button>,
                  ]
                }
              >
                <div className="d-flex justify-content-between mt-4">
                  <Button className="fos-btn-primary" onClick={() => deactivate(selectedRow.id)}>
                    {lang.deactivate}{" "}
                  </Button>
                </div>
              </Modal>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AllDonors;
