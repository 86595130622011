import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, Card } from 'antd';
import getPageLabels from "../../Utils/Label.service";
import { MANAGEDASHBOARD_PAGE_LABELS } from "../../Utils/Labels/ManageDashboard.page.labels";
import { i18n } from "../../App";
import { deleteBanner, getBanners, uploadBanner } from '../../Api';
import { responseAlert, failureAlert } from "../modules/Alert";
import { ASSET_URL } from "../../Api/index";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ManageDashboard = () => {

    const { language } = useContext(i18n);

    const lang = useMemo(
        () => getPageLabels(MANAGEDASHBOARD_PAGE_LABELS, language.language),
        [language]
    );

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const GetBanners = () => {
        getBanners().then(out => {
            const banners = out.data.data;
            const bout = banners.map((banner) => {
                return {
                    uid: banner?.id,
                    name: banner?.img,
                    status: 'done',
                    url: `${ASSET_URL}/banners/${banner?.img}`,
                }
            });
            setFileList(bout);
        })
    }
    const handleChange = ({ fileList: newFileList, file }) => {
        const data = new FormData();
        data.append('banner', file ? file.originFileObj : null);

        uploadBanner(data)
            .then((response) => {
                responseAlert(response.status, language.language, lang.alert_update_success);
                GetBanners();
            })
            .catch((error) => {
                failureAlert(error, language.language);
            });
    };

    const handleRemove = (data) => {
        deleteBanner({ id: data.uid }).then(res => {
            responseAlert(res.status, language.language, lang.alert_update_success);
            GetBanners();
        })
        .catch((error) => {
            failureAlert(error, language.language);
        });
        return false;
    }

    useEffect(() => {
        GetBanners();
    }, []);
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {lang.upload_btn}
            </div>
        </div>
    );
    return (
        <>
            <Card className="p-2">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex justify-content-between">
                            <div className="fos-card-heading font-bold">
                                {lang.heading}
                            </div>
                        </div>
                        <br></br>
                        {lang.imagesize}
                        <Upload className='mt-5'
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            onRemove={handleRemove}
                        >
                            {fileList.length >= 4 ? null : uploadButton}
                        </Upload>
                        {/* {fileList.map((file) => {
                            return <img src={file.url} />
                        })} */}
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                </div>
            </Card>
        </>
    );
};
export default ManageDashboard;