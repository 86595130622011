import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Modal, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown, Space, Card, Input } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import getPageLabels from "../../Utils/Label.service";
import { ALLUSERS_PAGE_LABELS } from "../../Utils/Labels/AllUsers.page.labels";
import { i18n } from "../../App";
import { activateDeactivateUser, getUsers } from "../../Api";
import { Toast } from "../modules/Alert";

const items = [
  {
    label: <div>Year</div>,
    key: "0",
  },
  {
    label: <div>Month</div>,
    key: "1",
  },

  {
    label: <div>Weak</div>,
    key: "3",
  },
];

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    userName: "Kasun",
    userRole: "MOH",
    Month: `Kaluthara`,
    Amount: 435.54,
    Status: <div className="fos-success ">success</div>,
  });
}
const data1 = [
  {
    District: "Colombo",
    Goal: <Input placeholder="Goal" />,
  },
];

function AllUsers() {
  const navigate = useNavigate();

  const { language } = useContext(i18n);
  const [data, setData] = useState([]);

  const lang = useMemo(
    () => getPageLabels(ALLUSERS_PAGE_LABELS, language.language),
    [language]
  );

  const refresh = () => {
    getUsers()
      .then((out) => {
        if (out.status == 200) {
          const mapped = out.data.data.map((itm, index) => {
            //      console.log(data);
            return { ...itm, key: index };
          });
          setData(mapped);
        }
      })
      .catch((e) => {
        console.log("CATCH", e);
      });
  }

  useEffect(() => {
    refresh();
  }, []);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleActionClick = (rowKey) => {
    const selectedRecord = data.find((record) => record.key === rowKey);
    setSelectedRow(selectedRecord);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };

  const renderActions = (record) => {
    return (
      <div className="d-flex justify-content-start mt-4">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-30px",
          }}
        >
          {record.status != 0 ?
            <Tooltip title={lang.activate} placement="bottom">
              <Button
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#52c41a",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  width: "40px",
                  height: "40px",
                  marginTop: "6px",
                }}
                onClick={() => activate(record.username)}
              >
                <CheckOutlined style={{ fontSize: "18px", color: "#FFFFFF" }} />
              </Button>
            </Tooltip>
            :
            <Tooltip title={lang.deactivate} placement="bottom">
              <Button
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#ff4f4f",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  width: "40px",
                  height: "40px",
                  marginLeft: "10px",
                  marginTop: "6px",
                }}
                onClick={() => deactivate(record.username)}
              >
                <CloseOutlined style={{ fontSize: "18px", color: "#FFFFFF" }} />
              </Button>
            </Tooltip>
          }
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: lang.username,
      dataIndex: "username",
      key: "username",
      fixed: "left",
    },
    {
      title: lang.email,
      dataIndex: "email",
      key: "email",
    },
    {
      title: lang.name,
      dataIndex: "name",
      key: "name",
    },
    {
      title: lang.area_type,
      dataIndex: "area_type",
      key: "area_type",
    },
    {
      title: lang.user_role,

      dataIndex: "role",
      key: "role",
      fixed: "left",
    },
    {
      title: lang.status,
      dataIndex: "status",
      key: "status",
      render: (_,rec) => {
        return <div
          className={rec?.status == 0 ? "fos-lable-green" : "fos-lable-red"}
        >
          {rec?.status == 0 ? "Active" : "Inactive"}
        </div>
      }
    },

    {
      title: lang.action,
      key: "operation",
      fixed: "right",
      width: 200,
      render: renderActions,
    },
  ];
  const columns1 = [
    {
      title: "User Name",

      dataIndex: "username",
      key: "name",
      fixed: "left",
    },
    {
      title: "Goal",

      dataIndex: "Goal",
      key: "age",
      fixed: "left",
    },
  ];

  const handleAddNewUserClick = () => {
    navigate("/AddNewUser");
  };
  const onFinish1 = (values) => {
    // Handle form submission here
    console.log("Form values:", values);

    Toast.fire({
      icon: "success",
      title: lang.alert_push_to_funding_list_success,
    });
  };
  const activate = (username) => {
    activateDeactivateUser({ username, status: 0 })
      .then((out) => {
        refresh();
        Toast.fire({
          icon: "success",
          title: lang.alert_activate_success,
        });
      })
  };
  const deactivate = (username) => {
    activateDeactivateUser({ username, status: 1 })
      .then((out) => {
        refresh();
        Toast.fire({
          icon: "success",
          title: lang.alert_deactivate_success,
        });
      })
  };

  return (
    <div>
      <div className="row mt-4 ">
        <div className="col-md-12">
          <Card>
            <div className="d-flex align-items-center justify-content-between">
              <div className="fos-heading ml-2">{lang.heading}</div>
              <Button
                className="w-auto fos-btn-primary d-flex align-items-center"
                onClick={handleAddNewUserClick}
              >
                {lang.add_user_btn}
                <PlusOutlined />
              </Button>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 150 }}
              sticky
              className="mt-3"
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
