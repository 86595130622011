import React, { useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { Layout } from "antd";
import SideBar from "./Components/base/sidebar";
import AppHeader from "./Components/base/header";
import { Content } from "antd/es/layout/layout";
import { allRoutes } from "./Constants";
import NotFound from "./Components/Pages/404";
import Loader from "./Components/modules/Loader";
import Login from "./Pages/Login";
import AppLayout from "./AppLayout";
import PublicDashboard from "./Pages/PublicDashboard";
import AdminLogin from "./Pages/AdminLogin";
import Register from "./Pages/Register";
import Home from "./Components/Pages/Home";
import PrivateRoutes from "./PrivateRoutes";
import Beneficiaries from "./Components/Pages/Benificiaries";
import AllDonations from "./Components/Pages/AllDonations";
import DonorRoutes from "./DonorRoutes";
import DonorDashboard from "./Components/Donor Module/DonorDashboard";
import MakeDonation from "./Components/Donor Module/MakeDonation";
import DonorDonation from "./Components/Donor Module/DonorDonation";
import AccountSetting from "./Components/Pages/AccountSetting";
import DonationDetails from "./Components/Pages/DonationDetails";
import MonthlyTargetForDestrict from "./Components/Pages/MonthlyTargetForDistrict";
import MonthlyTargetForCategory from "./Components/Pages/MonthlyTargetForCategory";
import AnnulTargetForNational from "./Components/Pages/AnnulTargetForNational";
import SetFundsReleaseDate from "./Components/Pages/SetFundsReleaseDate";
import DonationRecievedByBenificiary from "./Components/Pages/DonationRecievedByBenificiary";
import DonationRecievedByCategory from "./Components/Pages/DonationRecievedByCategory";
import DonationRecievedByDonor from "./Components/Pages/DonationRecievedByDonor";
import FundsReleaseByBenificiary from "./Components/Pages/FundsReleaseByBenificiary";
import DonationRecievedByDistrict from "./Components/Pages/DonationRecievedByDistrict";
import DonationTargetByDistrict from "./Components/Pages/DonationTargetByDistrict";
import AllUsers from "./Components/Pages/AllUsers";
import AllDonors from "./Components/Pages/AllDonors";
import UserRoles from "./Components/Pages/UserRoles";
import AddNewUser from "./Components/Pages/AddNewUser";
import Area from "./Components/Pages/Area";
import ActivityLog from "./Components/Pages/ActivityLog";
import ManageDashboard from "./Components/Pages/ManageDashboard";
import DivertFunds from "./Components/Pages/DivertFunds";
import DonationConfirmation from "./Components/Donor Module/DonationConfirmation";
import Forgot from "./Pages/Forgot";
import Pay from "./Pages/Pay";
import Certificate from "./Pages/Certificate";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicDashboard />} path="/" exact />
        <Route element={<Login />} path="/login" exact />
        <Route element={<Forgot />} path="/forgotpassword" exact />
        <Route element={<AdminLogin />} path="/admin_login" exact />
        <Route element={<Register />} path="/register" exact />
        <Route element={<Pay />} path="/pay/:session" exact />
        <Route element={<Certificate />} path="/certificate/:id" exact />
        <Route element={<PrivateRoutes />}>
          <Route element={<Home />} path="/home" exact />
          <Route element={<Beneficiaries />} path="/beneficiaries" exact />
          {/* Donation Module */}
          <Route element={<AllDonations />} path="/allDonations" exact />
          <Route element={<DonationDetails />} path="/donationDetails/:donation_id" exact />
          <Route element={<MonthlyTargetForDestrict />} path="/monthlyGoalForDistrict" exact />
          <Route element={<MonthlyTargetForCategory />} path="/monthlyGoalForCategory" exact />
          <Route element={<AnnulTargetForNational />} path="/annualGoalForNational" exact />
          <Route element={<SetFundsReleaseDate />} path="/setFundsReleaseDate" exact />
          {/* Reports */}
          <Route element={<DonationRecievedByBenificiary />} path="/donationRecievedByBenificiary" exact />
          <Route element={<DonationRecievedByCategory />} path="/donationRecievedByCategory" exact />
          <Route element={<DonationRecievedByDonor />} path="/donationRecievedByDonor" exact />
          <Route element={<FundsReleaseByBenificiary />} path="/fundsReleaseByBenificiary" exact />
          <Route element={<DonationRecievedByDistrict />} path="/donationRecievedByDistrict" exact />
          <Route element={<DonationTargetByDistrict />} path="/donationGoalByDistrict" exact />
          {/* Users */}
          <Route element={<AllUsers />} path="/allUsers" exact />
          <Route element={<AllDonors />} path="/allDonors" exact />
          <Route element={<UserRoles />} path="/userRoles" exact />
          <Route element={<AddNewUser />} path="/addNewUser" exact />
          {/* Meta */}
          <Route element={<Area />} path="/area" exact />
          <Route element={<ActivityLog />} path="/activityLog" exact />
          <Route element={<ManageDashboard />} path="/manageDashboard" exact />
          <Route element={<DivertFunds />} path="/divert/:beneficiary_uid" exact />
        </Route>
        <Route element={<DonorRoutes />}>
          <Route element={<DonorDashboard />} path="/donorDashboard" exact />
          <Route element={<MakeDonation />} path="/makeDonation" exact />
          <Route element={<DonationConfirmation />} path="/makeDonation/:donation_id" exact />
          <Route element={<DonorDonation />} path="/myDonations" exact />
          <Route element={<AccountSetting />} path="/settings" exact />
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  )
}
export default AppRouter;