import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { Card } from "antd";
import { DatePicker } from "antd";

import FormSelect from "../form/FormSelect";

import getPageLabels from "../../Utils/Label.service";
import { ADDNEWUSER_PAGE_LABELS } from "../../Utils/Labels/AddNewUser.page.labels";
import { i18n } from "../../App";
import { responseAlert, failureAlert } from "../modules/Alert";
import UserRules from "./validations/user_validation_rules";
import { addUser, getOrgUnits, getUserRoles } from "../../Api";

const items = [
  {
    label: "Value 1",
    key: "1",
  },
  {
    label: "Value 2",
    key: "2",
  },
];

const { RangePicker } = DatePicker;

function AddNewUser() {
  const [role, setRole] = useState(0);
  const [area, setArea] = useState(0);

  const [roles, setRoles] = useState([]);
  const [orgUnits, setOrgUnits] = useState([]);

  const { language } = useContext(i18n);
  const rules = UserRules();
  const lang = useMemo(
    () => getPageLabels(ADDNEWUSER_PAGE_LABELS, language.language),
    [language]
  );
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onFinish = (values) => {
    // Handle form submission here
    values.area_type = 1;

    // Check if values.user_role is an array before mapping
    if (values.user_role && Array.isArray(values.user_role)) {
      values.user_role = values.user_role.map((value) => Number(value));
    }

    addUser(values)
      .then((response) => {
        console.log(values);
        responseAlert(
          response.status,
          language.language,
          lang.alert_save_success
        );
      })
      .catch((error) => {
        // Catch any network errors
        failureAlert(error, language.language);
      });
  };

  useEffect(() => {
    getUserRoles().then((out) => {
      const roles = out.data.data.map((itm) => {
        return { value: itm.id, text: itm.role };
      });
      setRoles(roles);
    });
    getOrgUnits().then((out) => {
      const orgs = out.data.data;
      console.log("ORGS", orgs);
      setOrgUnits(
        orgs.map((org) => {
          return {
            value: org.id,
            text: org.phm + "(" + org.rdhs + "/" + org.moh + ")",
          };
        })
      );
    });
  }, []);

  return (
    <div>
      <div className="">
        <div className="">
          <div>
            <Card className="p-2">
              <Form
                onFinish={onFinish}
                autoComplete="off"
                name="basic"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}

                layout="vertical"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <div className="fos-card-heading font-bold">
                        {lang.heading}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <Form.Item
                              label={lang.username_lable}
                              name="username"
                              rules={Object.values(rules?.user_name ?? {})}
                            >
                              <Input
                                prefix={
                                  <UserOutlined className="site-form-item-icon" />
                                }
                                placeholder={lang.username_placeholder}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item
                              label={lang.name_lable}
                              name="name"
                              rules={Object.values(rules?.name ?? {})}
                            >
                              <Input placeholder={lang.name_lable} />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item
                              label={lang.email_lable}
                              name="email"
                              rules={Object.values(rules?.email ?? {})}
                            >
                              <Input placeholder={lang.email_lable} />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Form.Item
                              label={lang.password_label}
                              name="password"
                              rules={Object.values(rules?.password ?? {})}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={lang.password_placeholder}
                              />
                            </Form.Item>
                            {/* <div className="fos-label mt-2">Password</div>
                            <Input
                              prefix={
                                <LockOutlined className="site-form-item-icon" />
                              }
                              type="password"
                              placeholder="Password"
                            /> */}
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label={lang.confirmpassword_label}
                              name="confirm_password"
                              rules={Object.values(
                                rules?.confirm_password ?? {}
                              )}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={lang.confirmpassword_placeholder}
                              />
                            </Form.Item>
                            {/* <div className="fos-label mt-2">
                              Confirm Password
                            </div>
                            <Input
                              prefix={
                                <LockOutlined className="site-form-item-icon" />
                              }
                              type="password"
                              placeholder="Re Enter Password"
                            /> */}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Form.Item
                              label={lang.select_user_role_label}
                              name="user_role"
                              rules={Object.values(rules?.user_roles ?? {})}
                            >
                              <Select>
                                {roles.map((item) => (
                                  <Select.Option
                                    key={item.value}
                                    value={Number(item.value)}
                                  >
                                    {item.text}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>

                          {/* <div className="fos-label mt-2">
                                Select User Role
                              </div>
                              <div className="mt-1">
                                <Dropdown menu={menuProps}>
                                  <Button>
                                    <Space>
                                      MOH
                                      <DownOutlined />
                                    </Space>
                                  </Button>
                                </Dropdown>
                              </div> */}
                          <div className="col-md-6">
                            <div>
                              <Form.Item name="areas" label="Area">
                                <Select
                                  mode="multiple"
                                  rules={Object.values(rules?.area_type ?? {})}
                                  options={orgUnits.map((item) => {
                                    return {
                                      label: item.text,
                                      value: item.value,
                                    };
                                  })}
                                />
                              </Form.Item>
                              {/* <div className="fos-label mt-2">Select Area</div>
                              <div className="mt-1">
                                <Dropdown menu={menuProps}>
                                  <Button>
                                    <Space>
                                      Panadura MOH
                                      <DownOutlined />
                                    </Space>
                                  </Button>
                                </Dropdown>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6 d-flex align-items-end ">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="fos-btn-primary mb-1 ml-4"
                            // onClick={onFinish}
                          >
                            {lang.save_btn}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUser;
