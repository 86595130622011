import React, { useContext, useMemo, useEffect, useState } from "react";

import { Table, Button, Form, Input, Avatar, Card, Select, } from "antd";

import { i18n } from "../../App";
import getPageLabels from "../../Utils/Label.service";
import { DIVERT_FUNDS_PAGE_LABELS } from "../../Utils/Labels/DivertFunds.page.labels";
import { useParams } from "react-router-dom";
import { Toast, Toast2 } from "../modules/Alert";
import { checkDivert, confirmDivert } from "../../Api";
import { responseAlert,failureAlert } from "../modules/Alert";

const DivertFunds = () => {
  const { beneficiary_uid } = useParams();
  const [beneficiary, setBeneficiary] = useState(null);
  const { language } = useContext(i18n);
  const [amount, setAmount] = useState(0);
  const [eligible, isEligibleForDivert] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [remaining, setRemaining] = useState(0);
  const [donation, setDonation] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (beneficiary_uid != null) {
      checkDivert({ uid: beneficiary_uid }).then((out) => {
        if (out.status == 200) {
          const data = out?.data?.data ?? null;
          setBeneficiary(data?.user ?? null);
          setBeneficiaries(
            data?.user?.amount > -1 ? data?.beneficiaries ?? [] : []
          );
          isEligibleForDivert(data?.eligible ?? false);
        }
      });
    }
  }, [beneficiary_uid]);
  
  useEffect(() => {
    form.setFieldsValue({
      uid: beneficiary?.uid??beneficiary_uid??null,
    });
  }, [beneficiary_uid,beneficiary]);

  const onFinish = (values) => {
    //values.uid = beneficiary?.uid??beneficiary_uid??null;
    values.beneficiaries = beneficiaries;
    console.log(values);
    if (eligible && beneficiaries.length < 1) {
      Toast2.fire({
        icon: "error",
        title:
          "This account cannot be diverted as it is not subject to certain policies.",
      });
    } else {
      confirmDivert(values)
        .then((response) => {
          responseAlert(response.status,language.language,null);
        })
        .catch((error) => {
          // Catch any network errors
          failureAlert(error,language.language);
        });
      }
  };

  const lang = useMemo(
    () => getPageLabels(DIVERT_FUNDS_PAGE_LABELS, language.language),
    [language]
  );

  const columns = [
    {
      title: lang.child_public_id,
      dataIndex: "public_id",
    },
    {
      title: lang.received_amount,
      dataIndex: "received",
      render: (record) =>
        new Intl.NumberFormat("lk-LK", {
          style: "currency",
          currency: "LKR",
        }).format(record),
    },
    {
      title: lang.receivable_amount,
      dataIndex: "receivable",
      render: (record) =>
        new Intl.NumberFormat("lk-LK", {
          style: "currency",
          currency: "LKR",
        }).format(record),
    },
    {
      title: lang.enter_amount,
      dataIndex: "offer",
      render: (record) =>
        new Intl.NumberFormat("lk-LK", {
          style: "currency",
          currency: "LKR",
        }).format(record),
    },
  ];
  return (
    <div className="p-1">
      <div className="text-start">
        <label className="fos-card-heading font-bold" htmlFor="name">
          {lang.divert_donations} #{beneficiary?.public_id}
        </label>
      </div>
      <div className="mt-1">
        <Card className="p-2">
          <div className="d-flex justify-content-center">
            <div>
              <div className="text-center">
                <Avatar size="large">{beneficiary?.name?.charAt(0)}</Avatar>
              </div>
              <div className="fos-card-heading font-bold">
                {beneficiary?.name}
              </div>
              <div className="fos-label text-center">{beneficiary?.rdhs}</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="fos-label">{lang.full_name}</div>
              <div className="fos-label-detail">{beneficiary?.name}</div>
            </div>
            <div className="col-md-3">
              <div className="fos-label"> {lang.system_id}</div>
              <div className="fos-label-detail">{beneficiary?.uid}</div>
            </div>
            <div className="col-md-2">
              <div className="fos-label">{lang.public_id}</div>
              <div className="fos-label-detail">{beneficiary?.public_id}</div>
              {/* <Select
                defaultValue={beneficiary?.public_id}
                onChange={(val) => {
                  const ben = donation?.beneficiaries?.filter(
                    (itm) => itm.public_id == val
                  );
                  setBeneficiary(ben[0]);
                }}
                options={donation?.beneficiaries?.map((ben) => {
                  return { label: ben.public_id, value: ben.public_id };
                })}
              /> */}
            </div>
            <div className="col-md-2">
              <div className="fos-label"> {lang.age}</div>
              <div className="fos-label-detail">{new Date().getFullYear() - (new Date(beneficiary?.dob).getFullYear())} Yrs</div>
            </div>
            <div className="col-md-2">
              <div className="fos-label">{lang.moh_area}</div>
              <div className="fos-label-detail">{beneficiary?.moh}</div>
            </div>
          </div>
        </Card>
      </div>

      <div className="d-flex mt-3">
        <div className="d-flex fos-heading-small font-bold">
          <div>
            {lang.total_donation} :&nbsp;
            {new Intl.NumberFormat("lk-LK", {
              style: "currency",
              currency: "LKR",
            }).format(amount)}
            {/* {beneficiary_uid} */}
          </div>
        </div>
      </div>
      <Table className="mt-3" columns={columns} dataSource={beneficiaries} />
        <Form onFinish={onFinish} autoComplete="off" name="basic" layout="vertical" form={form}>
          <div className="row">
            <div className="mt-3">
              {/* <Form.List name="beneficiaries" initialValue={beneficiaries} style={{ display: 'none'}}></Form.List> */}
              <Form.Item style={{ display: 'none'}} name="uid">
                <Input/>
              </Form.Item>
              <Form.Item>
                <Button style={{ width: "max-content" }} type="success" htmlType="submit" className="fos-btn-primary mb-1 ml-4">Divert</Button>
              </Form.Item>
            </div>
          </div>
        </Form>

    </div>
  );
};

export default DivertFunds;
