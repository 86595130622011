export const SIDEBAR_BASE_LABELS = {
  dashboard: {
    en: "Dashboard",
    ta: "டாஷ்போர்டு",
    si: "උපකරණ පුවරුව",
  },

  benificiaries: {
    en: "Benificiaries",
    ta: "பயனாளிகள்",
    si: "ප්‍රතිලාභීන්",
  },

  donation_module: {
    en: "Donation Module",
    ta: "நன்கொடை தொகுதி",
    si: "පරිත්‍යාග මොඩියුලය",
  },
  all_donations: {
    en: "All Donations",
    ta: "அனைத்து நன்கொடைகள்",
    si: "සියලුම පරිත්‍යාග",
  },
  donation_details: {
    en: "Donation Details",
    ta: "நன்கொடை விவரங்கள்",
    si: "පරිත්‍යාග විස්තර",
  },
  common_filter: {
    en: "Common Filters",
    ta: "பொதுவான வடிப்பான்கள்",
    si: "පොදු පෙරහන",
  },
  monthly_goal_for_district: {
    en: "Monthly Goal For RDHS",
    ta: "மாவட்டங்களுக்கான மாதாந்திர இலக்கு",
    si: "දිස්ත්‍රික්ක සඳහා මාසික ඉලක්කය",
  },
  monthly_goal_for_category: {
    en: "Monthly Goal For Category",
    ta: "வகைக்கான மாதாந்திர இலக்கு",
    si: "කාණ්ඩය සඳහා මාසික ඉලක්කය",
  },
  annual_goal_for_national: {
    en: "Annual Goal For National",
    ta: "தேசியத்திற்கான வருடாந்திர இலக்கு",
    si: "ජාතික සඳහා අවුරුද්දක් තුළ පවතින ඉලක්කය",
  },
  set_funds_release_date: {
    en: "Release funds",
    ta: "நிதியை விடுவிக்கவும்",
    si: "අරමුදල් නිදහස් කරන්න",
  },
  reports: {
    en: "Reports",
    ta: "அறிக்கைகள்",
    si: "වර්තා",
  },
  donations_recieved_by_beneficiary: {
    en: "Donations Received by Beneficiary",
    ta: "பயனாளியால் பெறப்பட்ட நன்கொடைகள்",
    si: "ප්‍රතිලාභියා විසින් ලැබුණු පරිත්‍යාග",
  },
  donations_recieved_by_category: {
    en: "Donations Received by Category",
    ta: "வகை மூலம் பெறப்பட்ட நன்கொடைகள்",
    si: "කාණ්ඩය අනුව ලැබුණු පරිත්‍යාග",
  },
  donations_recieved_by_donor: {
    en: "Donations by Donor",
    ta: "நன்கொடையாளர் வழங்கும் நன்கொடைகள்",
    si: "පරිත්යාගශීලියා විසින් පරිත්යාග කිරීම",
  },
  funds_release_by_beneficiary: {
    en: "Funds Released per Beneficiary",
    ta: "ஒரு பயனாளிக்கு விடுவிக்கப்படும் நிதி",
    si: "ප්එක් ප්‍රතිලාභියෙකුට නිකුත් කරන ලද අරමුදල්",
  },
  donations_recieved_by_district: {
    en: "Donation Recieved By RDHS",
    ta: "மாவட்ட வாரியாக நன்கொடை பெறப்பட்டது",
    si: "දිස්ත්‍රික්ක අනුව ලැබී ඇත පරිත්‍යාග",
  },
  donations_goal_by_district: {
    en: "Donation Goal By RDHS",
    ta: "மாவட்ட வாரியாக நன்கொடை இலக்கு",
    si: "දිස්ත්‍රික්ක අනුව පරිත්‍යාග ඉලක්කය",
  },
  manage_dashboard: {
    en: "Manage Dashboard",
    ta: "டாஷ்போர்டை நிர்வகி",
    si: "උපකරණ පුවරුව කළමනාකරණය කරන්න",
  },
  user_module: {
    en: "User Module",
    ta: "பயனர் தொகுதி",
    si: "පරිශීලක මොඩියුලය",
  },
  all_users: {
    en: "All Users",
    ta: "அனைத்து பயனாளர்கள்",
    si: "සියලුම පරිශීලකයින්",
  },
  all_donors: {
    en: "All Donors",
    ta: "அனைத்து நன்கொடையாளர்கள்",
    si: "සියලුම පරිත්‍යාගශීලීන්",
  },
  user_roles: {
    en: "User Roles",
    ta: "பயனர் பாத்திரங்கள்",
    si: "පරිශීලක භූමිකාවන්",
  },
  user_areas: {
    en: "User Areas",
    ta: "பயனர் பகுதிகள்",
    si: "පරිශීලක ප්‍රදේශ",
  },
  add_new_user: {
    en: "Add New User",
    ta: "புதிய பயனரைச் சேர்க்கவும்",
    si: "නව පරිශීලක එකතු කරන්න",
  },
  add_new_user_role: {
    en: "Add New User Role",
    ta: "புதிய பயனர் பங்கைச் சேர்க்கவும்",
    si: "නව පරිශීලක භූමිකාව එක් කරන්න",
  },
  area: {
    en: "Area",
    ta: "பகுதி",
    si: "ප්‍රදේශයේ",
  },
  add_new_area: {
    en: "Add New Area",
    ta: "புதிய பகுதியைச் சேர்க்கவும்",
    si: "නව ප්‍රදේශයක් එකතු කරන්න",
  },
  activity_log: {
    en: "Activity Log",
    ta: "நடவடிக்கை பதிவு",
    si: "ක්‍රියාකාරකම් ලොගය",
  },
  donor_dashboard: {
    en: "Dashboard",
    ta: "டாஷ்போர்டு",
    si: "උපකරණ පුවරුව",
  },
  make_donation: {
    en: "Donate",
    ta: "தானம் செய்யுங்கள்",
    si: "පරිත්‍යාග කරන්න",
  },
  donor_donation: {
    en: "My Donations",
    ta: "நன்கொடையாளர் நன்கொடை",
    si: "මාගේ පරිත්‍යාග",
  },
  account_settings: {
    en: "Account Settings",
    ta: "கணக்கு அமைப்புகள்",
    si: "ගිණුම් සැකසුම්",
  },
  public_dashboard: {
    en: "Public Dashboard",
    ta: "பொது டாஷ்போர்டு",
    si: "පොදු උපකරණ පුවරුව",
  },
  home: {
    en: "Home",
    ta: "டாஷ்போர்டு",
    si: "උපකරණ පුවරුව",
  },
  logout: {
    en: "Logout",
    ta: "வெளியேறு",
    si: "පිටවීම",
  },
};
