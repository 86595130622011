import React, { useContext, useEffect, useMemo, useState } from "react";
import { Avatar, Select, Space, Table, Tag } from "antd";
import { Card } from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import "../../Styles/main.scss";
import { DownOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown } from "antd";
import { useParams } from "react-router-dom";
import FosUser from "../../Assests/ava.jpg";
import getPageLabels from "../../Utils/Label.service";
import { DONATIONDETAILS_PAGE_LABELS } from "../../Utils/Labels/DonationDetails.page.labels";
import { i18n } from "../../App";
import { getDonation, getDonations } from "../../Api";

function DonationDetails() {
  let { donation_id } = useParams();
  const [donation, setDonation] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);

  useEffect(() => { }, []);

  useEffect(() => {
    if (donation_id != null) {
      getDonation({ reference_no: donation_id }).then((out) => {
        const donated = out.data.data;
        console.log("DONATED", donated);
        setDonation(donated);
        setBeneficiary(donated?.beneficiaries[0]);
      });
    }
  }, [donation_id]);

  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(DONATIONDETAILS_PAGE_LABELS, language.language),
    [language]
  );

  const getPaymentMethod = (method) => {
    let out = "";
    switch (method) {
      case 1:
        out = "QR";
        break;
      case 2:
        out = "Card";
        break;
      case 3:
        out = "Bank";
        break;
      default:
        out = "Dummy";
        break;
    }
    return out;
  };

  const getBeneficaryStatus = (status) => {
    let out = "";
    switch (status) {
      case 0:
        console.log("HERE");
        out = <div className="fos-lable-green">Enrolled</div>;
        break;
      case 1:
        out = <div className="fos-lable-blue-5">Pending</div>;
        break;
      case 2:
        out = <div className="fos-lable-green">Funding</div>;
        break;
      case 3:
        out = <div className="fos-lable-blue-9">Complete</div>;
        break;
      case 4:
        out = <div className="fos-lable-red">Excluded</div>;
        break;
      case 5:
        out = <div className="fos-lable-grey">Deceased</div>;
        break;
      default:
        out = <div className="fos-success">{status}</div>;
        break;
    }
    return out;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <Card className="p-2">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold">
                {" "}
                {lang.donation_details}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="fos-label"> {lang.donation_id}</div>
                <div className="fos-label-detail">
                  {donation?.donation?.reference_no}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fos-label"> {lang.donor_name}</div>
                <div className="fos-label-detail">
                  {donation?.donor?.first_name} {donation?.donor?.last_name}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="fos-label"> {lang.donation_category}</div>
                <div className="fos-label-detail">SAM8</div>
              </div>
            </div>
          </Card>
          <Card className="p-2 mt-4">
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold">
                {" "}
                {lang.transaction_detail}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="fos-label"> {lang.transaction_id}</div>
                <div className="fos-label-detail">
                  {donation?.transaction?.transaction_id}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fos-label"> {lang.transaction_date}</div>
                <div className="fos-label-detail">
                  {donation?.transaction?.updated &&
                    new Date(
                      donation?.transaction?.updated
                    ).toLocaleDateString()}
                </div>
              </div>

              <div className="col-md-4">
                <div className="fos-label"> {lang.transaction_time}</div>
                <div className="fos-label-detail">
                  {donation?.transaction?.updated &&
                    new Date(
                      donation?.transaction?.updated
                    ).toLocaleTimeString()}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="fos-label"> {lang.transaction_amount}</div>
                <div className="fos-label-detail">
                  {donation?.donation?.amount}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fos-label"> {lang.payment_method}</div>
                <div className="fos-label-detail">
                  {getPaymentMethod(donation?.transaction?.payment_method)}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fos-label"> {lang.card_ref_no}</div>
                <div className="fos-label-detail">XXXX XXXX XXXX 7646</div>
              </div>
            </div>
          </Card>
        </div>
        {beneficiary ? (
          <div className="col-md-5">
            <Card className="p-2">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="text-center">
                    <Avatar size="large">{beneficiary?.name?.charAt(0)}</Avatar>
                  </div>
                  <div className="fos-card-heading font-bold">
                    {beneficiary?.name}
                  </div>
                  <div className="fos-label text-center">
                    {beneficiary?.rdhs}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="fos-label">{lang.full_name}</div>
                  <div className="fos-label-detail">{beneficiary?.name}</div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="fos-label"> {lang.system_id}</div>
                  <div className="fos-label-detail">{beneficiary?.uid}</div>
                </div>

                <div className="col-md-6">
                  <div className="fos-label">{lang.public_id}</div>
                  <Select
                    defaultValue={beneficiary?.public_id}
                    onChange={(val) => {
                      const ben = donation?.beneficiaries?.filter(
                        (itm) => itm.public_id == val
                      );
                      setBeneficiary(ben[0]);
                    }}
                    options={donation?.beneficiaries?.map((ben) => {
                      return { label: ben.public_id, value: ben.public_id };
                    })}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="fos-label"> {lang.age}</div>
                  <div className="fos-label-detail">{beneficiary?.age}</div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="fos-label">{lang.moh_area}</div>
                  <div className="fos-label-detail">{beneficiary?.moh}</div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="fos-label"> {lang.current_amount}</div>
                  <div className="fos-label-detail">{beneficiary?.amount}</div>
                </div>

                <div className="col-md-6">
                  <div className="fos-label"> {lang.due_amount}</div>
                  <div className="fos-label-detail">
                    {beneficiary?.receivable}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="fos-label"> {lang.status}</div>
                  {getBeneficaryStatus(beneficiary?.funding_state)}
                </div>
              </div>
            </Card>
          </div>
        ) : null}
      </div>
    </>
  );
}
export default DonationDetails;
