import React, { useContext, useMemo, useState } from "react";
import { Modal, Button, Table } from "antd";
import getPageLabels from "../../Utils/Label.service";
import { MYDONATIONDONOR_PAGE_LABELS } from "../../Utils/Labels/MyDonationDonor.page.labels";
import { i18n } from "../../App";
const PopupComponent = ({ beneficiaries }) => {
  // console.log("BENS", beneficiaries);
  const { language } = useContext(i18n);

  const lang = useMemo(
    () => getPageLabels(MYDONATIONDONOR_PAGE_LABELS, language.language),
    [language]
  );

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: lang.public_id,
      dataIndex: "public_id",
      key: "public_id",
    },
    {
      title: lang.amount,
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div>
      <Button className="fos-btn-primary" onClick={showModal}>
        {lang.beneficiaries}
      </Button>
      <Modal
        title={lang.beneficiaries_assigned}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* Use the Table component inside the Modal */}
        <Table
          columns={columns}
          dataSource={beneficiaries}
          rowKey="key"
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default PopupComponent;
