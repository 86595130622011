export const HOME_PAGE_LABELS = {
  hi: {
    en: "Hi",
    ta: "வணக்கம்",
    si: "ආයුබෝවන්",
  },
  heading_small: {
    en: "Welcome back to SNEHA dashboard",
    ta: "SNEHA டாஷ்போர்டுக்கு மீண்டும் வரவேற்கிறோம்",
    si: "ස්නේහ උපකරණ පුවරුව වෙත නැවත සාදරයෙන් පිළිගනිමු",
  },
};
