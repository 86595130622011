export const MAKEDONATIONS_PAGE_LABELS = {
  make_donations: {
    en: "MakeDonations",
    ta: "நன்கொடைகள்",
    si: "පරිත්‍යාග",
  },
  hi: {
    en: "Hi",
    ta: "வணக்கம்",
    si: "ආයුබෝවන්",
  },
  welcome: {
    en: "Welcome to the donation portal. Please Enter the total amount you wish to donate",
    ta: "நன்கொடை போர்ட்டலுக்கு வரவேற்கிறோம். நீங்கள் நன்கொடை அளிக்க விரும்பும் மொத்தத் தொகையை உள்ளிடவும்",
    si: "පරිත්‍යාග ද්වාරය වෙත සාදරයෙන් පිළිගනිමු. කරුණාකර ඔබ පරිත්‍යාග කිරීමට කැමති මුළු මුදල ඇතුළත් කරන්න",
  },
  you_can: {
    en: "You can donate",
    ta: "நீங்கள் தானம் செய்யலாம்",
    si: "ඔබට පරිත්‍යාග කළ හැකිය",
  },
  per_child: {
    en: " You can donate Rs. 500.00 - Rs.90,000.00 per child in each donation",
    ta: "நன்கொடையாக ரூ. ஒவ்வொரு நன்கொடையிலும் ஒரு குழந்தைக்கு 500.00 - ரூ.90,000.00",
    si: "එක් පරිත්‍යාගකින් ඔබට රු. 500.00 - රු. 90,000.00 බැගින් එක් දරුවෙකුට ලබා දිය හෑකිය",
  },
  heading_small_1: {
    en: "Enter Donation Amount",
    ta: "நன்கொடை தொகையை உள்ளிடவும்",
    si: "පරිත්‍යාග මුදල ඇතුළත් කරන්න",
  },
  heading_small_2: {
    en: "Please Enter the total amount you wish to donate",
    ta: "நீங்கள் நன்கொடை அளிக்க விரும்பும் மொத்தத் தொகையை உள்ளிடவும்",
    si: "කරුණාකර ඔබ පරිත්‍යාග කිරීමට කැමති මුළු මුදල ඇතුළත් කරන්න",
  },
  next_btn: {
    en: "Next ",
    ta: " அடுத்தது",
    si: "ඊළඟ",
  },
  allocate_donations: {
    en: "Allocate donations",
    ta: "நன்கொடைகளை ஒதுக்குங்கள்",
    si: "පරිත්‍යාග වෙන් කරන්න",
  },
  heading_small_3: {
    en: "Congratulations! The following Children have been selected to receive your donation.",
    ta: "வாழ்த்துகள்!  உங்கள் நன்கொடையைப் பெற பின்வரும் குழந்தைகள் தேர்ந்தெடுக்கப்பட்டுள்ளனர்.",
    si: "සුභ පැතුම්! ඔබගේ පරිත්‍යාගය ලැබීමට පහත දරුවන් තෝරාගෙන ඇත.",
  },
  heading_small_4: {
    en: "Total Donation Amount  ",
    ta: "மொத்த நன்கொடைத் தொகை ",
    si: "මුළු පරිත්‍යාග මුදල ",
  },
  remaining: {
    en: "Remaining Amount",
    ta: "மீதமுள்ள தொகை",
    si: "ඉතිරි මුදල",
  },
  remaining_description: {
    en: "Remaining amount is the donation amount remaining from the total donation, available to allocate from, to the next one or more children.",
    ta: "மீதமுள்ள தொகை என்பது, அடுத்த ஒன்று அல்லது அதற்கு மேற்பட்ட குழந்தைகளுக்கு ஒதுக்கக்கூடிய மொத்த நன்கொடையில் இருந்து மீதமுள்ள நன்கொடைத் தொகையாகும்.",
    si: "ඉතිරි මුදල යනු මුළු පරිත්‍යාගයෙන් ඉතිරි වන පරිත්‍යාග මුදල, ඊළඟ දරුවන්ගෙන් එක් අයෙකුට හෝ වැඩි ගණනකට වෙන් කිරීමට තිබේ.",
  },
  child_public_id: {
    en: "child Public ID",
    ta: "குழந்தை பொது ஐடி",
    si: "ළමා පොදු හඳුනාගැනීමේ අංකය",
  },
  received_amount: {
    en: " Received Amount (LKR)",
    ta: "பெறப்பட்ட தொகை (LKR)",
    si: "ලැබුණු මුදල (LKR)",
  },
  receivable_amount: {
    en: " Receivable Amount (LKR)",
    ta: "பெறத்தக்க தொகை (LKR)",
    si: "ලැබිය යුතු මුදල (LKR)",
  },
  received_funds: {
    en: " Received Funds (LKR)",
    ta: "நிதி பெறப்பட்டது (LKR)",
    si: "ලැබුණු අරමුදල් (LKR)",
  },
  required_funds: {
    en: " Required Funds (LKR)",
    ta: "தேவையான நிதி (LKR)",
    si: "අවශ්ය අරමුදල් (LKR)",
  },
  enter_amount: {
    en: "Enter Amount (LKR)",
    ta: "தொகையை உள்ளிடவும் (LKR)",
    si: "මුදල ඇතුළත් කරන්න (LKR)",
  },

  action: {
    en: "Action",
    ta: "நடவடிக்கை",
    si: "ක්‍රියාව",
  },
  previous_btn: {
    en: "previous",
    ta: "முந்தைய",
    si: "කලින්",
  },
  done_btn: {
    en: "Done",
    ta: "முடிந்தது",
    si: "අවසන් කරන්න",
  },
  referral_number: {
    en: "Referral Number",
    ta: "பரிந்துரை எண்",
    si: "යොමු අංකය",
  },
  heading_small_5: {
    en: "Please note down this referral number for future reference",
    ta: "எதிர்கால குறிப்புக்கு இந்த பரிந்துரை எண்ணை குறித்து கொள்ளவும்",
    si: "ඉදිරි පරිශීලනය සඳහා කරුණාකර මෙම යොමු අංකය සටහන් කර ගන්න",
  },
  payment: {
    en: "Payment",
    ta: "கட்டணம் ",
    si: "ගෙවීම ",
  },
  finish: {
    en: "Finish",
    ta: "முடிக்கவும் ",
    si: "අවසන් කරන්න ",
  },
  donation_successful: {
    en: "Donation Successful",
    ta: "நன்கொடை வெற்றிகரமாக ",
    si: "පරිත්‍යාගය සාර්ථකයි ",
  },
  thankyou_msg: {
    en: "Thank you for your contribution to support our children in their fight with malnutrition.",
    ta: "மந்தபோசனத்திற்கு எதிரான போராட்டத்தில் எங்கள் குழந்தைகளுக்கு ஆதரவு தெரிவிக்க உங்கள் பங்களிப்புக்கு நன்றி.",
    si: "මන්දපෝෂණයට එරෙහි සටනේදී අපගේ දරුවන්ට සහයෝගය දැක්වීමට ඔබේ දායකත්වයට ස්තූතියි.  ",
  },
  thankyou_msg2: {
    en: "If you want to check the progress of your donations please contact us",
    ta: "உங்கள் பரித்யாக்வல பிரகதியை பரிசோதிக்க உங்களுக்குத் தேவை என்றால் தயவுசெய்து எங்களை தொடர்பு கொள்ளவும் ",
    si: "ඔබගේ පරිත්‍යාගවල ප්‍රගතිය පරීක්ෂා කිරීමට ඔබට අවශ්‍ය නම් කරුණාකර අප හා සම්බන්ධ වන්න ",
  },
  time: {
    en: "Time",
    ta: "நேரம் ",
    si: "කාලය ",
  },
  donor_id: {
    en: "Donor ID",
    ta: "பரிவர்த்தனை ஐடி",
    si: "පරිත්යාගශීලියාගේ හඳුනාගැනීමේ අංකය",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  amount: {
    en: "Amount",
    ta: "தொகை",
    si: "මුදල",
  },
  date: {
    en: "Date",
    ta: "நாள்",
    si: "දවස",
  },
  transaction_id: {
    en: "Transaction ID",
    ta: "பரிவர்த்தனை ஐடி ",
    si: "ගනුදෙනු හඳුනාගැනීමේ අංකය",
  },
  enter_amountR: {
    en: "Enter Amount RS",
    ta: " தொகையை உள்ளிடவும் RS",
    si: "මුදල ඇතුලත් කරන්න RS ",
  },
  alert_amountR500: {
    en: "Please enter LKR 500 or more",
    ta: "LKR 500 அல்லது அதற்கு மேல் உள்ளிடவும்",
    si: "කරුණාකර LKR 500 හෝ ඊට වැඩි මුදලක් ඇතුළු කරන්න",
  },
  alert_amountN: {
    en: "Amount required",
    ta: " தேவையான தொகை",
    si: "මුදල ඇතුලත් කරන්න",
  },
  alert_amount_notenough: {
    en: "Don't have enough amount left",
    ta: "போதுமான தொகை மிச்சமில்லை",
    si: "ප්‍රමාණවත් මුදලක් ශේෂ වී නොමැත",
  },
  alert_amount_greater: {
    en: "Amount is greater than receivable amount",
    ta: "பெறத்தக்க தொகையை விட தொகை அதிகமாக உள்ளது",
    si: "ලැබිය යුතු මුදලට වඩා බැර කරන මුදල වැඩිය",
  },
  alert_process_done: {
    en: "Donation Successful",
    ta: "நன்கொடை வெற்றி",
    si: "පරිත්‍යාගය සාර්ථකයි",
  },
  pay_now: {
    en: "Pay Now",
    ta: "இப்போது செலுத்த ",
    si: "දැන් ගෙවන්න",
  },
  add_child: {
    en: "Add Child",
    ta: "கூட்டு ",
    si: "දරුවා එක් කරන්න",
  },
  reference_no: {
    en: "Reference Number",
    ta: "குறிப்பு எண்",
    si: "යොමු අංකය",
  },
};
