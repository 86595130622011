export const DONATIONDETAILS_PAGE_LABELS = {
  donation_details: {
    en: "Donation Details",
    ta: "நன்கொடை விவரங்கள்",
    si: "පරිත්‍යාග විස්තර",
  },
  donation_id: {
    en: "Donation ID",
    ta: "நன்கொடை ஐடி",
    si: "පරිත්‍යාග හඳුනාගැනීමේ අංකය",
  },
  donor_name: {
    en: "Donor Name",
    ta: "நன்கொடை பெயர்",
    si: "පරිත්‍යාග නාමය",
  },
  donation_category: {
    en: "Donation Category",
    ta: "நன்கொடை வகை",
    si: "පරිත්‍යාග කාණ්ඩය",
  },
  transaction_detail: {
    en: "Transaction Details",
    ta: "பரிமாற்ற விவரங்கள்",
    si: "ගනුදෙනු විස්තර",
  },
  transaction_id: {
    en: "Transaction ID",
    ta: "பரிவர்த்தனை ஐடி",
    si: "ගනුදෙනු හඳුනාගැනීමේ අංකය",
  },
  transaction_date: {
    en: "Transaction Date",
    ta: "பரிவர்த்தனை தேதி",
    si: "ගනුදෙනු දිනය",
  },
  transaction_time: {
    en: "Transaction Time",
    ta: "பரிவர்த்தனை நேரம்",
    si: "ගනුදෙනු කාලය",
  },
  transaction_amount: {
    en: "Transaction Amount (LKR)",
    ta: "பரிவர்த்தனை தொகை (LKR)",
    si: "ගනුදෙනු මුදල (LKR)",
  },
  payment_method: {
    en: "Payment Method",
    ta: "பணம் செலுத்தும் முறை",
    si: "ගෙවීම් ක්‍රමය",
  },
  card_ref_no: {
    en: "Card Ref No",
    ta: "அட்டை குறிப்பு எண்",
    si: "කාඩ්පත් යොමු අංකය",
  },
  full_name: {
    en: "Full Name",
    ta: "முழு பெயர்",
    si: "සම්පූර්ණ නම",
  },
  system_id: {
    en: "System ID",
    ta: "கணினி ஐடி",
    si: "පද්ධති හඳුනාගැනීමේ අංකය",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  age: {
    en: "Age",
    ta: "வயது",
    si: "වයස",
  },
  moh_area: {
    en: "MOH Area",
    ta: "MOH பகுதி",
    si: "MOH ප්‍රදේශය",
  },
  current_amount: {
    en: "Recieved Amount (LKR)",
    ta: "தற்போதைய தொகை (LKR)",
    si: "වත්මන් මුදල (LKR)",
  },
  due_amount: {
    en: "Recievable Amount (LKR)",
    ta: "நிலுவை தொகை (LKR)",
    si: "නියමිත මුදල (LKR)",
  },
  status: {
    en: "Status",
    ta: "நிலை",
    si: "තත්ත්වය",
  },
};
