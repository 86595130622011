export const NEWREGISTRATIONCHART_PAGE_LABELS = {
  jan: {
    en: "JAN",
    ta: "ஜனவரி",
    si: "ජනවාරි",
  },
  feb: {
    en: "FEB",
    ta: "பிப்ரவரி",
    si: "පෙබරවාරි",
  },
  mar: {
    en: "MAR",
    ta: "மார்ச்",
    si: "මාර්තු",
  },
  apr: {
    en: "APR",
    ta: "ஏப்ரல்",
    si: "අප්‍රේල්",
  },
  may: {
    en: "MAY",
    ta: "மே",
    si: "මැයි",
  },
  jun: {
    en: "JUN",
    ta: "ஜூன்",
    si: "ජූනි",
  },
  jul: {
    en: "JUL",
    ta: "ஜூலை",
    si: "ජුලි",
  },
  aug: {
    en: "AUG",
    ta: "ஆகஸ்ட்",
    si: "අගෝස්තු",
  },
  sep: {
    en: "SEP",
    ta: "செப்டம்பர்",
    si: "සැප්තැම්බර්",
  },
  oct: {
    en: "OCT",
    ta: "அக்டோபர்",
    si: "ඔක්තෝම්බර්",
  },
  nov: {
    en: "NOV",
    ta: "நவம்பர்",
    si: "නොවැම්බර්",
  },
  dec: {
    en: "DEC",
    ta: "டிசம்பர்",
    si: "දෙසැම්බර්",
  },

  heading: {
    en: "New Child registrations by month (Count)",
    ta: "மாதவாரியாக புதிய குழந்தை பதிவுகள் (எண்ணிக்கை)",
    si: "මාසය අනුව නව ළමා ලියාපදිංචි කිරීම් (ගණනය)",
  },
};
