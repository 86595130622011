export const DONATIONRECIEVEDBYBENEFICIARRY_PAGE_LABELS = {
  heading: {
    en: "Donations Received by Beneficiary",
    ta: "பயனாளியால் பெறப்பட்ட நன்கொடைகள்",
    si: "ප්‍රතිලාභියා විසින් ලැබුණු පරිත්‍යාග",
  },
  donor_id: {
    en: "Donor ID",
    ta: "நன்கொடையாளர் ஐடி",
    si: "දායක හඳුනාගැනීමේ අංකය",
  },
  child_id: {
    en: "Child ID",
    ta: "குழந்தை ஐடி",
    si: "ළමා හඳුනාගැනීමේ අංකය",
  },
  download_report: {
    en: "Download Report",
    ta: "அறிக்கையைப் பதிவிறக்கவும்",
    si: "වර් තාව බාගත කරන්න",
  },
  public_id: {
    en: "Public ID",
    ta: "பொது ஐடி",
    si: "පොදු හඳුනාගැනීමේ අංකය",
  },
  donation_id: {
    en: "Donation ID",
    ta: "நன்கொடைகள் ஐடி",
    si: "පරිත්‍යාග හඳුනාගැනීමේ අංකය",
  },
  month: {
    en: "Month",
    ta: "மாதம்",
    si: "මාසය",
  },
  year: {
    en: "Year",
    ta: "ஆண்டு",
    si: "අවුරුදු",
  },
  date: {
    en: "Date",
    ta: "நாள்",
    si: "දිනය",
  },
  category: {
    en: "Category",
    ta: "வகை",
    si: "ප්‍රභේදය",
  },
  amount: {
    en: "Amount (LKR)",
    ta: "தொகை (LKR)",
    si: "මුදල (LKR)",
  },
  beneficiary_id: {
    en: "Benificiary ID",
    ta: "பயனாளி ஐடி",
    si: "ප්‍රතිලාභි හඳුනාගැනීමේ අංකය",
  },
};
